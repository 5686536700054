import { get } from 'lodash'
import { observer } from 'mobx-react'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Bar,
    BarChart,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import {
    ValueType,
    NameType,
    Formatter,
    // @ts-ignore
} from 'recharts/lib/component/DefaultTooltipContent'
import { Static } from '../../models/response'
import { getStaticsMap } from '../../stores'
import { getCurrentBreakpoint } from '../../util/break-point'
import {
    formatMonthDayYear,
    formatMonthYear,
    formatShortMonthYear,
} from '../../util/misc'
import { useStores } from '../../util/stores'
import { RoundedBar } from './rounded-bar'

interface Props {
    colors: string[]
    dataKey: string
    staticsKey: string
}

export const MonthlyStackedBar: React.FC<Props> = observer(
    ({ dataKey: key, colors, staticsKey }) => {
        const { company, statics } = useStores()
        const { t } = useTranslation()

        const yTickFormatter = useCallback((value: string) => {
            return value + '%'
        }, [])

        const tooltipFormatter: Formatter<ValueType, NameType> = useCallback(
            (value: ValueType, name: NameType) => {
                return [
                    ((+value).toFixed(0) + '%') as NameType,
                    name as NameType,
                ]
            },
            [],
        )

        const getLabelTitle = useCallback((date: Date) => {
            if (
                new Date(date).getMonth() === new Date().getMonth() &&
                new Date(date).getFullYear() === new Date().getFullYear()
            ) {
                return formatMonthDayYear(new Date())
            } else {
                return formatShortMonthYear(date)
            }
        }, [])

        const CustomizedXTick = (props: any) => {
            const { x, y, payload } = props

            const isSmallBreakPoint =
                getCurrentBreakpoint() === 'lg' ||
                getCurrentBreakpoint() === 'md' ||
                getCurrentBreakpoint() === 'sm'

            const transformValue = isSmallBreakPoint ? -35 : 0
            const xValue = isSmallBreakPoint ? 0 : 30

            return (
                <g transform={`translate(${x},${y})`}>
                    <text
                        x={xValue}
                        y={0}
                        fontSize={12}
                        dy={5}
                        textAnchor="end"
                        fill="#10123A"
                        transform={`rotate(${transformValue})`}
                    >
                        {formatMonthYear(payload.value)}
                    </text>
                </g>
            )
        }

        const chartData: { [key: string]: string }[] = useMemo(() => {
            if (!company.monthlyAggregates.length) {
                return []
            }

            const values: { [key: string]: string }[] = []

            company.monthlyAggregates.forEach((aggregate) => {
                const data_: Record<string, number> = get(aggregate, key)
                const obj: Record<string, number> = {}

                const map = getStaticsMap(
                    (statics as any)[staticsKey] as Static[],
                )

                Object.entries(data_).map((value: [string, number]) => {
                    const name = map[value[0]] ?? t('extras.other')
                    obj[name] = value[1]
                })

                values.push({
                    month: aggregate.month,
                    ...obj,
                })
            })

            return values
        }, [t, company.monthlyAggregates])

        return (
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    data={chartData}
                    margin={{ left: -20, right: 40, bottom: 20 }}
                >
                    <CartesianGrid stroke="#F0F0F0" />
                    <XAxis
                        type="category"
                        height={39}
                        dataKey="month"
                        strokeWidth={1}
                        fontSize={12}
                        tickLine={false}
                        tick={CustomizedXTick}
                        tickFormatter={formatMonthYear}
                        angle={-45}
                        interval={0}
                        axisLine={{ stroke: '#F0F0F0' }}
                        allowDuplicatedCategory={false}
                    />
                    <YAxis
                        type="number"
                        strokeWidth={1}
                        fontSize={12}
                        tickLine={false}
                        tick={{ fill: '#10123A' }}
                        axisLine={{ stroke: '#F0F0F0' }}
                        tickFormatter={yTickFormatter}
                        domain={[0, 'dataMax + 5']}
                        scale="linear"
                    />
                    <Tooltip
                        wrapperStyle={{ outline: 'none' }}
                        cursor={{ fill: '#EDEDED' }}
                        labelFormatter={getLabelTitle}
                        formatter={tooltipFormatter}
                        position={{ y: 0 }}
                        itemStyle={{
                            fontSize: '10px',
                            marginTop: -8,
                        }}
                        labelStyle={{
                            color: 'white',
                            marginBottom: 8,
                        }}
                        contentStyle={{
                            background: '#080922',
                            padding: 8,
                            borderRadius: 6,
                            border: 'none',
                        }}
                    />

                    {Object.keys(chartData[0]).map((key, index) => {
                        if (key !== 'month') {
                            return (
                                <Bar
                                    dataKey={key}
                                    data={chartData}
                                    stackId="a"
                                    key={index}
                                    name={key}
                                    shape={<RoundedBar />}
                                    barSize={23}
                                    fill={colors[index]}
                                />
                            )
                        }
                    })}
                </BarChart>
            </ResponsiveContainer>
        )
    },
)
