import { Formik, FormikHelpers } from 'formik'
import { filter } from 'lodash'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Subscription } from 'rxjs'
import { PrimaryButton } from '../../../components/buttons'
import { SelectInput, TextInput } from '../../../components/input'
import { MultiSelectInput } from '../../../components/input/multi-select-input'
import { CenterModal, ModalProps } from '../../../components/modals'
import { InviteUserModel } from '../../../models/request'
import { AccountLevel, accountLevelOptions } from '../../../util/constants'
import { cancelSubscriptions, Option } from '../../../util/misc'
import { useStores } from '../../../util/stores'
import { validateModel } from '../../../util/validation'
import { RolesModal } from './roles.modal'

export const InviteUserModal: React.FC<ModalProps> = observer(
    ({ isOpen, setIsOpen }) => {
        const { t } = useTranslation()
        const { company, user } = useStores()
        const [isRolesModalOpen, setIsRolesModalOpen] = useState(false)
        const [canViewDepartments, setCanViewDepartments] = useState(false)
        const subscriptions: Subscription[] = []
        const isAdmin = user.canAccess(AccountLevel.ADMIN)

        const levelLabel = useMemo(() => {
            return (
                <div className="flex space-x-2">
                    <span>{t('fields.level.label')}</span>
                    <button
                        type="button"
                        onClick={setIsRolesModalOpen.bind(null, true)}
                    >
                        <img
                            className="h-3"
                            src="/img/icons/i-circle-open.svg"
                            alt="Information"
                        />
                    </button>
                </div>
            )
        }, [setIsRolesModalOpen])

        const levelOptions = useMemo(() => {
            return filter(accountLevelOptions(user.accountLevel), {
                disabled: false,
            })
        }, [user.accountLevel])

        const onLevelChange = useCallback((option: Option) => {
            setCanViewDepartments(option.value === AccountLevel.VIEWER)
        }, [])

        const close = useCallback(() => {
            user.trackEvent('button_clicked', {
                name: 'inviteUser',
                action: 'close',
            }).subscribe()
            setIsOpen(false)
        }, [])

        const inviteUser = useCallback(
            (
                values: InviteUserModel,
                helpers: FormikHelpers<InviteUserModel>,
            ) => {
                helpers.setSubmitting(true)

                const subscription = company.inviteUser(values).subscribe({
                    next(response) {
                        helpers.setSubmitting(false)

                        if (response.ok) {
                            close()
                        }
                    },
                })

                subscriptions.push(subscription)
            },
            [close],
        )

        useEffect(() => {
            if (isAdmin && company.activeCompanyId) {
                const subscription = company.getDepartments().subscribe()
                subscriptions.push(subscription)
            }
            return () => {
                cancelSubscriptions(subscriptions)
            }
        }, [company.activeCompanyId, isAdmin])

        const departmentOptions = useMemo(() => {
            return company.departments.map(
                (department): Option => ({
                    value: department._id,
                    display: department.name,
                }),
            )
        }, [close, company.departments])

        return (
            <>
                <CenterModal
                    isOpen={isOpen}
                    setIsOpen={close}
                    className="max-w-[32rem]"
                >
                    <header className="flex flex-col space-y-3 mb-6 mx-9">
                        <span className="text-2xl">
                            {t('modals.inviteUser.heading')}
                        </span>
                        <span className="text-xs text-[#56576A]">
                            {t('modals.inviteUser.subText')}
                        </span>
                    </header>
                    <Formik
                        validate={validateModel}
                        initialValues={new InviteUserModel()}
                        onSubmit={inviteUser}
                    >
                        {({ handleSubmit, isSubmitting, isValid }) => (
                            <form onSubmit={handleSubmit}>
                                <main className="space-y-4 mx-9 mb-6">
                                    <div className="flex space-x-4">
                                        <TextInput
                                            name="email"
                                            label={
                                                t(
                                                    'fields.email.label',
                                                ) as string
                                            }
                                            className="w-[55%]"
                                            placeholder={
                                                t(
                                                    'fields.email.placeholder',
                                                ) as string
                                            }
                                        />
                                        <SelectInput
                                            name="level"
                                            className="flex-1"
                                            options={levelOptions}
                                            label={levelLabel}
                                            onChange={onLevelChange}
                                            placeholder={
                                                t(
                                                    'fields.level.placeholder',
                                                ) as string
                                            }
                                        />
                                    </div>
                                    <TextInput
                                        name="firstName"
                                        label={
                                            t(
                                                'fields.firstName.label',
                                            ) as string
                                        }
                                        placeholder={
                                            t(
                                                'fields.firstName.placeholder',
                                            ) as string
                                        }
                                    />
                                    <TextInput
                                        name="lastName"
                                        label={
                                            t('fields.lastName.label') as string
                                        }
                                        placeholder={
                                            t(
                                                'fields.lastName.placeholder',
                                            ) as string
                                        }
                                    />
                                    {company.getActiveLanguages && (
                                        <SelectInput
                                            name="appLanguage"
                                            options={company.getActiveLanguages}
                                            label={
                                                t(
                                                    'fields.appLanguage.label',
                                                ) as string
                                            }
                                            placeholder={
                                                t(
                                                    'fields.appLanguage.placeholder',
                                                ) as string
                                            }
                                        />
                                    )}
                                    {canViewDepartments &&
                                        company.departments &&
                                        company.departments.length > 0 && (
                                            <MultiSelectInput
                                                name="departments"
                                                options={departmentOptions}
                                                label={
                                                    t(
                                                        'fields.departmentsAccess.label',
                                                    ) as string
                                                }
                                                placeholder={
                                                    t(
                                                        'fields.departmentsAccess.placeholder',
                                                    ) as string
                                                }
                                            />
                                        )}
                                </main>
                                <footer className="px-9 pb-9">
                                    <PrimaryButton
                                        trackingId="inviteUser"
                                        trackingAction="save"
                                        type="submit"
                                        className="w-full"
                                        disabled={isSubmitting || !isValid}
                                    >
                                        <span>{t('buttons.inviteUser')}</span>
                                    </PrimaryButton>
                                </footer>
                            </form>
                        )}
                    </Formik>
                </CenterModal>
                <RolesModal
                    isOpen={isRolesModalOpen}
                    setIsOpen={setIsRolesModalOpen}
                />
            </>
        )
    },
)
