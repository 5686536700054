import { useCallback, useEffect } from 'react'

export const Clarity: React.FC = () => {
    const clarity = useCallback(
        (c: any, l: any, a: any, r: any, i?: any, t?: any, y?: any) => {
            c[a] =
                c[a] ||
                function () {
                    ;(c[a].q = c[a].q || []).push(arguments)
                }
            t = l.createElement(r)
            t.async = 1
            t.src = 'https://www.clarity.ms/tag/' + i
            y = l.getElementsByTagName(r)[0]
            y.parentNode.insertBefore(t, y)
        },
        [],
    )

    useEffect(() => {
        clarity(window, document, 'clarity', 'script', process.env.CLARITY_KEY)
    }, [])

    return <></>
}
