import { observer } from 'mobx-react'
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { CenterModal, ModalProps } from '../../../components/modals'

interface TableRow {
    accessKey: string
    viewer: boolean
    manager: boolean
    admin: boolean
    superAdmin: boolean
}

const tableRows: TableRow[] = [
    // {
    //     accessKey: 'modals.roles.departmentInfo',
    //     viewer: true,
    //     manager: true,
    //     admin: true,
    //     superAdmin: true,
    // },
    // {
    //     accessKey: 'modals.roles.specificKeyDates',
    //     viewer: false,
    //     manager: true,
    //     admin: true,
    //     superAdmin: true,
    // },
    {
        accessKey: 'modals.roles.dashboard',
        viewer: true,
        manager: true,
        admin: true,
        superAdmin: true,
    },
    {
        accessKey: 'modals.roles.exportPdf',
        viewer: true,
        manager: true,
        admin: true,
        superAdmin: true,
    },
    {
        accessKey: 'modals.roles.viewDashboardUsers',
        viewer: true,
        manager: true,
        admin: true,
        superAdmin: true,
    },

    {
        accessKey: 'modals.roles.dataTable',
        viewer: false,
        manager: false,
        admin: true,
        superAdmin: true,
    },
    {
        accessKey: 'modals.roles.inviteUsers',
        viewer: false,
        manager: false,
        admin: true,
        superAdmin: true,
    },
    {
        accessKey: 'modals.roles.addRemoveUsers',
        viewer: false,
        manager: false,
        admin: false,
        superAdmin: true,
    },
]

const Icon: React.FC<{ value: boolean }> = memo(({ value }) => {
    const icon = value ? 'check-circle' : 'x-circle-gray'
    const alt = value ? 'Check mark in circle' : 'X symbol in circle'

    return (
        <div className="flex justify-center">
            <img src={`/img/icons/${icon}.svg`} alt={alt} className="w-4" />
        </div>
    )
})

export const RolesModal: React.FC<ModalProps> = observer(
    ({ isOpen, setIsOpen }) => {
        const { t } = useTranslation()

        return (
            <CenterModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                className="max-w-[40rem] !py-9"
            >
                <header className="flex flex-col space-y-3 mb-6 mx-9">
                    <span className="text-2xl">
                        {t('modals.roles.heading')}
                    </span>
                    <span className="text-xs text-[#56576A]">
                        {t('modals.roles.subText')}
                    </span>
                </header>
                <main className="mx-9">
                    <table className="w-full">
                        <thead className="text-[13px] text-[#56576A] tracking-[0.07em] uppercase">
                            <tr>
                                <th className="font-normal py-3 text-left">
                                    {t('modals.roles.access')}
                                </th>
                                <th className="font-normal w-[6rem] border-l border-[#DDDEE34d] py-3">
                                    {t('levels.viewer')}
                                </th>
                                {/* <th className="font-normal w-[6rem] border-l border-[#DDDEE34d] py-3">
                                    {t('levels.manager')}
                                </th> */}
                                <th className="font-normal w-[6rem] border-l border-[#DDDEE34d] py-3">
                                    {t('levels.admin')}
                                </th>
                                <th className="font-normal w-[6rem] border-l border-[#DDDEE34d] py-3">
                                    {t('levels.super-admin')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableRows.map(
                                (
                                    {
                                        accessKey,
                                        viewer,
                                        manager,
                                        admin,
                                        superAdmin,
                                    },
                                    i,
                                ) => (
                                    <tr key={i}>
                                        <td className="border-t border-[#DDDEE34d] py-4">
                                            <span className="text-sm">
                                                {t(accessKey)}
                                            </span>
                                        </td>
                                        <td className="border-l border-t border-[#DDDEE34d]">
                                            <Icon value={viewer} />
                                        </td>
                                        {/* <td className="border-l border-t border-[#DDDEE34d]">
                                            <Icon value={manager} />
                                        </td> */}
                                        <td className="border-l border-t border-[#DDDEE34d]">
                                            <Icon value={admin} />
                                        </td>
                                        <td className="border-l border-t border-[#DDDEE34d]">
                                            <Icon value={superAdmin} />
                                        </td>
                                    </tr>
                                ),
                            )}
                        </tbody>
                    </table>
                </main>
            </CenterModal>
        )
    },
)
