import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { NotificationArea } from './components/layout'
import { Clarity } from './components/misc/clarity'
import { Router } from './components/routing'
import { routes } from './pages/routes'

export const App: React.FC = () => {
    return (
        <HelmetProvider>
            <Router routes={routes} />
            <Clarity />
            <NotificationArea />
        </HelmetProvider>
    )
}
