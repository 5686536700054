import React, { useState, useEffect, useCallback, useMemo } from 'react'
import {
    useReactTable,
    getCoreRowModel,
    flexRender,
    ColumnDef,
    getPaginationRowModel,
    PaginationState,
    getFilteredRowModel,
    ColumnFiltersState,
    getFacetedUniqueValues,
    FilterFn,
    SortingState,
    getSortedRowModel,
    ColumnResizeMode,
    OnChangeFn,
    Updater,
} from '@tanstack/react-table'

import { observer } from 'mobx-react'
import { classNames } from '../../util/misc'
import { Coachee } from '../../models/response'
import { t } from 'i18next'

import { Filter } from './filter'

import './table-list.css'
import { useStores } from '../../util/stores'

declare module '@tanstack/table-core' {
    interface FilterFns {
        multiOption: FilterFn<unknown>
        dateOption: FilterFn<unknown>
    }
}

declare module '@tanstack/table-core' {
    interface ColumnMeta<TData extends unknown, TValue> {
        trackableName: string
        staticsKey?: string
        translationKey?: string
    }
}

interface Props {
    data: Coachee[]
    columns: ColumnDef<Coachee, string>[]
    binAction: any
    csvExportData: any
    defaultSort?: SortingState
    isHorizontalScrollVisible?: boolean
}

export const TableList: React.FC<Props> = observer(
    ({
        data,
        columns,
        binAction,
        csvExportData,
        defaultSort,
        isHorizontalScrollVisible,
    }) => {
        const { user, company } = useStores()
        const TABLE_NAME = 'coacheeTable'
        const [{ pageIndex, pageSize }, setPagination] =
            React.useState<PaginationState>({
                pageIndex: 0,
                pageSize: 100,
            })

        const [columnResizeMode, setColumnResizeMode] =
            React.useState<ColumnResizeMode>('onChange')

        const pagination = React.useMemo(
            () => ({
                pageIndex,
                pageSize,
            }),
            [pageIndex, pageSize],
        )

        const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
            company.getTableFilters(TABLE_NAME) ?? [],
        )
        const [sorting, setSorting] = useState<SortingState>(defaultSort ?? [])

        const dateOptionFilter: FilterFn<any> = (
            row,
            columnId,
            dates,
            addMeta,
        ) => {
            const columnValue = row.getValue(columnId)
            if (typeof columnValue === 'string') {
                const dateValue = new Date(columnValue)
                return dates.start <= dateValue && dates.end >= dateValue
            } else {
                return !dates.start
            }
        }
        dateOptionFilter.autoRemove = (value: any) => {
            return value.start === null
        }

        const multiOptionFilter: FilterFn<any> = (
            row,
            columnId,
            value,
            addMeta,
        ) => {
            return Array.isArray(value) && value.length > 0
                ? value.includes(row.getValue(columnId))
                : true
        }
        multiOptionFilter.autoRemove = (value: any) => {
            return (Array.isArray(value) && value.length < 1) || value === null
        }

        useEffect(() => {
            company.storeTableFilters(TABLE_NAME, columnFilters)
        }, [columnFilters])

        useEffect(() => {
            company.storeTableSort(TABLE_NAME, sorting)
            if (sorting.length > 0) {
                user.trackEvent('table_sort', {
                    name: sorting[sorting.length - 1].id,
                    direction: sorting[sorting.length - 1].desc
                        ? 'desc'
                        : 'asc',
                    values: sorting,
                    action: 'set',
                }).subscribe()
            }
        }, [sorting])

        const table = useReactTable({
            data: data,
            columns: columns,
            columnResizeMode,
            enableMultiSort: true,
            maxMultiSortColCount: 1,
            isMultiSortEvent: () => true,
            onPaginationChange: setPagination,
            getCoreRowModel: getCoreRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            filterFns: {
                multiOption: multiOptionFilter,
                dateOption: dateOptionFilter,
            },
            state: {
                columnFilters,
                sorting,
                pagination,
            },
            onSortingChange: setSorting,
            onColumnFiltersChange: setColumnFilters,
            getFacetedUniqueValues: getFacetedUniqueValues(),
            getSortedRowModel: getSortedRowModel(),
        })

        useEffect(() => {
            csvExportData(table.getPrePaginationRowModel().rows)
        }, [table, table.getPrePaginationRowModel().rows])

        return (
            <div className="">
                <table
                    className={classNames('m-auto bg-[#FFFFFF]')}
                    style={{
                        width: table.getCenterTotalSize(),
                    }}
                >
                    <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr
                                key={headerGroup.id}
                                className="sticky top-[-1px] z-[2] shadow-[0px_4px_8px_0px_rgba(16,18,58,0.12)]"
                            >
                                {headerGroup.headers.map((header) => (
                                    <th
                                        key={header.id}
                                        className={classNames(
                                            'bg-[#ffffff]',
                                            'border-[#f1f2f4] border-[1px]',
                                            'text-[#10123a] font-normal h-[68px]',
                                        )}
                                        style={{
                                            width: header.getSize(),
                                        }}
                                    >
                                        <div
                                            {...{
                                                onMouseDown:
                                                    header.getResizeHandler(),
                                                onTouchStart:
                                                    header.getResizeHandler(),
                                                className: `resizer ${
                                                    header.column.getIsResizing()
                                                        ? 'isResizing'
                                                        : ''
                                                }`,
                                                style: {
                                                    transform:
                                                        columnResizeMode ===
                                                            'onEnd' &&
                                                        header.column.getIsResizing()
                                                            ? `translateX(${
                                                                  table.getState()
                                                                      .columnSizingInfo
                                                                      .deltaOffset
                                                              }px)`
                                                            : '',
                                                },
                                            }}
                                        />
                                        {header.isPlaceholder ? null : (
                                            <div
                                                {...{
                                                    className:
                                                        header.column.getCanSort()
                                                            ? 'pl-3 text-left select-none inline-flex items-center w-[98%]'
                                                            : '',
                                                }}
                                            >
                                                <>
                                                    <span
                                                        className={classNames(
                                                            'cursor-pointer',
                                                            header.column.getIsFiltered() ||
                                                                header.column.getIsSorted()
                                                                ? 'text-[#3095aa]'
                                                                : 'text-[#10123a]',
                                                        )}
                                                        onClick={header.column.getToggleSortingHandler()}
                                                    >
                                                        {flexRender(
                                                            header.column
                                                                .columnDef
                                                                .header,
                                                            header.getContext(),
                                                        )}
                                                        {
                                                            {
                                                                asc: (
                                                                    <img
                                                                        onClick={header.column.getToggleSortingHandler()}
                                                                        src="/img/icons/green-arrow-down.svg"
                                                                        alt="Sort column"
                                                                        className="inline w-2 h-3 ml-1 cursor-pointer rotate-180"
                                                                    />
                                                                ),
                                                                desc: (
                                                                    <img
                                                                        onClick={header.column.getToggleSortingHandler()}
                                                                        src="/img/icons/green-arrow-down.svg"
                                                                        alt="Sort column"
                                                                        className="inline w-2 h-3 ml-1 cursor-pointer"
                                                                    />
                                                                ),
                                                            }[
                                                                header.column.getIsSorted() as string
                                                            ]
                                                        }
                                                    </span>
                                                    {header.column.getCanFilter() ? (
                                                        <Filter
                                                            initialValues={header.column.getFilterValue()}
                                                            column={
                                                                header.column
                                                            }
                                                            table={table}
                                                            sort={{
                                                                toggleHandler:
                                                                    header
                                                                        .column
                                                                        .getToggleSortingHandler,
                                                                isSorted:
                                                                    header.column.getIsSorted() as string,
                                                            }}
                                                        />
                                                    ) : null}
                                                </>
                                            </div>
                                        )}
                                    </th>
                                ))}
                                <th
                                    className={classNames(
                                        'border-[#f1f2f4] border-2 ',
                                        'bg-[#ffffff] w-[50px] px-5',
                                        'text-[#10123a] font-normal text-left',
                                    )}
                                >
                                    &nbsp;
                                </th>
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map((row, index) => (
                            <tr
                                key={row.id}
                                className={classNames(
                                    index % 2 ? '' : 'bg-[#f6f6f6]',
                                    'text-left h-[68px]',
                                )}
                            >
                                {row.getVisibleCells().map((cell) => (
                                    <td
                                        key={cell.id}
                                        className={classNames(
                                            'font-normal pl-3 ',
                                        )}
                                        style={{
                                            width: cell.column.getSize(),
                                        }}
                                    >
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext(),
                                        )}
                                    </td>
                                ))}
                                <td className="sticky bg-[#ffffff] right-[-1px] p-0 m-0 ">
                                    <div className="pt-[25px] border-[#f1f2f4] border-[1px] w-full h-[68px]">
                                        {row.original.status === 'active' && (
                                            <img
                                                onClick={() =>
                                                    binAction(
                                                        row.original._id,
                                                        row.original.firstName +
                                                            ' ' +
                                                            row.original
                                                                .lastName,
                                                    )
                                                }
                                                className="cursor-pointer w-[15px] m-auto"
                                                src="/img/icons/bin.svg"
                                                alt="Bin icon"
                                            />
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div
                    className={classNames(
                        isHorizontalScrollVisible ? 'bottom-2' : 'bottom-0',
                        'fixed w-full  py-2 bg-[#FFFFFF] shadow-[0px_4px_8px_0px_rgba(16,18,58,0.12)]',
                    )}
                >
                    <div className={classNames('w-[600px] m-auto')}>
                        <button
                            className={classNames(
                                'inline-block w-[20px]',
                                'border-solid text-center',
                                'cursor-pointer text-[#10123a]',
                                'rounded-sm align-middle',
                            )}
                            onClick={() => table.setPageIndex(0)}
                            disabled={!table.getCanPreviousPage()}
                        >
                            {'<<'}
                        </button>{' '}
                        <button
                            className={classNames(
                                'inline-block  w-[20px]',
                                'border-solid text-center',
                                'cursor-pointer text-[#10123a]',
                                'rounded-sm align-middle',
                            )}
                            onClick={() => table.previousPage()}
                            disabled={!table.getCanPreviousPage()}
                        >
                            {'<'}
                        </button>{' '}
                        <span>
                            {table
                                .getPageOptions()
                                .map((page: number, index: number) => {
                                    return (
                                        <span
                                            key={index}
                                            onClick={() =>
                                                table.setPageIndex(page)
                                            }
                                            className={classNames(
                                                'inline-block  w-[20px]',
                                                'border-solid text-center',
                                                'cursor-pointer text-[#10123a]',
                                                'rounded-sm align-middle',
                                                table.getState().pagination
                                                    .pageIndex === page &&
                                                    'bg-[#f1f8f9]',
                                            )}
                                        >
                                            {page + 1}
                                        </span>
                                    )
                                })}
                        </span>
                        <button
                            className={classNames(
                                'inline-block  w-[20px]',
                                'border-solid text-center',
                                'cursor-pointer text-[#10123a]',
                                'rounded-sm align-middle',
                            )}
                            onClick={() => table.nextPage()}
                            disabled={!table.getCanNextPage()}
                        >
                            {'>'}
                        </button>{' '}
                        <button
                            className={classNames(
                                'inline-block  w-[20px]',
                                'border-solid text-center',
                                'cursor-pointer text-[#10123a]',
                                'rounded-sm align-middle',
                            )}
                            onClick={() =>
                                table.setPageIndex(table.getPageCount() - 1)
                            }
                            disabled={!table.getCanNextPage()}
                        >
                            {'>>'}
                        </button>{' '}
                        <select
                            className={classNames(
                                'inline-block  w-[90px] h-[30px] ml-[10px]',
                                'border-solid text-center',
                                'cursor-pointer text-[#10123a]',
                                'rounded-sm align-middle',
                            )}
                            value={table.getState().pagination.pageSize}
                            onChange={(e) => {
                                table.setPageSize(Number(e.target.value))
                            }}
                        >
                            {[25, 50, 100, 150, 200, 250].map((size) => (
                                <option key={size} value={size}>
                                    {t('tabelList.pagination.show') as string}{' '}
                                    {size}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        )
    },
)
