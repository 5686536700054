import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
    CartesianGrid,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import {
    ValueType,
    NameType,
    Formatter,
    // @ts-ignore
} from 'recharts/lib/component/DefaultTooltipContent'
import { formatMonthYearByUTC } from '../../../util/misc'

interface Data {
    date: string
    value: number
}

interface Props {
    data: Data[]
}

export const MonthlyActiveUsers: React.FC<Props> = ({ data }) => {
    const { t } = useTranslation()

    const formatter: Formatter<NameType, NameType> = useMemo(
        () => (value: ValueType, name: NameType) =>
            [value as NameType, t(`extras.value`) as NameType],
        [t],
    )

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data} margin={{ left: -20, right: 50 }}>
                <CartesianGrid stroke="#F0F0F0" />
                <XAxis
                    type="category"
                    dataKey="date"
                    strokeWidth={1}
                    fontSize={12}
                    tickLine={false}
                    tickFormatter={formatMonthYearByUTC}
                    tick={{ fill: '#10123A' }}
                    interval={0}
                    axisLine={{ stroke: '#F0F0F0' }}
                />
                <YAxis
                    type="number"
                    strokeWidth={1}
                    fontSize={12}
                    tickLine={false}
                    tick={{ fill: '#10123A' }}
                    axisLine={{ stroke: '#F0F0F0' }}
                />
                <Tooltip
                    wrapperStyle={{ outline: 'none' }}
                    cursor={{ fill: '#EDEDED' }}
                    labelFormatter={formatMonthYearByUTC}
                    formatter={formatter}
                    itemStyle={{
                        fontSize: '12px',
                        marginTop: -8,
                        color: 'white',
                    }}
                    labelStyle={{
                        color: 'white',
                        marginBottom: 8,
                    }}
                    contentStyle={{
                        background: '#080922',
                        padding: 8,
                        borderRadius: 6,
                        border: 'none',
                    }}
                />
                <Line
                    type="monotone"
                    dataKey="value"
                    stroke="#FF7558"
                    activeDot={{ r: 4 }}
                />
            </LineChart>
        </ResponsiveContainer>
    )
}
