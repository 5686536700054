import { Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Subscription } from 'rxjs'
import { PrimaryButton } from '../../../components/buttons'
import { SelectInput, TextareaInput } from '../../../components/input'
import { CenterModal, ModalProps } from '../../../components/modals'
import { RemoveAccessModel } from '../../../models/request'
import { CompanyUser } from '../../../models/response'
import { cancelSubscriptions, Option } from '../../../util/misc'
import { useStores } from '../../../util/stores'
import { validateModel } from '../../../util/validation'

interface Props extends ModalProps {
    companyUser: CompanyUser
}

export const RemoveAccessModal: React.FC<Props> = observer(
    ({ isOpen, setIsOpen, companyUser }) => {
        const { t } = useTranslation()
        const { statics, company, user } = useStores()
        const subscriptions: Subscription[] = []
        const name =
            companyUser.user.firstName + ' ' + companyUser.user.lastName

        const reasonOptions = useMemo(() => {
            return statics.disableReasons.map(
                (reason): Option => ({
                    value: reason.key,
                    display: reason.name,
                }),
            )
        }, [statics.disableReasons])

        const close = useCallback(() => {
            user.trackEvent('button_clicked', {
                name: 'removeAdminAccess',
                action: 'close',
            }).subscribe()
            setIsOpen(false)
        }, [])

        const removeAccess = useCallback(
            (
                values: RemoveAccessModel,
                helpers: FormikHelpers<RemoveAccessModel>,
            ) => {
                helpers.setSubmitting(true)

                const subscription = company
                    .removeCompanyUserAccess(values, companyUser._id)
                    .subscribe({
                        next(response) {
                            helpers.setSubmitting(false)

                            if (response.ok) {
                                close()
                            }
                        },
                    })

                subscriptions.push(subscription)
            },
            [close, companyUser],
        )

        useEffect(() => {
            return () => {
                cancelSubscriptions(subscriptions)
            }
        }, [])

        return (
            <CenterModal isOpen={isOpen} setIsOpen={setIsOpen}>
                <header className="flex flex-col space-y-3 mb-6 mx-9">
                    <span className="text-2xl">
                        <Trans
                            t={t}
                            i18nKey="modals.removeAccess.heading"
                            tOptions={{ name }}
                        >
                            <b />
                        </Trans>
                    </span>
                    <span className="text-xs text-[#56576A]">
                        {t('modals.removeAccess.subText')}
                    </span>
                </header>
                <Formik
                    validate={validateModel}
                    initialValues={new RemoveAccessModel()}
                    onSubmit={removeAccess}
                >
                    {({ handleSubmit, values, isSubmitting, isValid }) => (
                        <form
                            onSubmit={handleSubmit}
                            className="divide-y divide-[black]/10"
                        >
                            <main className="space-y-4 mx-9 mb-6">
                                <SelectInput
                                    name="reason"
                                    options={reasonOptions}
                                    label={t('fields.reason.label')}
                                    placeholder={
                                        t('fields.reason.placeholder') as string
                                    }
                                />
                                {values.reason?.value === 'other' && (
                                    <TextareaInput
                                        name="reasonText"
                                        placeholder={
                                            t(
                                                'fields.reasonText.placeholder',
                                            ) as string
                                        }
                                    />
                                )}
                            </main>
                            <footer className="px-9 py-5 flex space-x-8">
                                <PrimaryButton
                                    trackingId="removeAdminAccess"
                                    trackingAction="cancel"
                                    type="button"
                                    className="flex-1 btn-open"
                                    onClick={close}
                                    disabled={isSubmitting}
                                >
                                    <span>{t('buttons.cancel')}</span>
                                </PrimaryButton>
                                <PrimaryButton
                                    trackingId="removeAdminAccess"
                                    trackingAction="deactivate"
                                    type="submit"
                                    className="flex-1 btn-red"
                                    disabled={isSubmitting || !isValid}
                                >
                                    <span>{t('buttons.remove')}</span>
                                </PrimaryButton>
                            </footer>
                        </form>
                    )}
                </Formik>
            </CenterModal>
        )
    },
)
