import React from 'react'
import { BarProps } from 'recharts'

export const RoundedBar: React.FC<Partial<BarProps>> = ({
    fill,
    x,
    y,
    width,
    height,
    layout,
}) => {
    const length =
        layout === 'vertical' ? height : width && width < 11 ? 11 : width

    return (
        <rect
            x={x}
            y={y}
            width={width && width < 11 ? 11 : width}
            height={height}
            rx={+length! / 2}
            ry={+length! / 2}
            fill={fill}
            stroke="none"
        />
    )
}
