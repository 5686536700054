import React, { PropsWithChildren, useState, useEffect } from 'react'
import { classNames } from '../../../util/misc'
import styled from 'styled-components'

/*******************
 * So no one else wastes hour with wondering why there
 * is a "normal" css style passed in here rather than using
 * tailwind.
 *
 * https://stackoverflow.com/questions/72550439/tailwind-css-unresponsive-to-react-state-change
 *
 * In short you can not create a string with tailwind you
 * must use an exact string
 *
 ********************/

interface Props extends PropsWithChildren {
    className?: string
    horizontalScroll?: Boolean
    scrollableStyle?: Object
}

const Wrapper = styled.div`
    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 4rem;
        background-image: linear-gradient(
            to left,
            rgba(255, 255, 255, 1),
            rgba(255, 255, 255, 0)
        );
        transision: all linear 0.3s;
    }
`
export const ScollableBlock: React.FC<Props> = ({
    children,
    className,
    scrollableStyle,
}) => {
    return (
        <Wrapper className={classNames('h-full', className)}>
            <div
                className={classNames(
                    'relative bg-white rounded-lg overflow-y-hidden flex-none overflow-x-auto h-full',
                )}
            >
                <div
                    className="flex-1 pt-8 w-[calc(100%+155px)] lg:w-[100%] h-full"
                    style={scrollableStyle}
                >
                    {children}
                </div>
            </div>
        </Wrapper>
    )
}
