import { FormikHelpers } from 'formik'
import { t } from 'i18next'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Subscription } from 'rxjs'
import { AddPhoneModel, TwoFaModel } from '../../../models/request'
import { RouteLink } from '../../../util/constants'
import { cancelSubscriptions } from '../../../util/misc'
import { useStores } from '../../../util/stores'
import { AuthPageWrapper } from '../components'
import { SetPhoneForm, VerifyPhoneForm } from './forms'

enum Stage {
    SET_PHONE,
    VERIFY_PHONE,
}

export const SignUpPage: React.FC = observer(() => {
    const navigate = useNavigate()
    const { auth } = useStores()
    const [stage, setStage] = useState<Stage>(Stage.SET_PHONE)
    const [subscriptions] = useState<Subscription[]>([])
    const companyName = auth.authResponse?.companyName
    const location = useLocation()
    const state = location.state as { canSignUp?: boolean } | undefined

    useEffect(() => {
        return () => {
            cancelSubscriptions(subscriptions)
        }
    }, [])

    useEffect(() => {
        setStage(auth.phone ? Stage.VERIFY_PHONE : Stage.SET_PHONE)
    }, [auth, setStage])

    const setPhoneNumber = useCallback(
        (values: AddPhoneModel, helpers: FormikHelpers<AddPhoneModel>) => {
            helpers.setSubmitting(true)
            cancelSubscriptions(subscriptions)

            const subscription = auth.setPhone(values).subscribe({
                next(response) {
                    if (response.ok) {
                        setStage(Stage.VERIFY_PHONE)
                    }
                },
                complete() {
                    helpers.setSubmitting(false)
                },
            })

            subscriptions.push(subscription)
        },
        [auth, setStage],
    )

    const verifyPhoneNumber = useCallback(
        (values: TwoFaModel, helpers: FormikHelpers<TwoFaModel>) => {
            helpers.setSubmitting(true)
            cancelSubscriptions(subscriptions)

            const subscription = auth.validate2fa(values).subscribe({
                next(response) {
                    if (response.ok) {
                        navigate(RouteLink.DASHBOARD, { replace: true })
                    }
                },
                complete() {
                    helpers.setSubmitting(false)
                },
            })

            subscriptions.push(subscription)
        },
        [auth],
    )

    useEffect(() => {
        if (!state?.canSignUp) {
            return navigate(RouteLink.INDEX, { replace: true })
        }
    }, [state])

    return (
        <AuthPageWrapper>
            {stage === Stage.SET_PHONE && (
                <SetPhoneForm
                    title={
                        t('titles.companyInvitesYou', {
                            company: companyName,
                        }) as string
                    }
                    onSubmit={setPhoneNumber}
                />
            )}
            {stage === Stage.VERIFY_PHONE && (
                <VerifyPhoneForm
                    title={
                        t('titles.companyInvitesYou', {
                            company: companyName,
                        }) as string
                    }
                    onSubmit={verifyPhoneNumber}
                />
            )}
        </AuthPageWrapper>
    )
})

export default SignUpPage
