import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { observer } from 'mobx-react'
import { CenterModal } from './index'
import { Trans, useTranslation } from 'react-i18next'
import { ProfilePictureCircle } from '../../components/misc'
import { Subscription } from 'rxjs'
import { useStores } from '../../util/stores'
import { classNames } from '../../util/misc'
import { cancelSubscriptions, formatDate } from '../../util/misc'
import { UploadLogoModal } from '../../pages/dashboard/modals'
import { Option } from '../../util/misc'
import { ListBox } from '../../components/input'
import { find } from 'lodash'
import { getStaticsValue } from '../../stores'
import { UpdateUserModel } from '../../models/request/company-user/update-user.model'
import { AccountLevel } from '../../util/constants'

interface Props {
    isOpen: boolean
    setIsOpen: any
}

const ImgIconElement: React.FC<any> = ({ onClick }) => {
    return (
        <div
            onClick={onClick}
            className={classNames(
                'absolute',
                'cursor-pointer bg-[#56576a] rounded-[50%]',
                'flex items-center justify-center px-[5px] py-[6.2px] ml-[53px] mt-[62px]',
            )}
        >
            <img src="/img/icons/img-icon.svg" width="13px" alt="Image icon" />
        </div>
    )
}

export const MyProfileModal: React.FC<Props> = observer(
    ({ isOpen, setIsOpen }) => {
        const { t } = useTranslation()
        const { user, company, statics } = useStores()
        const subscriptions: Subscription[] = []
        const [isUploadLogoModalOpen, setIsUploadLogoModalOpen] =
            useState(false)
        const [language, setLanguage] = useState<Option>()

        const initials = useMemo(() => {
            if (!user.user) {
                return 'N/A'
            }

            const firstInitial = user.user.firstName[0]?.toUpperCase?.() ?? ''
            const lastInitial = user.user.lastName[0]?.toUpperCase?.() ?? ''

            return firstInitial + lastInitial
        }, [user.user])

        useEffect(() => {
            if (
                company.activeCompanyId &&
                user.activeAssociation?.companyUser._id
            ) {
                subscriptions.push(user.getPicture().subscribe())
                setLanguage(getLanguage())
            }

            return () => {
                cancelSubscriptions(subscriptions)
            }
        }, [company.activeCompanyId, user.user])

        const openUploadLogoModalOpen = useCallback(
            (open: boolean) => {
                user.trackEvent('button_clicked', {
                    name: 'uploadLogo',
                    type: 'company-user',
                    action: open ? 'open' : 'close',
                }).subscribe()
                setIsUploadLogoModalOpen(open)
            },
            [company.activeCompanyId, user.user],
        )

        const changeAppLanguage = useCallback(
            (oldLang: string, newLang: string) => {
                user.trackEvent('dashboard_language', {
                    from: oldLang,
                    to: newLang,
                    type: 'company-user',
                    action: 'changed',
                }).subscribe()
            },
            [company.activeCompanyId, user.user],
        )

        const companyOptions: Option[] = useMemo(() => {
            return user.userAssociations.map((association) => ({
                value: association.company._id,
                display: association.company.label,
            }))
        }, [user.activeAssociation, user.user])

        const activeOption = useMemo(() => {
            return find(companyOptions, {
                value: user.activeAssociation?.company._id,
            })
        }, [companyOptions, user.activeAssociation])

        const selectCompany = useCallback(
            (option: Option) => {
                user.setActiveAssociationByCompanyId(option.value as string)
            },
            [user.user],
        )

        const getDashboardLanguageOptions = useCallback(() => {
            return statics.supportedLanguages.map((lang) => {
                return {
                    value: lang.key,
                    display: lang.name,
                } as Option
            })
        }, [company.activeCompanyId, user.user])

        const getLanguage = useCallback(() => {
            const language = getStaticsValue(
                statics.supportedLanguages,
                user.user?.appLanguage ?? 'en',
            )

            return {
                value: user.user?.appLanguage ?? 'en',
                display: language,
            }
        }, [company.activeCompanyId, user.user?.appLanguage])

        const updateAppLanguage = useCallback(
            (option: Option) => {
                const companyUserId = user.companyUserId
                if (companyUserId) {
                    const updateUser = new UpdateUserModel(option)
                    user.updateCompanyUser(companyUserId, updateUser).subscribe(
                        {
                            next() {
                                setLanguage(option)
                                user.changeSiteLanguage(option.value as string)
                            },
                        },
                    )
                    changeAppLanguage(getLanguage().display, option.display)
                }
            },
            [company.activeCompanyId, user.user],
        )

        return (
            <CenterModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                className="max-w-[32rem]"
            >
                <header className="flex flex-col space-y-3 mb-6 mx-9 items-center">
                    <UploadLogoModal
                        isOpen={isUploadLogoModalOpen}
                        setIsOpen={openUploadLogoModalOpen}
                        uploadType="company-user"
                        uploadId={user.activeAssociation?.companyUser._id ?? ''}
                        setPicture={user.setPicture}
                    />
                    <ImgIconElement
                        onClick={openUploadLogoModalOpen.bind(null, true)}
                    />
                    <ProfilePictureCircle
                        initials={initials}
                        picture={user.picture}
                        onClick={openUploadLogoModalOpen.bind(null, true)}
                        wrapperClassNames="cursor-pointer h-[70px] w-[70px]"
                    />
                    {companyOptions.length > 1 && (
                        <ListBox
                            className="z-50 w-40"
                            options={companyOptions}
                            buttonClassName=" bg-grey h-7 !rounded-full"
                            onSelect={selectCompany}
                            value={activeOption}
                            showArrow
                        />
                    )}

                    <span className="text-2xl">
                        {user.user?.firstName} {user.user?.lastName}
                    </span>
                </header>
                <main>
                    <div className="border-solid border-[1px] rounded-[10px] p-5 m-5 grid grid-cols-2 place-items-center">
                        <div className="grid place-items-center">
                            <img
                                src="/img/icons/date-joined.svg"
                                alt="Date user joind icon"
                                className="w-5 ml-3"
                            />
                            <span>
                                <Trans
                                    t={t}
                                    i18nKey="modals.myProfile.joinedContent"
                                    tOptions={{
                                        formattedDate: formatDate(
                                            user.user?._createdAt as string,
                                        ),
                                    }}
                                ></Trans>
                            </span>
                        </div>
                        <div className="grid place-items-center">
                            <img
                                src="/img/icons/envelope.svg"
                                alt="Envelope icon"
                                className="w-5 ml-3"
                            />
                            <span>
                                <a
                                    className="font-normal"
                                    href={`mailto:${user.user?.email}`}
                                >
                                    {user.user?.email}
                                </a>
                            </span>
                        </div>
                    </div>
                    {user.canAccess(AccountLevel.SAMA_ADMIN) && (
                        <div
                            className={classNames(
                                'flex flex-col w-[70%] m-auto pb-8',
                            )}
                        >
                            {t('modals.myProfile.dashboardLanguage.title')}
                            <ListBox
                                onSelect={updateAppLanguage}
                                value={language}
                                options={getDashboardLanguageOptions()}
                                placeholder={'placeholder'}
                                buttonClassName="!h-12"
                                optionsClassName="!w-full bg-[#f6f7f9] !divide-[#10123A]/[3%]"
                                optionClassName="text-accent-2"
                                optionClassNameActive="!text-accent"
                                optionClassNameSelected="!text-accent flex-row-reverse justify-between"
                                disabled={false}
                            />
                        </div>
                    )}
                </main>
            </CenterModal>
        )
    },
)
