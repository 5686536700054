import { observer } from 'mobx-react'
import * as Sentry from '@sentry/react'
import { Trans, useTranslation } from 'react-i18next'
import { useStores } from '../../../util/stores'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Tooltip } from '../../../components/tooltip'
import styled from 'styled-components'
import { FilePond, registerPlugin } from 'react-filepond'
import { getHeaders, getAccessToken } from '../../../util/request'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond/dist/filepond.min.css'
import ReactDOMServer from 'react-dom/server'
import { cancelSubscriptions, formatDate } from '../../../util/misc'
import { Subscription } from 'rxjs'
import { Company, FileDownload } from '../../../models/response'
import { BasicConfirmModal } from '../../dashboard/modals'
import { DocumentRenderer } from '@sama-io/react-doc-viewer'

registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateType,
)

const MAXIMUM_ALLOWED_FILES = 3

const Wrapper = styled.main`
    .filepond--root {
        font-family: inherit;
        min-height: 128px;
        border: 1px dashed #3095aa;
        border-radius: 0.5rem;
    }

    .filepond--drop-label {
        cursor: pointer;
        height: 128px;
    }

    .filepond--drop-label > label {
        cursor: pointer;
    }

    .filepond--panel-root {
        background: #f1f8f9cc;
        overflow: hidden;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
`
interface Props {
    companyInfo: Company
}

export const CompanyConfidentialInsights: React.FC<Props> = observer(
    ({ companyInfo }) => {
        const { t } = useTranslation()
        const { user, company } = useStores()
        const uploadType = 'company'
        const uploadId = companyInfo._id
        const subscriptions: Subscription[] = []
        const [files, setFiles] = useState(null as FileDownload[] | null)
        const [fileIdToDelete, setFileIdToDelete] = useState(
            null as string | null,
        )

        const [isFileOpen, openFile] = useState(false)
        const [fileToOpen, setFileToOpen] = useState(
            null as FileDownload | null,
        )

        useEffect(() => {
            getFiles()
        }, [companyInfo])

        const openFileView = useCallback(
            (file: FileDownload) => {
                openFile(true)
                setFileToOpen(file)
            },
            [isFileOpen],
        )

        const placeholder = useMemo(
            () => (
                <div className="flex flex-col place-items-center">
                    <img
                        src="/img/icons/upload-logo.svg"
                        className="h-6"
                        alt="Upload logo"
                    />
                    <span className="!text-accent-2 !text-[16px]">
                        <Trans
                            t={t}
                            i18nKey="modals.editCoachee.dragAndDrop"
                            tOptions={{ browse: t('modals.uploadLogo.browse') }}
                        >
                            <span className="text-[#3095AA]" />
                        </Trans>
                    </span>
                    <span className="!text-[#56576A] !text-[13px]">
                        {t('modals.editCoachee.supportedFileTypes')}
                    </span>
                </div>
            ),
            [t],
        )

        const getFiles = useCallback(() => {
            subscriptions.push(
                company
                    .getCompanyConfidentialInsightsFiles()
                    .subscribe((files) => {
                        setFiles(files.data)
                    }),
            )

            return () => {
                cancelSubscriptions(subscriptions)
            }
        }, [])

        const openConfmraionPopup = useCallback((fileId: string) => {
            setFileIdToDelete(fileId)
        }, [])

        const deleteFile = useCallback(() => {
            if (fileIdToDelete) {
                company
                    .deleteCompanyConfidentialInsightsFile(fileIdToDelete)
                    .subscribe(() => {
                        getFiles()
                    }),
                    user
                        .trackEvent('button_clicked', {
                            name: 'deleteCompanyConfidentialInsightFile',
                            action: 'delete',
                        })
                        .subscribe()
                setFileIdToDelete(null)
            }
        }, [fileIdToDelete])

        return (
            <>
                {!files ||
                    (files.length < MAXIMUM_ALLOWED_FILES && (
                        <div className="m-2 pt-3 pb-0 mb-0">
                            <div className="flex flex-row">
                                <span className="text-sm text-gray-600 mr-3">
                                    {t('modals.editCoachee.uploadFile')}
                                </span>
                                <Tooltip
                                    text={t('messages.userConfidentialInfo')}
                                    orientation="top"
                                >
                                    <img
                                        src="/img/icons/i-circle-open.svg"
                                        alt={t(
                                            'modals.editCoachee.userConfidentialInfo',
                                        ).toString()}
                                        className="w-5"
                                    />
                                </Tooltip>
                            </div>
                            <div className="py-5 pb-3 mt-0 place-items-center">
                                <Wrapper>
                                    <FilePond
                                        name="files"
                                        server={{
                                            process: (
                                                fieldName,
                                                file,
                                                _,
                                                load,
                                                error,
                                                progress,
                                                abort,
                                            ) => {
                                                const formData = new FormData()
                                                formData.append(
                                                    fieldName,
                                                    file,
                                                    file.name,
                                                )

                                                const request =
                                                    new XMLHttpRequest()
                                                const headers = getHeaders()

                                                request.open(
                                                    'POST',
                                                    process.env
                                                        .REACT_APP_BASE_URL +
                                                        '/company/' +
                                                        uploadId +
                                                        `/upload-insight`,
                                                )

                                                request.setRequestHeader(
                                                    'Authorization',
                                                    getAccessToken(),
                                                )

                                                request.upload.onprogress = (
                                                    e,
                                                ) => {
                                                    progress(
                                                        e.lengthComputable,
                                                        e.loaded,
                                                        e.total,
                                                    )
                                                }

                                                request.onload =
                                                    async function () {
                                                        if (
                                                            request.status >=
                                                                200 &&
                                                            request.status < 300
                                                        ) {
                                                            getFiles()
                                                            user.trackEvent(
                                                                'uploadedFile',
                                                                {
                                                                    type: uploadType,
                                                                },
                                                            ).subscribe()
                                                            load(
                                                                request.responseText,
                                                            )
                                                        } else {
                                                            Sentry.captureMessage(
                                                                'Upload failed: status:' +
                                                                    request.status +
                                                                    ' message: ' +
                                                                    request.responseText,
                                                            )
                                                            error('')
                                                        }
                                                    }

                                                request.send(formData)

                                                return {
                                                    abort: () => {
                                                        request.abort()
                                                        abort()
                                                    },
                                                }
                                            },
                                        }}
                                        instantUpload={true}
                                        credits={false}
                                        allowMultiple={true}
                                        labelIdle={ReactDOMServer.renderToString(
                                            placeholder,
                                        )}
                                        acceptedFileTypes={[
                                            'application/pdf',
                                            'application/msword',
                                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                            'application/vnd.ms-excel',
                                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                        ]}
                                    />
                                </Wrapper>
                            </div>
                        </div>
                    ))}
                <div>
                    {fileToOpen && (
                        <DocumentRenderer
                            isOpen={isFileOpen}
                            setIsOpen={() => openFile(false)}
                            file={fileToOpen}
                        />
                    )}

                    {files &&
                        files.map((file, index) => (
                            <span key={index}>
                                <div className="flex flex-row w-100 pl-5 py-3 mt-5">
                                    <span
                                        onClick={() => openFileView(file)}
                                        className="w-[10%] cursor-pointer"
                                    >
                                        <img
                                            src="/img/icons/file.svg"
                                            className="h-7 pr-5"
                                            alt="Edit user"
                                        />
                                    </span>
                                    <span
                                        onClick={() => openFileView(file)}
                                        className="w-[65%] cursor-pointer"
                                    >
                                        <p className="pb-0 mb-0 leading-4">
                                            {file.name}
                                        </p>
                                        <p className="text-xs text-[#56576a]">
                                            {(file.size / 100000).toFixed(2)}mb
                                            &#x2022;{' '}
                                            {formatDate(file.createdAt)}
                                        </p>
                                    </span>
                                    <span
                                        className="flex flex-row w-[10%] pt-1 cursor-pointer"
                                        onClick={() =>
                                            openConfmraionPopup(file.id)
                                        }
                                    >
                                        <p>{t('modals.editCoachee.delete')}</p>
                                        <img
                                            src="/img/icons/bin.svg"
                                            className="h-4 pl-2 mt-1"
                                            alt="Edit user"
                                        />
                                    </span>
                                </div>
                                <div className="w-[85%] h-[1px] ml-[6%] border-b-[1px]">
                                    &nbsp;
                                </div>
                            </span>
                        ))}
                    <BasicConfirmModal
                        title={t('modals.editCoachee.fileDeleteTitle')}
                        subText={t('modals.editCoachee.fileDeleteSubtext')}
                        isOpen={fileIdToDelete !== null}
                        setIsOpen={() => setFileIdToDelete(null)}
                        name="deleteConfidentialInsightFile"
                        onConfirm={() => deleteFile()}
                    />
                </div>
            </>
        )
    },
)
