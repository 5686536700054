import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { t } from 'i18next'
import { useStores } from '../../../util/stores'
import { classNames } from '../../../util/misc/class-names'
import { CompanyConfidentialInsights } from './company-confidential-insight'
import { EditCiModel } from '../models/edit-ci.model'
import Linkify from 'react-linkify'
import { Tooltip } from '../../../components/tooltip'

export const CompanyDetailTab: React.FC = observer(() => {
    const { company } = useStores()
    const [isCiPopupOpen, openCiPopup] = useState(false)

    return (
        <div className={classNames('w-full flex flex-col flex-wrap')}>
            <div className="pt-[30px] pl-[32px]">
                <h1 className="text-2xl">
                    {t('account.menuHeader.companyDetail')}
                </h1>
            </div>
            <div className="w-[72%] ml-auto mr-[4.5rem] py-[3rem]">
                <div className="flex flex-row">
                    <span className="text-sm text-gray-600 mr-3">
                        {t('account.companyDetail.companyConfidentiaInfo')}
                    </span>
                    <Tooltip
                        text={t('messages.companyConfidentialInfo')}
                        orientation="top"
                    >
                        <img
                            src="/img/icons/i-circle-open.svg"
                            alt="t(
                                        'modals.editCoachee.companyConfidentialInfo',
                                    )"
                            className="w-5"
                        />
                    </Tooltip>
                </div>
                <div
                    onClick={() => openCiPopup(true)}
                    className="bg-gray-100 rounded-[10px] p-5 mt-4 place-items-center h-30 max-h-[200px] overflow-y-auto cursor-pointer"
                >
                    <span className="font-normal whitespace-pre-wrap">
                        <Linkify>
                            {company.activeCompany?.confidentialInsight}
                        </Linkify>
                    </span>
                    <EditCiModel
                        isOpen={isCiPopupOpen}
                        setIsOpen={openCiPopup}
                    />
                </div>

                <CompanyConfidentialInsights
                    companyInfo={company.activeCompany!}
                />
            </div>
        </div>
    )
})
