import React from 'react'
import { classNames } from '../../../util/misc'

interface Props {
    children: React.ReactNode
}

export const AuthPageWrapper: React.FC<Props> = ({ children }) => {
    return (
        <div className="h-screen w-full min-w-screen flex flex-col justify-end">
            <div
                className={classNames(
                    'flex',
                    'flex-col',
                    'justify-center',
                    'items-center',
                    'sm:h-full',
                    'w-full sm:max-w-[560px]',
                    'bg-white',
                    'p-8 pt-[4.5rem] pb-10',
                )}
            >
                <img
                    src="/img/logo/logo-color.svg"
                    alt="Logo"
                    className="hidden sm:block absolute left-8 top-8 h-7"
                />
                <div className="max-w-[25rem] w-full">{children}</div>
                <img
                    src="/img/logo/logo-grayscale.svg"
                    alt="Logo"
                    className="mt-[4rem] sm:hidden"
                />
            </div>
        </div>
    )
}
