import { Dialog, Transition } from '@headlessui/react'
import React, { useCallback, Fragment } from 'react'
import { classNames } from '../../util/misc'
import { ModalCloseButton } from '../buttons'
import { ModalProps } from './modal-props'

interface Props extends ModalProps {
    className?: string
}

export const CenterModal: React.FC<Props> = ({
    children,
    className,
    isOpen,
    setIsOpen,
    initialFocus,
}) => {
    const close = useCallback(() => {
        setIsOpen(false)
    }, [setIsOpen])

    return (
        <Transition
            show={isOpen}
            enter="ease-out duration-300"
            enterFrom="translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo=" translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom=" translate-y-0 sm:scale-100"
            leaveTo="translate-y-4 sm:translate-y-0 sm:scale-95"
            as={Fragment}
        >
            <Dialog
                open={isOpen}
                onClose={close}
                className="z-50 isolate fixed inset-0 flex items-center justify-center p-5 overflow-y-auto scroll-my-5 h-screen"
                initialFocus={initialFocus}
            >
                <div
                    className="fixed inset-0 bg-[#030412]/30"
                    aria-hidden="true"
                />

                <Dialog.Panel
                    className={classNames(
                        'w-full max-w-[28rem] bg-white pt-9 scroll-py-10 rounded-md relative mt-5',
                        className,
                    )}
                >
                    <ModalCloseButton
                        onClick={close}
                        className="absolute right-3 top-3"
                    />
                    <main>{children}</main>
                </Dialog.Panel>
            </Dialog>
        </Transition>
    )
}
