import { Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Subscription } from 'rxjs'
import { PrimaryButton } from '../../../components/buttons'
import { TextInput } from '../../../components/input'
import { CenterModal } from '../../../components/modals'
import { DepartmentModel } from '../../../models/request/department/department.model'
import { Department } from '../../../models/response'
import { cancelSubscriptions } from '../../../util/misc'
import { useStores } from '../../../util/stores'
import { validateModel } from '../../../util/validation'

interface Props {
    department?: undefined | Department
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    onClose?: any
}

export const CreateOrUpdateDepartmentModal: React.FC<Props> = observer(
    ({ department, isOpen, setIsOpen, onClose }) => {
        const { t } = useTranslation()
        const { company } = useStores()
        const subscriptions: Subscription[] = []

        const close = () => {
            if (onClose) {
                onClose()
            }

            setIsOpen(false)
        }

        useEffect(() => {
            return () => {
                cancelSubscriptions(subscriptions)
            }
        }, [department, isOpen])

        const getDepartmentModel = useCallback(() => {
            return new DepartmentModel(department?._id, department?.name)
        }, [department, isOpen])

        const createOrUpdateDepartment = useCallback(
            (
                values: DepartmentModel,
                helpers: FormikHelpers<DepartmentModel>,
            ) => {
                helpers.setSubmitting(true)
                let subscription: Subscription
                if (department) {
                    subscription = company.updateDepartment(values).subscribe({
                        next(response) {
                            helpers.setSubmitting(false)

                            if (response.ok) {
                                close()
                            }
                        },
                    })
                } else {
                    subscription = company.addDepartment(values).subscribe({
                        next(response) {
                            helpers.setSubmitting(false)

                            if (response.ok) {
                                close()
                            }
                        },
                    })
                }
                subscriptions.push(subscription)
            },
            [close],
        )

        return (
            <CenterModal
                isOpen={isOpen}
                setIsOpen={close}
                className="max-w-[32rem]"
            >
                <header className="flex flex-col space-y-3 mb-6 mx-9">
                    <span className="text-2xl">
                        {!department && t('modals.createDepartment.heading')}
                        {department && t('modals.updateDepartment.heading')}
                    </span>
                </header>
                <main>
                    <Formik
                        validate={validateModel}
                        initialValues={getDepartmentModel()}
                        onSubmit={createOrUpdateDepartment}
                    >
                        {({ handleSubmit, isSubmitting, isValid }) => (
                            <form
                                onSubmit={handleSubmit}
                                className="divide-y divide-[black]/10"
                            >
                                <main className="space-y-4 mx-9 mb-6">
                                    <TextInput
                                        name="name"
                                        label={
                                            t(
                                                'fields.department.label',
                                            ) as string
                                        }
                                        placeholder={
                                            t(
                                                'fields.department.placeholder',
                                            ) as string
                                        }
                                    />
                                </main>
                                <footer className="px-9 py-5 flex space-x-8">
                                    <PrimaryButton
                                        trackingId="createDepartment"
                                        trackingAction="cancel"
                                        type="button"
                                        className="flex-1 btn-open"
                                        onClick={close}
                                    >
                                        <span>{t('buttons.cancel')}</span>
                                    </PrimaryButton>
                                    <PrimaryButton
                                        trackingId="createDepartment"
                                        trackingAction="save"
                                        type="submit"
                                        className="flex-1"
                                        disabled={isSubmitting || !isValid}
                                    >
                                        <span>
                                            {!department &&
                                                t('buttons.createDepartment')}
                                            {department &&
                                                t('buttons.updateDepartment')}
                                        </span>
                                    </PrimaryButton>
                                </footer>
                            </form>
                        )}
                    </Formik>
                </main>
            </CenterModal>
        )
    },
)
