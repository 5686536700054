/** @type {import('tailwindcss').Config} */
module.exports = {
    content: ['./src/**/*.{js,jsx,ts,tsx}'],
    theme: {
        extend: {
            colors: {
                primary: '#FF7558',
                accent: '#2E7B8B',
                'accent-dark': '#124C58',
                'accent-2': '#10123A',
                'light-teal': '#D1E5E9',
                'lighter-teal': '#f4f9f9',
                red: '#E14F51',
            },
        },
    },
    plugins: [require('tailwind-scrollbar')({ nocompatible: true }),],
}
