import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

interface EditOptionProps {
    isEditing: boolean
    onClick: any
}

export const EditOption: React.FC<EditOptionProps> = observer(
    ({ isEditing, onClick }) => {
        const { t } = useTranslation()
        return (
            <>
                <span className="text-sm mr-2">
                    <a onClick={onClick} className="font-normal" href="#">
                        {isEditing
                            ? (t('buttons.save') as string)
                            : (t('buttons.edit') as string)}
                    </a>
                </span>

                {!isEditing && (
                    <img
                        src="/img/icons/edit-icon.svg"
                        className="h-[9px]"
                        alt={`${t('buttons.edit')} icon`}
                    />
                )}
            </>
        )
    },
)
