import { Disclosure } from '@headlessui/react'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import DatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { PrimaryButton } from '../../../../components/buttons'
import { classNames, formatDate, formatMonthYear } from '../../../../util/misc'
import { useStores } from '../../../../util/stores'

const Wrapper = styled.div`
    .react-datepicker {
        font-family: inherit;
        font-size: 1rem;
        border: none;
    }

    .react-datepicker__header {
        background: unset;
        border-radius: none;
        border: none;
        padding: 0;
        margin-bottom: 6px;
    }

    .react-datepicker__current-month {
        font-size: 10px;
        font-weight: 400;
        margin-bottom: 12px;
    }

    .react-datepicker__month-container {
        width: 100%;
    }

    .react-datepicker__week {
        margin-bottom: 2.5px;
    }

    .react-datepicker__day-names {
        display: grid;
        justify-content: space-between;
        padding: 0;
        background: unset;
        grid-template-columns: repeat(7, 1fr);
        width: 100%;
    }

    .react-datepicker__day-name {
        color: #74758b;
        font-size: 8px;
        width: unset;
        margin: 0;
        text-transform: uppercase;
    }

    .react-datepicker__month {
        margin: 0;
    }

    .react-datepicker__week {
        display: grid;
        justify-content: space-between;
        padding: 0;
        background: unset;
        grid-template-columns: repeat(7, 1fr);
        height: 24px;
    }

    .react-datepicker__day {
        color: var(--accent-2);
        font-size: 8px;
        width: unset;
        margin: 0;
        border-radius: 0;
        height: 24px;
    }

    .react-datepicker__day--disabled {
        color: #ccc;
        text-decoration: line-through;
    }

    .react-datepicker__day--today {
        background: #f4f9f9;
        color: var(--accent);
        font-weight: normal;
    }

    .react-datepicker__day--keyboard-selected {
        background: #ffffff;
        color: var(--accent-2);
    }

    .react-datepicker__day--in-selecting-range {
        background: #6eb5c3;
        color: var(--accent);
        font-weight: normal;
    }

    .react-datepicker__day--in-range {
        color: white;
        background: var(--accent);
    }

    .react-datepicker__day--range-start {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
    }

    .react-datepicker__day--range-end {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
    }

    .react-datepicker__day--outside-month {
        opacity: 0;
        pointer-events: none;
    }
`

const CustomHeader: React.FC<ReactDatePickerCustomHeaderProps> = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
}) => (
    <div className="flex justify-between">
        <PrimaryButton
            trackingId="filter"
            trackingAction="previousMonth"
            className="btn-alt h-4 w-4 justify-center items-center inline-flex rounded-full !p-0"
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
        >
            <img
                src="/img/icons/chevron-down-green.svg"
                className="rotate-90 h-1"
                alt="Left chevron"
            />
        </PrimaryButton>
        <span className="text-xs text-accent-2">{formatMonthYear(date)}</span>
        <PrimaryButton
            trackingId="filter"
            trackingAction="nextMonth"
            className="btn-alt h-4 w-4 justify-center items-center inline-flex rounded-full !p-0"
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
        >
            <img
                src="/img/icons/chevron-down-green.svg"
                className="-rotate-90 h-1"
                alt="Right chevron"
            />
        </PrimaryButton>
    </div>
)

export const DateFiltersSection: React.FC = observer(() => {
    const { t } = useTranslation()
    const [startDate, setStartDate] = useState<Date | null>(new Date())
    const [endDate, setEndDate] = useState<Date | null>(new Date())

    const { company, user } = useStores()
    const isTouched =
        !!company.selectedFilters.date.start ||
        !!company.selectedFilters.date.end

    const trackFilterSelected = useCallback((start: string, end: string) => {
        user.trackEvent('dashboard_filters', {
            section: 'date_range',
            start: start,
            end: end,
            action: 'add',
        }).subscribe()
    }, [])

    const setDate = useCallback(
        ([start, end]: [Date | null, Date | null]) => {
            if (start && end) {
                // Only update filters if both have been set
                company.setFilter(['date'], {
                    start: start?.toISOString(),
                    end: end?.toISOString(),
                })

                trackFilterSelected(start.toISOString(), end.toISOString())
            }

            setStartDate(start)
            setEndDate(end)
        },
        [company],
    )

    const formatWeekDay = useCallback(
        // MON, TUE, WED, ...
        (weekDay: string) => weekDay.substring(0, 3),
        [],
    )

    useEffect(() => {
        if (company.selectedFilters.date.start) {
            setStartDate(new Date(company.selectedFilters.date.start))
        }

        if (company.selectedFilters.date.end) {
            setEndDate(new Date(company.selectedFilters.date.end))
        }
    }, [company.selectedFilters])

    return (
        <Wrapper>
            <Disclosure defaultOpen={isTouched}>
                <Disclosure.Button
                    className={classNames(
                        'flex items-center justify-between bg-[#F6F7F9] p-2.5 cursor-pointer',
                    )}
                    as="div"
                >
                    {({ open }) => (
                        <>
                            <div className="flex items-center space-x-2">
                                {isTouched && (
                                    <img
                                        src="/img/icons/check-circle.svg"
                                        alt="Check mark"
                                        className="h-3.5"
                                    />
                                )}
                                <span className="text-[14px]">
                                    {t('dashboard.filters.dateRange')}
                                </span>
                                {isTouched && (
                                    <span className="text-[7px] text-[#56576A] self-end">
                                        {formatDate(
                                            company.selectedFilters.date.start!,
                                        )}{' '}
                                        -{' '}
                                        {formatDate(
                                            company.selectedFilters.date.end!,
                                        )}
                                    </span>
                                )}
                            </div>
                            <div className="inline-flex space-x-1 items-center">
                                <img
                                    src="/img/icons/chevron-down.svg"
                                    className={classNames(
                                        'w-[6px]',
                                        open && 'rotate-180',
                                    )}
                                    alt="chevron"
                                />
                            </div>
                        </>
                    )}
                </Disclosure.Button>
                <Disclosure.Panel className="p-2.5">
                    <DatePicker
                        calendarClassName="w-full"
                        calendarStartDay={1} // Monday
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={new Date()}
                        minDate={
                            company.availableFilters?.date.start
                                ? new Date(company.availableFilters.date.start)
                                : undefined
                        }
                        onChange={setDate}
                        renderCustomHeader={CustomHeader}
                        formatWeekDay={formatWeekDay}
                        selectsRange
                        inline
                    />
                </Disclosure.Panel>
            </Disclosure>
        </Wrapper>
    )
})
