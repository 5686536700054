import { IsNotEmpty } from 'class-validator'
import { Option } from '../../../util/misc'
import { BaseModel } from '../base.model'

export class UpdateUserModel implements BaseModel {
    constructor(appLanguage: Option) {
        this.appLanguage = appLanguage
    }

    @IsNotEmpty()
    public appLanguage: Option | null = null

    public getRequestBody() {
        return {
            appLanguage: this.appLanguage?.value,
        }
    }
}
