import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import { Subscription } from 'rxjs'
import { AuthRequirement } from '../../pages/routes'
import { RouteLink } from '../../util/constants'
import { stores } from '../../util/stores'
import { cancelSubscriptions } from '../../util/misc'

interface Props {
    title: string
    component: React.FC
    authRequirements: AuthRequirement[]
}

type AuthRequirementResponse = [boolean, RouteLink]

export const AUTH_REQUIREMENTS: Record<
    AuthRequirement,
    () => AuthRequirementResponse
> = {
    [AuthRequirement.AUTHENTICATED]() {
        return [
            stores.auth.authenticated,
            RouteLink.LOG_IN,
        ] as AuthRequirementResponse
    },
    [AuthRequirement.UNAUTHENTICATED]() {
        const response = !stores.auth.authenticated

        if (stores.auth.authResponse?.isPhoneNeeded) {
            return [response, RouteLink.SET_PHONE]
        }
        if (stores.auth.authResponse?.awaiting2fa) {
            stores.reset()
            return [response, RouteLink.LOG_IN]
        }

        return [response, RouteLink.DASHBOARD]
    },
    [AuthRequirement.AWAITING_2FA]() {
        return [!!stores.auth.authResponse?.awaiting2fa, RouteLink.DASHBOARD]
    },
    [AuthRequirement.NOT_AWAITING_2FA]() {
        return [!stores.auth.authResponse?.awaiting2fa, RouteLink.TWO_FA]
    },
    [AuthRequirement.HAS_PHONE]() {
        return [!stores.auth.authResponse?.isPhoneNeeded, RouteLink.SET_PHONE]
    },
    [AuthRequirement.DOES_NOT_HAVE_PHONE]() {
        return [!!stores.auth.authResponse?.isPhoneNeeded, RouteLink.TWO_FA]
    },
    [AuthRequirement.FULLY_AUTHENTICATED]() {
        const [authenticated] = this[AuthRequirement.AUTHENTICATED]()
        const [awaiting2fa] = this[AuthRequirement.AWAITING_2FA]()
        const response = authenticated && !awaiting2fa

        if (stores.auth.authResponse?.isPhoneNeeded) {
            return [response, RouteLink.SET_PHONE]
        }

        if (stores.auth.authResponse?.awaiting2fa) {
            return [response, RouteLink.TWO_FA]
        }
        return [authenticated && !awaiting2fa, RouteLink.LOG_IN]
    },
    [AuthRequirement.FULLY_UNAUTHENTICATED]() {
        const [authenticated] = this[AuthRequirement.AUTHENTICATED]()
        const [awaiting2fa] = this[AuthRequirement.AWAITING_2FA]()
        return [!authenticated && !awaiting2fa, RouteLink.DASHBOARD]
    },
}

export const RouteWrapper: React.FC<Props> = observer(
    ({ title, component: Component, authRequirements }) => {
        const { t } = useTranslation()
        const subscriptions: Subscription[] = []
        let currentPage: string | null = null

        useEffect(() => {
            if (currentPage !== title) {
                currentPage = title
                const subscription = stores.user
                    .trackEvent('screen_viewed', { name: title })
                    .subscribe()
                subscriptions.push(subscription)
            }
        }, [title])

        for (const requirement of authRequirements) {
            const [verified, route] = AUTH_REQUIREMENTS[requirement]()

            if (!verified) {
                return (
                    <Navigate
                        to={route /* Link to correct page */}
                        replace={true}
                    />
                )
            }
        }

        return (
            <>
                <Helmet>
                    <title>{t(`pageTitles.${title}`)}</title>
                </Helmet>
                <Component />
            </>
        )
    },
)
