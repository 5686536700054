import { addHours } from 'date-fns'
import i18next from 'i18next'
import { memoize } from 'lodash'
import { action, computed, makeAutoObservable, runInAction } from 'mobx'
import { Static, Statics } from '../models/response'
import { HttpMethod } from '../util/constants'
import {
    dehydrateToStorage,
    hydrateFromStorage,
    removeFromStorage,
    Resettable,
} from '../util/misc'
import { request } from '../util/request'

const STATICS_KEY = 'SAMA:STATICS'
const HOURS_TO_CACHE_STATICS_KEY = 24
const STATICS_KEY_VERSION = 1

const SUPPORTED_LANG_CODES = ['en', 'fr']

interface StaticsCache {
    data: Statics
    version: number
    expires: string
}

export const getStaticsMap = memoize(
    (statics: Static[]): Record<string, string> => {
        return statics.reduce((acc, current) => {
            acc[current.key] = current.name
            return acc
        }, {} as any)
    },
)

export const getStaticsValue = memoize((statics: Static[], key: string) => {
    const data = getStaticsMap(statics)
    return data[key]
})

export class StaticsStore implements Resettable {
    private statics: Statics | null = null

    @computed
    public get disableReasons(): Static[] {
        return (
            this.statics?.disableReason.filter(
                ({ key }) => !['turn-over', 'anonymized'].includes(key),
            ) ?? []
        )
    }

    @computed
    public get competencies(): Static[] {
        return this.statics?.competencies ?? []
    }

    @computed
    public get positions(): Static[] {
        return this.statics?.positions ?? []
    }

    @computed
    public get functions(): Static[] {
        return this.statics?.functions ?? []
    }

    @computed
    public get userStatus(): Static[] {
        return this.statics?.userStatus ?? []
    }

    @computed
    public get coacheeGender(): Static[] {
        return this.statics?.coacheeGender ?? []
    }

    @computed
    public get countries(): Static[] {
        return this.statics?.countries ?? []
    }

    @computed
    public get supportedLanguages(): Static[] {
        if (!this.statics) {
            return []
        }

        return this.statics?.languages.filter(({ key }) =>
            SUPPORTED_LANG_CODES.includes(key),
        )
    }

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
    }

    @action
    public setStatics(statics: Statics) {
        this.statics = statics
        dehydrateToStorage(STATICS_KEY, {
            data: statics,
            version: STATICS_KEY_VERSION,
            expires: addHours(new Date(), HOURS_TO_CACHE_STATICS_KEY),
        })
    }

    @action
    public loadStatics(language: string = i18next.language.slice(0, 2)) {
        return new Promise((resolve, reject) => {
            const staticsCache: StaticsCache | null =
                hydrateFromStorage(STATICS_KEY, true) ?? null
            if (staticsCache && staticsCache.data) {
                const now = new Date()
                const expiresAt = new Date(staticsCache.expires)

                if (
                    now <= expiresAt &&
                    staticsCache.version === STATICS_KEY_VERSION
                ) {
                    this.statics = staticsCache.data
                }
                resolve(this.statics)
            }

            if (!this.statics) {
                request<never, Statics>('/statics', HttpMethod.GET, {
                    silentErrors: true,
                    query: {
                        language: language, // Language without region
                    },
                }).subscribe({
                    next: (response) => {
                        runInAction(() => {
                            if (response.data) {
                                this.setStatics(response.data)
                                resolve(response.data)
                            }
                        })
                    },
                })
            }
        })
    }

    @action
    public reset() {
        this.statics = null
        removeFromStorage(STATICS_KEY)
    }
}
