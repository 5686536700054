import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Cell,
    Label,
    LabelProps,
    Pie,
    PieChart as RechartsPieChart,
    ResponsiveContainer,
} from 'recharts'
import { CoachingHours } from '../../../models/response'

interface Props {
    data: CoachingHours
}

const centerLabel = (props: LabelProps) => {
    const { t } = useTranslation()
    const viewBox = props.viewBox as any

    return (
        <g>
            <text
                x={viewBox.cx}
                y={viewBox.cy - 40}
                textAnchor="middle"
                fontSize={27}
                fill="#10123A"
            >
                {props.value?.toString()}%
            </text>
            <text
                x={viewBox.cx}
                y={viewBox.cy - 20}
                textAnchor="middle"
                fontSize={12}
                fill="#56576A"
            >
                {t('dashboard.totalCoachingHours.totalAllocatedTimeUsed')}
            </text>
        </g>
    )
}

const usedLabel = (props: LabelProps) => {
    const { t } = useTranslation()
    const viewBox = props.viewBox as any
    const { innerRadius, outerRadius, cx, cy } = viewBox
    const x = cx - innerRadius - (outerRadius - innerRadius) / 2
    const y = cy + 15

    return (
        <g>
            <text x={x} y={y} textAnchor="middle" fontSize={14} fill="#10123A">
                {t(
                    props.value === 1
                        ? 'dashboard.totalCoachingHours.hourSingular'
                        : 'dashboard.totalCoachingHours.hourPlural',
                    { number: props.value },
                )}
            </text>
        </g>
    )
}

const totalLabel = (props: LabelProps) => {
    const { t } = useTranslation()
    const viewBox = props.viewBox as any
    const { innerRadius, outerRadius, cx, cy } = viewBox
    const x = cx + innerRadius + (outerRadius - innerRadius) / 2
    const y = cy + 15

    return (
        <g>
            <text x={x} y={y} textAnchor="middle" fontSize={10} fill="#10123A">
                {t(
                    props.value === 1
                        ? 'dashboard.totalCoachingHours.hourSingular'
                        : 'dashboard.totalCoachingHours.hourPlural',
                    { number: props.value },
                )}
            </text>
        </g>
    )
}

export const TotalCoachingHours: React.FC<Props> = ({ data }) => {
    const computedData = useMemo(() => {
        return [
            {
                name: 'used',
                value: data.used,
                color: '#FF7558',
            },
            {
                name: 'remaining',
                value: data.total - data.used,
                color: '#FFE3DE',
            },
        ]
    }, [data])

    return (
        <ResponsiveContainer width="100%" height="100%">
            <RechartsPieChart min-width="700px">
                <Pie
                    data={computedData}
                    cx="50%"
                    cy="65%"
                    innerRadius="80%"
                    outerRadius="110%"
                    dataKey="value"
                    labelLine={false}
                    animationBegin={0}
                    animationDuration={50}
                    paddingAngle={0}
                    endAngle={0}
                    startAngle={180}
                >
                    <Label content={centerLabel} value={data.percentageUsed} />
                    <Label content={usedLabel} value={data.used} />
                    <Label content={totalLabel} value={data.total} />
                    {computedData.map(({ color }, i) => (
                        <Cell fill={color} key={i} />
                    ))}
                </Pie>
            </RechartsPieChart>
        </ResponsiveContainer>
    )
}
