import { IsEmail, IsNotEmpty, MaxLength, MinLength } from 'class-validator'
import { t } from 'i18next'
import { Validation } from '../../../util/constants'
import { BaseModel } from '../base.model'

export class RequestCoacheeUpdateModel implements BaseModel {
    constructor(
        coacheeId: string,
        email: string,
        firstName: string,
        lastName: string,
    ) {
        ;(this.coacheeId = coacheeId),
            (this.email = email),
            (this.firstName = firstName),
            (this.lastName = lastName)
    }
    @IsNotEmpty({ message: t('validation.selectAnOption') as string })
    public coacheeId: string = ''

    @MinLength(Validation.SHORT_STRING_MIN_LENGTH, {
        message: t('validation.tooShort') as string,
    })
    @MaxLength(Validation.SHORT_STRING_MAX_LENGTH, {
        message: t('validation.tooLong') as string,
    })
    public firstName: string = ''

    @MinLength(Validation.SHORT_STRING_MIN_LENGTH, {
        message: t('validation.tooShort') as string,
    })
    @MaxLength(Validation.SHORT_STRING_MAX_LENGTH, {
        message: t('validation.tooLong') as string,
    })
    public lastName: string = ''

    @IsEmail(undefined, { message: t('validation.invalidFormat') as string })
    public email: string = ''

    public getRequestBody() {
        return {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
        }
    }
}
