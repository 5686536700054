import { HTMLMotionProps } from 'framer-motion'
import React, {
    ChangeEvent,
    Fragment,
    useEffect,
    useRef,
    useState,
    KeyboardEvent,
} from 'react'
import { classNames } from '../../util/misc'

interface Props extends HTMLMotionProps<'input'> {
    name: string
    label?: string
    setCode: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined,
    ) => void
}

let currentOtpIndex: number = 0

export const OtpInput: React.FC<Props> = ({
    name,
    label,
    className,
    setCode,
}) => {
    const [otp, setOtp] = useState<string[]>(new Array(4).fill(''))
    const [activeOtpIndex, setActiveOtpIndex] = useState<number>(0)
    const inputRef = useRef<HTMLInputElement>(null)
    const code = otp.join('')

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement>): void => {
        const { value } = target
        const newOtp: string[] = [...otp]
        newOtp[currentOtpIndex] = value.substring(value.length - 1)

        if (!value) {
            setActiveOtpIndex(currentOtpIndex - 1)
        } else {
            setActiveOtpIndex(currentOtpIndex + 1)
        }

        setOtp(newOtp)
    }

    const handleOnKeyDown = (
        e: KeyboardEvent<HTMLInputElement>,
        index: number,
    ) => {
        currentOtpIndex = index
        if (e.key === 'Backspace') {
            setActiveOtpIndex(currentOtpIndex - 1)
        }
    }

    useEffect(() => {
        inputRef.current?.focus()
        setCode('code', code, true)
    }, [activeOtpIndex, otp])

    return (
        <div className={classNames('flex flex-col', className)}>
            {label && (
                <label htmlFor={name} className="block text-[11px] mb-2">
                    {label}
                </label>
            )}
            <div>
                {otp.map((_, index: number) => {
                    return (
                        <Fragment key={index}>
                            <input
                                ref={index === activeOtpIndex ? inputRef : null}
                                type="number"
                                className="w-[22.3%] md:w-[23%] h-14 mr-2 rounded bg-[#f6f7f9] outline-none text-center text-xl text-[#10123a] transition"
                                onChange={handleChange}
                                value={otp[index]}
                                onKeyDown={(e) => handleOnKeyDown(e, index)}
                            />
                        </Fragment>
                    )
                })}
            </div>
        </div>
    )
}
