import { Allow } from 'class-validator'
import { BaseModel } from '../base.model'

export class MagicLoginModel extends BaseModel {
    @Allow()
    public magicToken: string

    @Allow()
    public company?: string

    constructor(magicToken: string, company?: string) {
        super()
        this.magicToken = magicToken
        this.company = company
    }
}
