import { Disclosure, Transition } from '@headlessui/react'
import { Table, Column } from '@tanstack/react-table'
import { format, isValid, parseISO, subDays, subYears } from 'date-fns'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Checkbox } from '../../pages/dashboard/components'
import { getStaticsMap } from '../../stores'
import { classNames } from '../../util/misc'
import { useStores } from '../../util/stores'
import { TableFilterInput } from '../input/table-filter-input'
import { rankItem } from '@tanstack/match-sorter-utils'
import { useTranslation } from 'react-i18next'
interface Props {
    column: Column<any, unknown>
    table: Table<any>
    sort: {
        toggleHandler: any
        isSorted: string
    }
    initialValues: any
}

export const Filter: React.FC<Props> = ({
    column,
    table,
    sort,
    initialValues,
}) => {
    const isDateFilter = column.columnDef.filterFn === 'dateOption'
    const [isOpen, setIsOpen] = useState(false)
    const wrapperRef = useRef(null)
    const [selected, setSelected] = useState(initialValues ?? ([] as string[]))
    const [startDate, setStartDate] = useState(
        isDateFilter && initialValues && initialValues.start !== undefined
            ? new Date(initialValues.start)
            : (null as Date | null),
    )
    const [endDate, setEndDate] = useState(
        isDateFilter && initialValues && initialValues.end !== undefined
            ? new Date(initialValues.end)
            : new Date(),
    )
    const [values, setValues] = useState([] as string[])
    const { statics, user } = useStores()
    const { t } = useTranslation()

    const firstValue = table
        .getPreFilteredRowModel()
        .flatRows[0]?.getValue(column.id)

    const sortedUniqueValues = React.useMemo(() => {
        if (typeof firstValue === 'number') {
            return []
        } else {
            const data = Array.from(
                column.getFacetedUniqueValues().keys(),
            ).sort()
            setValues(data)
            return data
        }
    }, [column.getFacetedUniqueValues()])

    const getFormatedValue = useCallback((value: any) => {
        if (column.columnDef.meta?.staticsKey) {
            return (
                getStaticsMap(
                    (statics as any)[column.columnDef.meta?.staticsKey],
                )[value] ?? 'N/A'
            )
        } else if (column.columnDef.meta?.translationKey) {
            const key = column.columnDef.meta?.translationKey + '.' + value
            return t(key) as string
        } else if (typeof value == 'boolean') {
            return value ? 'Yes' : 'No'
        } else {
            return value
        }
    }, [])

    const onSearchValueChange = useCallback(
        (value: string) => {
            if (value) {
                user.trackEvent('table_filter_search', {
                    name: column.columnDef.meta?.trackableName,
                    value: value,
                    action: 'set',
                }).subscribe()

                const data = sortedUniqueValues.filter(
                    (filterValue) =>
                        rankItem(getFormatedValue(filterValue), value).passed,
                )
                setValues(data)
            } else {
                user.trackEvent('table_filter_search', {
                    name: column.columnDef.meta?.trackableName,
                    action: 'clear',
                }).subscribe()
                setValues(sortedUniqueValues)
            }
        },
        [column.getFacetedUniqueValues()],
    )

    const clearDate = () => {
        user.trackEvent('table_filter', {
            name: column.columnDef.id,
            action: 'clear',
        }).subscribe()

        setStartDate(null)
        setEndDate(new Date())
        column.setFilterValue({ start: null, end: new Date() })
    }

    const onDateChange = (date: Date, type: string) => {
        user.trackEvent('table_filter', {
            name: column.columnDef.id + ' - [' + type + ']',
            value: date,
            action: 'set',
        }).subscribe()

        if (type === 'start') {
            date.setHours(0)
            date.setMinutes(0)
            date.setSeconds(0)
            column.setFilterValue({ start: date, end: endDate })
            setStartDate(date)
        }
        if (type === 'end') {
            date.setHours(23)
            date.setMinutes(59)
            date.setSeconds(59)
            column.setFilterValue({ start: startDate, end: date })
            setEndDate(date)
        }
    }

    const showSearchBox = React.useMemo(() => {
        return !isDateFilter && typeof firstValue !== 'boolean'
    }, [column.getFacetedUniqueValues()])

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                wrapperRef.current &&
                // @ts-ignore
                !wrapperRef.current.contains(event.target)
            ) {
                setTimeout(() => setIsOpen(false), 160)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [wrapperRef])

    return (
        <Disclosure defaultOpen={false}>
            <Disclosure.Button
                className={classNames('cursor-pointer')}
                onClick={() => setIsOpen(!isOpen)}
            >
                <>
                    {isOpen && (
                        <img
                            src="/img/icons/filter.svg"
                            className={classNames('inline w-4 ml-2')}
                            alt="Filter icon"
                        />
                    )}
                    {!isOpen && (
                        <img
                            src="/img/icons/filter-applied.svg "
                            className={classNames('inline w-4 ml-2')}
                            alt="Filter icon"
                        />
                    )}
                </>
            </Disclosure.Button>
            <Transition show={isOpen}>
                <Disclosure.Panel
                    static
                    ref={wrapperRef}
                    className={classNames(
                        'absolute pt-2.5 mt-[22px] max-h-[250px]',
                        'rounded-[10px]',
                        'shadow-[0_4px_20px_0_rgba(16,18,58,0.12)]',
                        'bg-[#FFFFFF] w-[200px]',
                    )}
                >
                    <div>
                        <div className="inline-flex text-right  mb-2 pl-[10px] w-full shadow-[0px_4px_6px_0_rgba(16,18,58,0.12)]">
                            {showSearchBox && (
                                <TableFilterInput
                                    value={''}
                                    onValueChange={onSearchValueChange}
                                    placeholder={
                                        t(
                                            'tabelList.columnSearchPlaceHolder',
                                        ) as string
                                    }
                                />
                            )}

                            {!showSearchBox && isDateFilter && (
                                <div
                                    className="text-sm cursor-pointer"
                                    onClick={clearDate}
                                >
                                    {t('messages.resetDates')}
                                </div>
                            )}

                            {{
                                asc: (
                                    <span className="inline ml-auto py-[3px] px-[2px] mb-[5px] mr-[5px] border-[#f1f2f4] border-[1px]">
                                        <img
                                            onClick={sort.toggleHandler()}
                                            src="/img/icons/sort-asc.svg"
                                            alt="Sort column"
                                            className=" w-5 h-5 cursor-pointer"
                                        />
                                    </span>
                                ),
                                desc: (
                                    <span className="inline ml-auto py-[3px] px-[2px] mb-[5px] mr-[5px] border-[#f1f2f4] border-[1px]">
                                        <img
                                            onClick={sort.toggleHandler()}
                                            src="/img/icons/sort-desc.svg"
                                            alt="Sort column"
                                            className=" w-5 h-5 cursor-pointer"
                                        />
                                    </span>
                                ),
                            }[sort.isSorted as string] ?? (
                                <span className="inline ml-auto py-[3px] px-[2px] mb-[5px] mr-[5px] border-[#f1f2f4] border-[1px]">
                                    <img
                                        onClick={sort.toggleHandler()}
                                        src="/img/icons/sort.svg"
                                        alt="Sort column"
                                        className=" w-5 h-5 cursor-pointer"
                                    />
                                </span>
                            )}
                        </div>
                        <div
                            className={classNames(
                                'scrollbar-thin scrollbar-thumb-[#c4c4c4] scrollbar-thumb-rounded',
                                'max-h-[200px] rounded-b-[10px]',
                                'bg-[#FFFFFF] overflow-y-auto ',
                            )}
                        >
                            {!isDateFilter &&
                                values
                                    .slice(0, 5000)
                                    .map((value: any, index: number) => {
                                        const notSelectedValues =
                                            selected.filter(
                                                (selected: string) =>
                                                    selected !== value,
                                            )
                                        const checked =
                                            selected.filter(
                                                (selected: string) =>
                                                    selected === value,
                                            ).length > 0

                                        const onChange = () => {
                                            let values: string[] = []
                                            if (checked) {
                                                values = notSelectedValues
                                            } else {
                                                values = [
                                                    ...selected,
                                                    ...[value],
                                                ]
                                            }
                                            column.setFilterValue(values)
                                            setSelected(values)
                                            user.trackEvent('table_filter', {
                                                name: column.columnDef.meta
                                                    ?.trackableName,
                                                value: values,
                                                action: 'set',
                                            }).subscribe()
                                        }

                                        return (
                                            <Checkbox
                                                key={index}
                                                checked={checked}
                                                onChange={onChange}
                                                active={true}
                                                label={getFormatedValue(value)}
                                                lineClassName={
                                                    index % 2
                                                        ? 'h-[30px] w-full pl-[10px]'
                                                        : 'h-[30px] bg-[#f6f6f6] w-full pl-[10px]'
                                                }
                                            />
                                        )
                                    })}
                        </div>
                        {isDateFilter && (
                            <>
                                <input
                                    type="date"
                                    className="picker flex flex-row-reverse outline-none h-[30px] w-full pl-[20px]"
                                    id="start"
                                    name="trip-start"
                                    value={
                                        startDate
                                            ? format(startDate, 'yyyy-MM-dd')
                                            : ''
                                    }
                                    min={format(
                                        subYears(new Date(), 4),
                                        'yyyy-MM-dd',
                                    )}
                                    max={format(
                                        subDays(new Date(), 1),
                                        'yyyy-MM-dd',
                                    )}
                                    onChange={(e) => {
                                        if (e.currentTarget.valueAsDate) {
                                            onDateChange(
                                                e.currentTarget.valueAsDate,
                                                'start',
                                            )
                                        }
                                    }}
                                />
                                <input
                                    type="date"
                                    className="picker flex flex-row-reverse outline-none h-[30px] bg-[#f6f6f6] w-full pl-[20px]"
                                    id="end"
                                    name="end"
                                    value={format(endDate, 'yyyy-MM-dd')}
                                    min={format(
                                        subYears(new Date(), 4),
                                        'yyyy-MM-dd',
                                    )}
                                    max={format(new Date(), 'yyyy-MM-dd')}
                                    onChange={(e) => {
                                        if (e.currentTarget.valueAsDate) {
                                            onDateChange(
                                                e.currentTarget.valueAsDate,
                                                'end',
                                            )
                                        }
                                    }}
                                />
                            </>
                        )}
                    </div>
                </Disclosure.Panel>
            </Transition>
        </Disclosure>
    )
}
