import { Dialog, Transition } from '@headlessui/react'
import React, { useCallback, Fragment } from 'react'
import { ModalCloseButton } from '../buttons'
import { ModalProps } from './modal-props'

export const SideModal: React.FC<ModalProps> = ({
    children,
    isOpen,
    setIsOpen,
    initialFocus,
    width,
}) => {
    const close = useCallback(() => {
        setIsOpen(false)
    }, [setIsOpen])

    return (
        <Transition
            show={isOpen}
            enter="transform transition ease-in-out duration-400"
            enterFrom="translate-x-full"
            enterTo="translate-x-0 bg-[#030412]/30"
            leave="transform transition ease-in-out duration-400"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
            as={Fragment}
        >
            <Dialog
                onClose={close}
                className="z-50 fixed inset-0"
                initialFocus={initialFocus}
            >
                <div className="" aria-hidden="true" />
                <div className="fixed inset-0 flex items-center justify-end ">
                    <Dialog.Panel
                        className={`h-full overflow-y-auto w-full bg-white px-8 py-10 scroll-py-10 ${
                            width ? width : 'max-w-[28rem]'
                        }`}
                    >
                        <ModalCloseButton
                            onClick={close}
                            className="absolute right-3 top-3"
                        />
                        <main>{children}</main>
                    </Dialog.Panel>
                </div>
            </Dialog>
        </Transition>
    )
}
