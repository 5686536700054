import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    quarter: string[]
}

export const InsufficentChartMessage: React.FC<Props> = ({ quarter }) => {
    const { t } = useTranslation()

    return (
        <div className="text-center mb-4">
            {quarter.length !== 0
                ? t('messages.insufficentData', {
                      quarter: `${quarter}`,
                  })
                : ''}
        </div>
    )
}
