export const areaOfCoaching = [
    { name: 'lifeBalance', color: '#FF7558' },
    { name: 'engagement', color: '#FFAC9B' },
    { name: 'leadership', color: '#FFE3DE' },
    { name: 'performance', color: '#3095AA' },
    { name: 'communication', color: '#83BFCC' },
]

export const seniorityLevel = [
    { name: 'executive', color: '#FF7558' },
    { name: 'admin', color: '#FFAC9B' },
    { name: 'associates', color: '#FFE3DE' },
    { name: 'senior', color: '#3095AA' },
    { name: 'manager', color: '#83BFCC' },
]
