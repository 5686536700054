import {
    Allow,
    IsEmail,
    IsNotEmpty,
    IsOptional,
    MaxLength,
    MinLength,
} from 'class-validator'
import { t } from 'i18next'
import { Validation } from '../../../util/constants'
import { Option } from '../../../util/misc'
import { stores } from '../../../util/stores'
import { BaseModel } from '../base.model'

export class InviteCoacheeModel implements BaseModel {
    @MinLength(Validation.SHORT_STRING_MIN_LENGTH, {
        message: t('validation.tooShort') as string,
    })
    @MaxLength(Validation.SHORT_STRING_MAX_LENGTH, {
        message: t('validation.tooLong') as string,
    })
    public firstName: string = ''

    @MinLength(Validation.SHORT_STRING_MIN_LENGTH, {
        message: t('validation.tooShort') as string,
    })
    @MaxLength(Validation.SHORT_STRING_MAX_LENGTH, {
        message: t('validation.tooLong') as string,
    })
    public lastName: string = ''

    @IsEmail(undefined, { message: t('validation.invalidFormat') as string })
    public email: string = ''

    public appLanguage: Option | null = null

    @IsNotEmpty({ message: t('validation.selectAnOption') as string })
    public country: Option | null = null

    @MinLength(Validation.SHORT_STRING_MIN_LENGTH, {
        message: t('validation.tooShort') as string,
    })
    @MaxLength(Validation.SHORT_STRING_MAX_LENGTH, {
        message: t('validation.tooLong') as string,
    })
    public city: string = ''

    @IsOptional()
    public department: Option | null = null

    @IsNotEmpty({ message: t('validation.selectAnOption') as string })
    public timeZone: Option | null = null

    public getRequestBody() {
        return {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            department: this.department?.value,
            appLanguage: this.appLanguage?.value,
            address: {
                country: this.country?.value,
                city: this.city,
            },
            _timezone: this.timeZone?.value,
            company: stores.company.activeCompanyId,
        }
    }
}
