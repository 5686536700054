import { Formik, FormikHelpers } from 'formik'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import { CenterModal } from '../../../components/modals'
import { Trans, useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../../components/buttons'
import { validateModel } from '../../../util/validation'
import { Subscription } from 'rxjs'
import { useStores } from '../../../util/stores'
import { cancelSubscriptions } from '../../../util/misc'
import { RequestCoacheeUpdateModel } from '../../../models/request'
import { TextInput } from '../../../components/input'
import { isEqual } from 'lodash'
import { FormValidationMessage } from '../../../components/layout'

interface Props {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    coacheeDetails: {
        _id: string
        firstName: string
        lastName: string
        email: string
    }
}

export const RequestEditFieldsCoacheeModal: React.FC<Props> = observer(
    ({ isOpen, setIsOpen, coacheeDetails }) => {
        const { t } = useTranslation()
        const { company, user } = useStores()
        const subscriptions: Subscription[] = []
        const [errorMessage, setErrorMessage] = useState(
            false as string | false,
        )

        const close = useCallback(() => {
            user.trackEvent('button_clicked', {
                name: 'requestChangeFieldsCoachee',
                action: 'close',
            }).subscribe()
            setIsOpen(false)
        }, [])

        const requestChangesCoachee = useCallback(
            (
                values: RequestCoacheeUpdateModel,
                helpers: FormikHelpers<RequestCoacheeUpdateModel>,
            ) => {
                helpers.setSubmitting(true)
                const initialValues = getInitialValues()

                if (isEqual(initialValues, values)) {
                    helpers.setSubmitting(false)
                    setErrorMessage(t('validation.valuesIdentical') as string)
                } else {
                    subscriptions.push(
                        company.sendEndRequest(values).subscribe(() => {
                            helpers.setSubmitting(false)
                            close()
                        }),
                    )
                }
            },
            [close, coacheeDetails],
        )

        useEffect(() => {
            setErrorMessage(false)
            return () => {
                cancelSubscriptions(subscriptions)
            }
        }, [isOpen])

        const getInitialValues = useCallback(() => {
            return new RequestCoacheeUpdateModel(
                coacheeDetails._id,
                coacheeDetails.email,
                coacheeDetails.firstName,
                coacheeDetails.lastName,
            )
        }, [coacheeDetails])

        return (
            <CenterModal
                isOpen={isOpen}
                setIsOpen={close}
                className="max-w-[32rem]"
            >
                <header className="flex flex-col space-y-3 mb-6 mx-9">
                    <span className="text-2xl">
                        <Trans
                            t={t}
                            i18nKey="modals.requestChangesCoachee.heading"
                        >
                            <b />
                        </Trans>
                    </span>
                    <span className="text-xs text-[#56576A]">
                        {t('modals.requestChangesCoachee.subText')}
                    </span>
                </header>
                <main>
                    <Formik
                        validate={validateModel}
                        initialValues={getInitialValues()}
                        onSubmit={requestChangesCoachee}
                    >
                        {({ handleSubmit, values, isSubmitting, isValid }) => (
                            <form
                                onSubmit={handleSubmit}
                                className="divide-y divide-[black]/10"
                            >
                                <main className="space-y-4 mx-9 mb-6">
                                    {errorMessage && (
                                        <div className="mt-[10px]">
                                            <FormValidationMessage
                                                message={errorMessage}
                                            />
                                        </div>
                                    )}
                                    <TextInput
                                        name="email"
                                        value={coacheeDetails.email}
                                        label={
                                            t('fields.email.label') as string
                                        }
                                        placeholder={
                                            t(
                                                'fields.email.placeholder',
                                            ) as string
                                        }
                                    />
                                    <TextInput
                                        name="firstName"
                                        value={coacheeDetails.firstName}
                                        label={
                                            t(
                                                'fields.firstName.label',
                                            ) as string
                                        }
                                        placeholder={
                                            t(
                                                'fields.firstName.placeholder',
                                            ) as string
                                        }
                                    />
                                    <TextInput
                                        name="lastName"
                                        value={coacheeDetails.lastName}
                                        label={
                                            t('fields.lastName.label') as string
                                        }
                                        placeholder={
                                            t(
                                                'fields.lastName.placeholder',
                                            ) as string
                                        }
                                    />
                                </main>
                                <footer className="px-9 py-5 flex space-x-8">
                                    <PrimaryButton
                                        trackingId="cancelRequestChangeFieldsCoachee"
                                        trackingAction="cancel"
                                        type="button"
                                        className="flex-1 btn-open"
                                        onClick={close}
                                        disabled={isSubmitting}
                                    >
                                        <span>{t('buttons.cancel')}</span>
                                    </PrimaryButton>
                                    <PrimaryButton
                                        trackingId="requestChangeFieldsCoachee"
                                        trackingAction="requestChange"
                                        type="submit"
                                        className="flex-1 btn"
                                        disabled={isSubmitting || !isValid}
                                    >
                                        <span>
                                            {t('buttons.requestChange')}
                                        </span>
                                    </PrimaryButton>
                                </footer>
                            </form>
                        )}
                    </Formik>
                </main>
            </CenterModal>
        )
    },
)
