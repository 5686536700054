import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useSearchParams } from 'react-router-dom'
import { useStores } from '../../../util/stores'
import { Subscription } from 'rxjs'

export const DownloadPdfPage: React.FC = observer(() => {
    const [search] = useSearchParams()
    const fileName = search.get('name')
    const { company } = useStores()
    const [subscriptions] = useState<Subscription[]>([])
    const [pdfFile, setPdfFile] = useState('')

    useEffect(() => {
        if (fileName) {
            const subscription = company.getCompanyPdf(fileName).subscribe({
                next(response) {
                    if (response?.data?.url) {
                        setPdfFile(response.data.url)
                    }
                },
            })
            subscriptions.push(subscription)
        }
    }, [])

    return (
        <div className="h-screen w-screen flex">
            {pdfFile && (
                <iframe width="100%" height="100%" src={pdfFile}></iframe>
            )}
            {!pdfFile && <h1>File not found</h1>}
        </div>
    )
})

export default DownloadPdfPage
