import { useField } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { classNames, Option } from '../../util/misc'
import { FormValidationMessage } from '../layout'
import TimezoneSelect, {
    ITimezoneOption,
    allTimezones,
} from 'react-timezone-select'
import styled from 'styled-components'
import soft from 'timezone-soft'
import spacetime from 'spacetime'

interface Props {
    name: string
    label?: string | React.ReactNode
    className?: string
    placeholder?: string
    options: Option[]
    disabled?: boolean
    isEditing?: boolean
    onKeyDown?: any
}

const Wrapper = styled.div`
    .timeZonePicker {
        min-width: 300px;
        border-radius: 0.25rem;
    }
    .timeZonePicker > div :nth-child(2) > span {
        display: none;
    }
    .timeZonePicker > div :nth-child(2) > div > svg {
        display: none;
    }
    .timeZonePicker > div {
        height: 50px;
        background-color: rgb(246 247 249 / var(--tw-bg-opacity));
        font-size: 13px;
    }

    .timeZonePicker > :nth-child(4) {
        height: 300px;
    }

    .timeZonePicker > :nth-child(4) > div {
        height: 300px;
    }

    .timeZonePicker > div :nth-child(2) > div::before {
        background-image: url('/img/icons/chevron-down.svg');
        background-size: 10px 20px;
        display: inline-block;
        width: 10px;
        height: 20px;
        content: '';
    }
`

export const TimezoneInput: React.FC<Props> = ({
    name,
    label,
    className,
    onKeyDown,
    isEditing = true,
}) => {
    const [field, meta, helpers] = useField(name)
    const [timezoneDisplay, setTimezoneDisplay] = useState(
        null as string | null,
    )

    // This code is copied from the react-timezone-select
    // code so that we can display the same format as they
    // do in the drop down but on the screen
    useEffect(() => {
        if (field.value?.value) {
            const now = spacetime.now(field.value.value)
            const tzStrings = soft(field.value.value)
            const tz = now.timezone()

            let abbr = now.isDST()
                ? tzStrings[0].daylight?.abbr
                : tzStrings[0].standard?.abbr

            const min = tz.current.offset * 60

            let country = allTimezones[field.value.value]
            if (!country) {
                const [region, location] = field.value.value.split('/')
                country = location.replace('_', ' ')
            }
            const hr =
                `${(min / 60) ^ 0}:` +
                (min % 60 === 0 ? '00' : Math.abs(min % 60))
            const prefix = `(GMT${hr.includes('-') ? hr : `+${hr}`}) ${country}`
            field.value.display = `${prefix} ${
                abbr?.length < 5 ? `(${abbr})` : ''
            }`
            setTimezoneDisplay(field.value.display)
        }
    }, [isEditing, field, field.value])

    const onSelect = useCallback((option: ITimezoneOption) => {
        helpers.setValue({
            value: option.value,
            display: option.label,
        } as Option)
    }, [])

    return (
        <div className={classNames('flex flex-col', className)}>
            {label && (
                <label htmlFor={name} className="block text-[11px] mb-2">
                    {label}
                </label>
            )}
            <Wrapper>
                {!isEditing ? (
                    <p>{timezoneDisplay}</p>
                ) : (
                    <TimezoneSelect
                        onKeyDown={onKeyDown}
                        className="timeZonePicker !w-full bg-[#f6f7f9] !divide-[#10123A]/[3%] text-accent-2"
                        value={field.value ?? ''}
                        onChange={onSelect}
                        labelStyle="abbrev"
                    />
                )}
            </Wrapper>
            {meta.touched && meta.error && (
                <div className="mt-[10px]">
                    <FormValidationMessage message={meta.error[0]} />
                </div>
            )}
        </div>
    )
}
