import { HTMLMotionProps, motion } from 'framer-motion'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { classNames } from '../../util/misc'

interface Props extends HTMLMotionProps<'button'> {
    loading?: boolean
}

export const FiltersButton = forwardRef<any, Props>(
    ({ loading, children, className, onClick, ...props }, ref) => {
        const { t } = useTranslation()
        return (
            <motion.button
                whileHover={{ scale: 1.025 }}
                className={classNames(
                    'rounded-full',
                    'bg-accent active:bg-accent-2 disabled:bg-[#e7e7eb]',
                    'text-white text-[13px]',
                    'h-[50px]',
                    'px-4',
                    className,
                )}
                onClick={loading ? (e) => e.preventDefault() : onClick}
                {...props}
                ref={ref}
            >
                {loading ? <span>{t('messages.loading')}...</span> : children}
            </motion.button>
        )
    },
)
