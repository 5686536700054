import React, { useState, useEffect, useCallback } from 'react'
import { useStores } from '../../util/stores'
import { classNames, cancelSubscriptions } from '../../util/misc'
import { useTranslation } from 'react-i18next'
import { UploadLogoModal } from '../../pages/dashboard/modals'
import { AccountLevel } from '../../util/constants'
import { Subscription } from 'rxjs'

export const LogoDisplay: React.FC = () => {
    const { t } = useTranslation()
    const { user, company } = useStores()

    const [isUploadLogoModalOpen, setIsUploadLogoModalOpen] = useState(false)
    const [image, setImage] = useState('')
    const isAdmin = user.canAccess(AccountLevel.ADMIN)
    const LogoDisplayType = isAdmin ? 'button' : 'div'
    const subscriptions: Subscription[] = []

    const openUploadLogoModalOpen = useCallback(
        (open: boolean) => {
            user.trackEvent('button_clicked', {
                name: 'uploadLogo',
                type: 'company',
                action: open ? 'open' : 'close',
            }).subscribe()
            setIsUploadLogoModalOpen(open)
        },
        [isUploadLogoModalOpen],
    )

    useEffect(() => {
        if (company.activeCompanyId) {
            subscriptions.push(
                company.getActiveCompanyPicture().subscribe((image) => {
                    setImage(image ?? '')
                }),
            )
        }

        return () => {
            cancelSubscriptions(subscriptions)
        }
    }, [company.activeCompanyId])

    return (
        <>
            <UploadLogoModal
                isOpen={isUploadLogoModalOpen}
                setIsOpen={openUploadLogoModalOpen}
                uploadType="company"
                uploadId={company.activeCompanyId ?? ''}
                setPicture={company.setActiveCompanyPicture}
            />
            <LogoDisplayType
                className={classNames(
                    'my-2 mx-2 w-[80px] aspect-square flex items-center justify-center rounded-[4px] overflow-hidden',
                    company.activeCompany?.picture
                        ? 'bg-[#F1F8F9]'
                        : 'bg-[#F1F8F9]/30 border border-dotted border-[#3095AA]',
                )}
                onClick={
                    isAdmin
                        ? openUploadLogoModalOpen.bind(null, true)
                        : undefined
                }
            >
                {!company.activeCompany?.picture && isAdmin && (
                    <div className="flex flex-col">
                        <img
                            src="/img/icons/upload-logo.svg"
                            className="h-3"
                            alt="Upload logo"
                        />
                        <span className="sm:text-[13px] text-[#3095AA]">
                            {t('dashboard.logo.uploadLogo')}
                        </span>
                    </div>
                )}

                {company.activeCompany?.picture && (
                    <img src={image} className="w-full" />
                )}
            </LogoDisplayType>
        </>
    )
}
