import React from 'react'
import { Menu } from '@headlessui/react'
import { classNames } from '../../util/misc'

interface Props {
    list: any[]
    isOpen: boolean
    boxClassName?: string
}

export const MenuHoverList: React.FC<Props> = ({
    list,
    isOpen = false,
    boxClassName,
}) => {
    return (
        <>
            {isOpen && (
                <div className={classNames('fixed', boxClassName)}>
                    <div
                        className={classNames(
                            'absolute right-4 w-2 h-2 top-full translate-y-2 rotate-45 transform z-[1] bg-accent-2',
                        )}
                    />
                    <Menu>
                        <>
                            <Menu.Items
                                className={classNames(
                                    'top-full transform translate-y-3 z-[1] absolute right-0 w-[9rem] origin-top-right bg-[#080922] divide-y divide-[#fff]/10 rounded-md',
                                )}
                                static
                            >
                                {list.map((item, index) => {
                                    return (
                                        <Menu.Item key={index}>
                                            {({ active }) => (
                                                <li
                                                    onClick={() =>
                                                        item.onClick
                                                            ? item.onClick()
                                                            : null
                                                    }
                                                    className={classNames(
                                                        'flex items-center text-[13px] mx-4 py-2 text-white cursor-pointer',
                                                    )}
                                                >
                                                    <a
                                                        className={classNames(
                                                            'text-white text-[13px]',
                                                        )}
                                                        href={item.link}
                                                    >
                                                        {item.title}
                                                    </a>
                                                </li>
                                            )}
                                        </Menu.Item>
                                    )
                                })}
                            </Menu.Items>
                        </>
                    </Menu>
                </div>
            )}
        </>
    )
}
