import { InputHTMLAttributes, useEffect, useState } from 'react'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    onValueChange: (value: string) => void
    debounce?: number
}

export const TableFilterInput: React.FC<Props> = ({
    value: initialValue,
    onValueChange,
    debounce = 300,
    ...props
}) => {
    const [value, setValue] = useState(initialValue)

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    useEffect(() => {
        const timeout = setTimeout(() => {
            onValueChange(value as string)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <input
            {...props}
            multiple
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className="rounded-xl border-[1px] outline-none border-[#10123a33] mb-2 pl-2 w-[80%]"
        />
    )
}
