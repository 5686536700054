import {
    IsDivisibleBy,
    IsNotEmpty,
    IsOptional,
    IsPositive,
    MaxLength,
} from 'class-validator'
import { t } from 'i18next'
import { Validation } from '../../../util/constants'
import { Option } from '../../../util/misc'
import { BaseModel } from '../base.model'

export class EditCoacheeModel implements BaseModel {
    constructor(
        _id: string,
        country: Option | null,
        city: string,
        timeZone: Option | null,
        minsLimit: number | undefined,
        department: Option | null,
    ) {
        ;(this._id = _id),
            (this.country = country),
            (this.city = city),
            (this.timeZone = timeZone),
            (this.minsLimit = minsLimit),
            (this.department = department)
    }

    @IsNotEmpty({ message: t('validation.selectAnOption') as string })
    public _id: string = ''

    @IsNotEmpty({ message: t('validation.selectAnOption') as string })
    public timeZone: Option | null = null

    @IsNotEmpty({ message: t('validation.selectAnOption') as string })
    public country: Option | null = null

    @IsOptional()
    @MaxLength(Validation.SHORT_STRING_MAX_LENGTH, {
        message: t('validation.tooLong') as string,
    })
    public city: string = ''

    @IsOptional()
    public department: Option | null = null

    @IsOptional()
    @IsPositive()
    @IsDivisibleBy(25, {
        message: t('validation.mustBeDivisible') as string,
    })
    public minsLimit: number | undefined = undefined

    public getRequestBody() {
        return {
            _timezone: this.timeZone?.value,
            minsLimit: this.minsLimit,
            department: this.department?.value,
            user: {
                address: {
                    country: this.country?.value,
                    city: this.city ? this.city : undefined,
                },
            },
        }
    }
}
