import { Formik, FormikHelpers } from 'formik'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../../../components/buttons'
import { PhoneCodeInput } from '../../../../components/input/phonecode-input'
import { AddPhoneModel } from '../../../../models/request'
import { validateModel } from '../../../../util/validation'

interface Props {
    title: string
    onSubmit: (
        model: AddPhoneModel,
        helpers: FormikHelpers<AddPhoneModel>,
    ) => any
}

export const SetPhoneForm: React.FC<Props> = ({ title, onSubmit }) => {
    const { t } = useTranslation()
    return (
        <Formik
            validate={validateModel}
            initialValues={new AddPhoneModel()}
            onSubmit={onSubmit}
        >
            {({
                handleSubmit,
                isSubmitting,
                isValid,
                isValidating,
                handleChange,
                errors,
                touched,
                handleBlur,
                setFieldValue,
            }) => (
                <form onSubmit={handleSubmit} className="flex flex-col">
                    <header className="mb-10">
                        <span className="inline-flex text-[2rem]">{title}</span>
                        <span className="inline-flex text-xs">
                            {t('fields.phone.reason')}
                        </span>
                    </header>
                    <main className="space-y-3 mb-6">
                        <PhoneCodeInput
                            name="phone"
                            label={t('fields.phone.label') as string}
                            touched={touched}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            error={errors}
                            disabled={isSubmitting}
                            setFieldValue={setFieldValue}
                            placeholder={
                                t('fields.phone.placeholder') as string
                            }
                        />
                    </main>
                    <footer>
                        <PrimaryButton
                            trackingId="setPhoneNumber"
                            trackingAction="save"
                            className="w-full"
                            type="submit"
                            loading={isSubmitting}
                            disabled={!isValid || isValidating}
                        >
                            <span>{t('buttons.verifyPhoneNumber')}</span>
                        </PrimaryButton>
                    </footer>
                </form>
            )}
        </Formik>
    )
}
