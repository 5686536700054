import { runInAction } from 'mobx'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Subscription, tap } from 'rxjs'
import { PrimaryButton } from '../../../components/buttons'
import { ProfilePictureCircle } from '../../../components/misc'
import { SideModal } from '../../../components/modals'
import { Coachee, Static } from '../../../models/response'
import { getStaticsMap } from '../../../stores'
import { cancelSubscriptions, formatDate, formatTime } from '../../../util/misc'
import { useStores } from '../../../util/stores'
import { CoacheeEditableFields } from '../components/coachee-edit-fields'
import { ConfidentialInsights } from '../components/confidential-insights'
import { DeactivateCoacheeModal } from './deactivate-coachee.modal'
import { RequestEditFieldsCoacheeModal } from './request-edit-fields-coachee.modal'

interface EditCoacheeProps {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    coacheeFields: Coachee
}

export const EditCoacheeModal: React.FC<EditCoacheeProps> = observer(
    ({ isOpen, setIsOpen, coacheeFields }) => {
        const { t } = useTranslation()
        const { user, statics, company } = useStores()
        const [isDeactivateOpen, setIsDeactivateOpen] = useState(false)
        const [isRequestChangeOpen, setIsRequestChangeOpen] = useState(false)
        const [coacheePicture, setCoacheePicture] = useState('' as string)
        const subscriptions: Subscription[] = []

        const close = useCallback(() => {
            user.trackEvent('button_clicked', {
                name: 'editCoachee',
                action: 'close',
            }).subscribe()
            setIsOpen(false)
        }, [])

        const initials = useMemo(() => {
            if (coacheeFields.firstName && coacheeFields.lastName) {
                const firstInitial =
                    coacheeFields.firstName[0]?.toUpperCase?.() ?? ''
                const lastInitial =
                    coacheeFields.lastName[0]?.toUpperCase?.() ?? ''

                return firstInitial + lastInitial
            }

            return ''
        }, [coacheeFields])

        const getStaticsValue = useCallback(
            (statics: Static[], key: string) => {
                const data = getStaticsMap(statics)
                return data[key]
            },
            [coacheeFields],
        )

        useEffect(() => {
            if (coacheeFields._id) {
                subscriptions.push(
                    company
                        .getCoacheePicture(coacheeFields._id)
                        .pipe(
                            tap((image: any) => {
                                runInAction(() => {
                                    if (image) {
                                        setCoacheePicture(image)
                                    }
                                })
                            }),
                        )
                        .subscribe(),
                )
            }
            return () => {
                cancelSubscriptions(subscriptions)
            }
        }, [coacheeFields])

        const confirmDeactivateCoachee = useCallback(() => {
            setIsDeactivateOpen(true)
        }, [close])

        const requestChangeFieldsCoachee = useCallback(() => {
            setIsRequestChangeOpen(true)
        }, [close])

        return (
            <>
                <SideModal
                    isOpen={isOpen}
                    setIsOpen={close}
                    width="max-w-[35rem]"
                >
                    <header className="flex flex-col space-y-3 mb-6 mx-9 items-center">
                        <ProfilePictureCircle
                            initials={initials}
                            picture={coacheePicture}
                            wrapperClassNames="h-[70px] w-[70px]"
                        />

                        <span className="text-m font-semibold">
                            {coacheeFields.firstName} {coacheeFields.lastName}
                        </span>
                        <span className="text-sm">
                            {coacheeFields.position}
                        </span>
                        <PrimaryButton
                            trackingId="changeRequest"
                            trackingAction="requestChange"
                            className="h-[42px] w-[120px] min-w-[120px] mt-[20px] btn-alt"
                            onClick={requestChangeFieldsCoachee}
                        >
                            <span>{t('buttons.editUserDetails')}</span>
                        </PrimaryButton>
                    </header>
                    <main>
                        <div className="border-solid border-[1px] rounded-[10px] p-5 m-5 grid grid-cols-3 place-items-center">
                            <div className="grid place-items-center">
                                <img
                                    src="/img/icons/date-joined.svg"
                                    alt="Date user joind icon"
                                    className="w-5"
                                />
                                <span className="text-xs ">
                                    <Trans
                                        t={t}
                                        i18nKey="modals.myProfile.joinedContent"
                                        tOptions={{
                                            formattedDate:
                                                coacheeFields.createdAt
                                                    ? formatDate(
                                                          coacheeFields.createdAt,
                                                      )
                                                    : '',
                                        }}
                                    ></Trans>
                                </span>
                            </div>
                            <div className="grid place-items-center h-[1.55rem] max-w-[135px]">
                                <img
                                    src="/img/icons/envelope.svg"
                                    alt="Envelope icon"
                                    className="w-4"
                                />
                                <span className="text-xs text-center">
                                    <a
                                        className="font-normal text-xs"
                                        href={`mailto:${coacheeFields.email}`}
                                    >
                                        {coacheeFields.email}
                                    </a>
                                </span>
                            </div>
                            <div className="grid place-items-center">
                                <img
                                    src="/img/icons/clock.svg"
                                    alt="Envelope icon"
                                    className="w-4"
                                />

                                <span className="font-normal text-xs">
                                    <Trans
                                        t={t}
                                        i18nKey="modals.myProfile.localTime"
                                        tOptions={{
                                            formattedTime:
                                                coacheeFields.timeZone
                                                    ? formatTime(
                                                          coacheeFields.timeZone,
                                                      )
                                                    : 'n/a',
                                        }}
                                    ></Trans>
                                </span>
                            </div>
                        </div>
                        <div className="border-solid border-[1px] rounded-[10px] p-5 m-5 place-items-center">
                            <div className="grid border-solid border-b-[1px]">
                                <span className="text-sm text-gray-600">
                                    {t('modals.editCoachee.areasOfCoaching')}
                                </span>
                                <span className="text-m mb-4">
                                    {getStaticsValue(
                                        statics.competencies,
                                        coacheeFields.areaOfCoaching,
                                    )}
                                </span>
                            </div>
                            <div className="grid border-solid mt-4">
                                <span className="text-sm text-gray-600">
                                    {t('modals.editCoachee.seniority')}
                                </span>
                                <span className="text-m">
                                    {getStaticsValue(
                                        statics.positions,
                                        coacheeFields.seniorityLevel,
                                    )}
                                </span>
                            </div>
                        </div>
                        <ConfidentialInsights coachee={coacheeFields} />

                        <div className="border-solid border-[1px] rounded-[10px] p-5 m-5 place-items-center">
                            <CoacheeEditableFields coachee={coacheeFields} />
                        </div>
                        {coacheeFields.status === 'active' && (
                            <div className="p-5 flex flex-col place-items-center">
                                <PrimaryButton
                                    trackingId="deleteUser"
                                    trackingAction="deactivate"
                                    type="submit"
                                    className="btn-light-red h-[42px] w-[150px] min-w-[120px]"
                                    onClick={confirmDeactivateCoachee}
                                >
                                    <span>{t('buttons.deleteUser')}</span>
                                </PrimaryButton>
                            </div>
                        )}
                    </main>
                </SideModal>

                <DeactivateCoacheeModal
                    isOpen={isDeactivateOpen}
                    setIsOpen={setIsDeactivateOpen}
                    coacheeDetails={{
                        _id: coacheeFields._id,
                        fullName:
                            coacheeFields.firstName +
                            ' ' +
                            coacheeFields.lastName,
                    }}
                />

                <RequestEditFieldsCoacheeModal
                    isOpen={isRequestChangeOpen}
                    setIsOpen={setIsRequestChangeOpen}
                    coacheeDetails={coacheeFields}
                />
            </>
        )
    },
)
