import { ResourceLanguage } from 'i18next'
import { Translation } from '../translation'

export const en: Translation & ResourceLanguage = {
    pageTitles: {
        logIn: 'Log into your account',
        setPhoneNumber: 'Set phone number',
        magicLogin: 'Magic login',
        signUp: 'Sign up for your account',
        magicSignup: 'Magic sign up',
        twoFa: 'Two-factor authentication',
        dashboard: 'Dashboard',
        account: 'Account',
        faqs: 'Frequently asked questions',
        redirecting: 'Redirecting...',
        pageNotFound: 'Page not found',
        downloadPdf: 'Sama dashboard report',
    },
    titles: {
        logIn: 'Log in',
        dashboard: 'Dashboard',
        companyInvitesYou: '{{company}} invites you to access Sama Dashboard',
        setPhoneNumber: 'Set phone number',
        account: 'Account',
        yourProfile: 'View your profile',
        faqs: 'FAQs',
    },
    fields: {
        email: {
            label: 'Email address',
            placeholder: 'Email address',
        },
        firstName: {
            label: 'First name',
            placeholder: 'First name',
        },
        lastName: {
            label: 'Last name',
            placeholder: 'Last name',
        },
        code: {
            label: 'Verification code',
            placeholder: 'Verification code',
        },
        phone: {
            label: 'Phone number',
            placeholder: 'Phone number',
            reason: 'By entering your phone number here you are agreeing to receive SMS messages containting one time codes for the purpose of authentication.',
        },
        reason: {
            label: 'Please specify a reason',
            placeholder: 'Select reason',
        },
        reasonText: {
            label: 'Reason',
            placeholder: 'Please specify the reason here',
        },
        level: {
            label: 'Level',
            placeholder: 'Select level',
        },
        appLanguage: {
            label: 'Language',
            placeholder: 'Select language',
        },
        departmentsAccess: {
            label: 'Departments access',
            placeholder: 'Select department(s)',
        },
        country: {
            label: 'Country',
            placeholder: 'Select country',
        },
        city: {
            label: 'City',
            placeholder: 'City',
        },
        timeZone: {
            label: 'Time zone',
            placeholder: 'Select time zone',
        },
        editCiContent: {
            label: '',
            placeholder: 'Add confidential insight',
        },
        minsLimit: {
            label: 'Limit of hours',
            placeholder: 'Limit is currently set at the company level',
        },
        department: {
            label: 'Name',
            placeholder: 'Department name',
        },
    },
    buttons: {
        sendVerificationCode: 'Send verification email',
        verifyCode: 'Verify code',
        verifyPhoneNumber: 'Verify phone number',
        signIn: 'Sign in',
        signOut: 'Sign out',
        resend2fa: 'Resend code',
        manageAccess: 'Manage access',
        inviteUser: 'Invite user',
        inviteCoachee: 'Add user',
        update: 'Update',
        cancel: 'Cancel',
        remove: 'Remove',
        confirm: 'Confirm',
        filters: 'Filters',
        save: 'Save',
        edit: 'Edit',
        exportToPdf: 'Export to PDF',
        addUsers: 'Add user(s)',
        editUserDetails: 'Edit user details',
        deleteUser: 'Delete user',
        requestChange: 'Request change',
        upgradePlan: 'Upgrade plan',
        sendNudge: 'Send nudge',
        createDepartment: 'Create new department',
        updateDepartment: 'Edit department',
        saveConfidentialInfo: 'Save confidential information',
        getStarted: 'Get started',
        downloadCsv: 'Download CSV',
    },
    messages: {
        emailSent: `Email sent. Can't find it? Please check your spam folder.`,
        loggingYouIn: 'Logging you in',
        invalidToken: 'Token is invalid',
        pageNotFound: 'Page not found',
        loading: 'Loading',
        resend2fa: `Haven't received an SMS? Send again (in {{seconds}}s).`,
        accessRemoved: 'Access has been removed for account',
        accessUpdated: 'Access level has been updated for account',
        departmentAccessUpdated: 'Departments have been updated for account',
        userInviteSent: 'User invite sent',
        insufficientDataToShow: 'Less than 5 users in this category',
        updatedCoachee: 'Details have been updated',
        updatedCompanyInsight:
            ' Company confidential insight has been updated ',
        deletedCoachee: 'User has been removed',
        reportBeingGenerated: 'Report will be sent to your email',
        supportEmailSubject: 'Dashboard support',
        feedbackEmailSubject: 'Dashboard feedback',
        inviteReSent: 'Invite email sent',
        userConfidentialInfo: 'User information will be shared with coach.',
        companyConfidentialInfo:
            'Company information will be shared with coach.',
        emailSupport:
            'Thank you for your upgrade request. A Sama representative will be in touch shortly.',
        editRequestSent: 'The request has been sent to Sama for processing',
        createDepartmentSent: 'Department created',
        updateDepartmentSent: 'Department updated',
        insufficentData: 'Insufficient data in {{quarter}}',
        contentCopied: 'Copied to clipboard',
        account: {
            activeUsers: 'The number of active users in this department',
        },
        resetDates: 'Reset dates',
        languageChanged: 'Language has been updated',
    },
    sidebar: {
        dashboardAccess: 'Dashboard Access',
        contactSupport: 'Contact Support',
        sendFeedback: 'Send us feedback',
        you: 'You',
    },
    modals: {
        uploadLogo: {
            heading: 'Upload logo',
            subText: 'Image size should be 500x500px.',
            browse: 'browse',
            dragAndDrop: 'Drag and drop image, or <0>{{browse}}</0>',
            supportedFileTypes: 'Supports .jpg, .png or .svg',
        },
        manageAccess: {
            heading: 'Manage access',
            subText: 'View and manage the users who can access this dashboard.',
            name: 'Name',
            role: 'Role',
            createdAt: 'Joined {{formattedDate}}',
            inviteSent: 'Invite sent {{formattedDate}}',
            resendInvite: 'Resend invite',
        },
        addCoachee: {
            heading: 'Add user',
            subText:
                'Add new users to the Sama platform by providing their details below.',
            seatsLeft: 'seats left',
            noCoacheesRemaining:
                'You have no seats remaining. Please contact support@sama.io to add more.',
        },
        editCiContent: {
            heading: 'Edit confidential info for <0>{{name}}</0>',
            subText: 'This content will be shared with their coach',
        },
        editCoachee: {
            heading: 'Edit user',
            areasOfCoaching: 'Area of coaching',
            seniority: 'Seniority level',
            userConfidentialInfo: 'User confidential info',
            companyConfidentialInfo: 'Company confidential info',
            uploadFile: 'Upload confidential insights documents',
            dragAndDrop: 'Drag and drop documents, or <0>{{browse}}</0>',
            supportedFileTypes: 'Supports .docs and .pdf',
            coachingLanguages: 'Preferred coaching language',
            country: 'Country',
            location: 'City',
            timeZone: 'Time zone',
            delete: 'Delete',
            fileDeleteTitle: 'Are you sure?',
            fileDeleteSubtext:
                'This can not be undone once you press the confirm button',
            department: 'Department',
        },
        removeAccess: {
            heading: 'Remove access for <0>{{name}}</0>',
            subText: 'You are about to remove access for this account.',
        },
        upgradePlan: {
            heading: 'Upgrade your plan',
            subText:
                'We will send a notification to your Sama representative about your wish to upgrade your plan. They will get in touch with you.',
        },
        inviteUser: {
            heading: 'Invite user',
            subText:
                'Add a new user to the dashboard by providing their details below.',
        },
        roles: {
            heading: 'Roles',
            subText: 'View dashboard roles with their levels of access.',
            access: 'Access',
            departmentInfo: 'Department information',
            specificKeyDates: 'Specific key dates',
            dataTable: 'View user tables',
            dashboard: 'View dashboard charts',
            exportPdf: 'Export dashboard charts as PDF',
            addRemoveUsers: 'Add/remove Sama user(s)',
            inviteUsers: 'Invite dashboard user(s)',
            viewDashboardUsers: 'View dashboard user(s)',
        },
        deactivateCoachee: {
            heading: 'Delete user <0>{{name}}</0>',
            subText:
                'Any future session will be cancelled and the user will be logged out of the app. The user will be unable to login again',
        },
        changeLevel: {
            heading: 'Confirm change of level to {{level}}',
            subText: '',
        },
        myProfile: {
            joinedContent: 'Joined {{formattedDate}}',
            localTime: '{{formattedTime}} local time',
            dashboardLanguage: { title: 'Dashboard language' },
        },
        requestChangesCoachee: {
            heading: 'Request user details change',
            subText:
                'Changes made here need to be reviewed by your Sama representative contact.',
        },
        createDepartment: {
            heading: 'Create department',
        },
        updateDepartment: {
            heading: 'Edit department',
        },
    },
    validation: {
        valuesIdentical: 'Values have not been changed',
        selectAnOption: 'Please select an option',
        mustBeSet: 'Must be set',
        invalidFormat: 'Invalid format',
        invalidPhone: 'Format required: +447706123456',
        tooShort: 'Must be at least $constraint1 character(s) long',
        tooLong: 'Must be at most $constraint1 character(s) long',
        twoFaCode: {
            length: 'Must be $constraint1 digit(s) long',
            format: 'Must only contain numbers',
        },
        mustBeDivisible: 'Must be multiples of 25 mins',
    },
    levels: {
        viewer: 'Viewer',
        manager: 'Manager',
        admin: 'Admin',
        'super-admin': 'Super Admin',
        'sama-admin': 'SAMA Admin',
    },
    dashboard: {
        areaOfCoaching: {
            title: 'Area of coaching',
            subTitle: 'Real time data.',
            lifeBalance: 'Life Balance',
            engagement: 'Engagement',
            leadership: 'Leadership',
            performance: 'Performance',
            communication: 'Communication'
        },
        seniorityLevel: {
            title: 'Seniority level',
            subTitle: 'Real time data.',
            executive: 'Executive',
            admin: 'Admin',
            associates: 'Associates',
            senior: 'Senior',
            manager: 'Manager'
        },
        function: {
            title: 'Function',
            subTitle: 'Real time data.',
        },
        userStatus: {
            title: 'User status',
            subTitle: '',
        },
        gender: 'Gender',
        appDownloads: 'App downloads',
        licencesActive: 'Licences granted',
        coacheesConnectedToCoach: 'Users matched',
        completedFirstSession: 'Users completed first session',
        bookedSessions: 'Booked sessions',
        totalGoalsCreated: 'Total goals created',
        averageGoalsPerUser: 'Average goals per user',
        totalTasksCreated: 'Total tasks created',
        tasksCompleted: 'Tasks completed',
        sessionDuration: {
            title: 'Session duration',
            subTitle: 'Real time data.',
            minutes: '{{number}} minutes',
        },
        totalAudioOrVideoSessions: {
            title: 'Total audio or video sessions',
            subTitle: 'Real time data.',
            lateCancel: 'Late cancels',
            completed: 'Completed',
            user: 'User {{number}}',
        },
        monthlyBookedSessions: {
            title: 'Monthly booked sessions',
            description:
                'Include sessions booked but not conducted after current date.',
            count: 'Sessions',
        },
        monthlyActiveUsers: {
            title: 'Monthly Active Users (MAU)',
            description:
                'Monthly Active Users = Number of unique users who use the Sama platform over 30-days rolling',
        },
        employeeEngagementTracker: {
            title: 'Employee engagement tracker',
            description: 'Updated on a quarterly basis.',
            adaptability: 'Adaptability',
            alignment: 'Alignment',
            motivation: 'Motivation',
            resilience: 'Resilience',
            selfEfficacy: 'Self-efficacy',
        },
        attritionRisk: {
            title: 'Attrition risk',
            description: 'Percentage of users. Updated monthly.',
            legend: {
                maybe: 'Maybe',
                no: 'Not thinking of leaving',
                yes: 'Yes',
                unknown: 'Unknown',
            },
        },
        coachRating: {
            title: 'Coach rating',
            description: 'Updated monthly.',
            starSingular: '{{number}} star',
            starPlural: '{{number}} stars',
        },
        totalCoachingHours: {
            title: 'Total coaching hours',
            description: 'Includes duration for chat, audio and video.',
            hourSingular: '{{number}} hour',
            hourPlural: '{{number}} hours',
            totalAllocatedTimeUsed: 'Percentage used',
        },
        filters: {
            clear: 'Clear filters',
            filters: 'Filters',
            dateRange: 'Date range',
            department: 'Department',
        },
        logo: {
            uploadLogo: 'Upload logo',
        },
        coacheeActivity: {
            title: 'User activity',
            columns: {
                user: 'User',
                status: 'Status',
                areaOfCoaching: 'Area of coaching',
                nextSession: 'Next session',
                numberOfSessions: '# sessions',
                numberOfLateCancels: '# late cancels',
            },
            user: 'User {{number}}',
            status: {
                active: 'Active',
                anonymized: 'Anonymized',
                deactivated: 'Deactivated',
                disabled: 'Deactivated',
                locked: 'Locked',
                paused: 'Paused',
            },
        },
    },
    account: {
        menuTabs: {
            userAccess: 'User access',
            billing: 'Billing information',
            departments: 'Departments',
            companyDetails: 'Company details',
        },
        menuHeader: {
            currentPlan: 'Current plan',
            companyDetail: 'Details',
            currentPlanSubText:
                'Your current plan has {{seats}} available seats.',
        },
        companyDetail: {
            companyConfidentiaInfo: 'Company confidential info',
        },
        departments: {
            activeUsers: 'Active users:',
            createdAt: 'Created date:',
            emptyDepartments: {
                title: 'Create department',
                subtext:
                    'Create your first department by clicking the button below.',
            },
        },
        coacheeTable: {
            headers: {
                email: 'Email',
                firstName: 'First name',
                lastName: 'Last name',
                status: 'Status',
                loggedIn: 'Logged in',
                activatedDate: 'Licence granted',
                nextSessionDate: 'Next session date',
                country: 'Country',
                minsLimit: 'Maximum mins',
                minsRemaining: 'Mins remaining',
                department: 'Department',
                location: 'City',
                onboarded: 'Onboarded',
                numberOfSessions: '# Sessions done',
                numberOfLateCancels: '# Late cancels',
                lastestSessionDate: 'Latest session',
            },
            status: {
                active: 'Active',
                anonymized: 'Anonymized',
                disabled: 'Disabled',
            },
        },
        activeCompanySince: 'User since {{formattedDate}}',
    },
    extras: {
        other: 'Other',
        value: 'Value',
        'n/a': 'N/A',
    },
    tabelList: {
        globalSearchPlaceHolder: 'Search by name or email',
        columnSearchPlaceHolder: 'Search ',
        pagination: {
            show: 'Show',
        },
    },
    options: {
        selectAll: 'Select all',
    },
}
