import { motion } from 'framer-motion'
import React from 'react'
import { Link, useMatch } from 'react-router-dom'
import { classNames } from '../../../util/misc'

interface Props {
    to: string
    title: string
    iconPath?: string
    isExpanded: boolean
    isDepartmentTab?: boolean
}

export const SideBarLink: React.FC<Props> = ({
    to,
    title,
    iconPath,
    isExpanded,
    isDepartmentTab,
}) => {
    const active = useMatch(to)

    return (
        <Link
            to={to}
            className={classNames(
                'border-b-[1px] sm:border-none relative h-10 flex items-center px-4 text-accent-2 font-normal',
                active && 'text-accent',
            )}
        >
            {active && !isDepartmentTab && (
                <motion.div
                    className="rounded-tr-full rounded-br-full w-[6px] h-full bg-accent top-0 left-[-3px] absolute"
                    layoutId="sidebar-link"
                />
            )}
            {iconPath && (
                <div
                    className={classNames(
                        'p-2 rounded-md !w-8',
                        active && 'bg-lighter-teal',
                    )}
                >
                    <img
                        src={iconPath}
                        className={classNames('!w-4', active && 'text-accent')}
                        alt="Icon for link"
                    />
                </div>
            )}

            <div
                className={classNames(
                    'ml-2 text-[16px]',
                    !isExpanded && 'hidden',
                )}
            >
                {title}
            </div>
        </Link>
    )
}
