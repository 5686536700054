import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import {
    Route,
    Routes,
    unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom'
import { Route as RouteDefinition } from '../../pages/routes'
import { history } from '../../util/misc'
import { useStores } from '../../util/stores'
import { RouteWrapper } from './route-wrapper'

interface Props {
    routes: RouteDefinition[]
}

export const Router: React.FC<Props> = observer(({ routes }) => {
    const { auth, user, statics } = useStores()

    useEffect(() => {
        if (auth.fullyAuthenticated) {
            statics.loadStatics()
            const subscription = user.getUser().subscribe()

            return () => {
                subscription.unsubscribe()
            }
        }
    }, [auth.fullyAuthenticated])

    return auth.ready ? (
        // https://github.com/remix-run/react-router/issues/9422#issuecomment-1301954272
        // @ts-expect-error
        <HistoryRouter history={history}>
            <Routes>
                {routes.map(
                    ([paths, title, component, authRequirements], i) => {
                        return paths.map((path, j) => {
                            return (
                                <Route
                                    key={`${i}${j}`}
                                    path={path}
                                    element={
                                        <RouteWrapper
                                            title={title}
                                            component={component}
                                            authRequirements={authRequirements}
                                        />
                                    }
                                />
                            )
                        })
                    },
                )}
            </Routes>
        </HistoryRouter>
    ) : null
})
