import { Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../../../components/buttons'
import { TextInput } from '../../../../components/input'
import { OtpInput } from '../../../../components/input/otp-input'
import { TwoFaModel } from '../../../../models/request'
import { useStores } from '../../../../util/stores'
import { validateModel } from '../../../../util/validation'
import { Resend2fa } from '../../components'

interface Props {
    title: string
    onSubmit: (model: TwoFaModel, helpers: FormikHelpers<TwoFaModel>) => any
}

export const VerifyPhoneForm: React.FC<Props> = observer(
    ({ title, onSubmit }) => {
        const { t } = useTranslation()
        const { auth } = useStores()

        return (
            <Formik
                enableReinitialize
                validate={validateModel}
                initialValues={new TwoFaModel(auth.phone ?? undefined)}
                onSubmit={onSubmit}
            >
                {({
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    isValidating,
                    setFieldValue,
                }) => (
                    <form onSubmit={handleSubmit} className="flex flex-col">
                        <header className="mb-10">
                            <span className="inline-flex text-[2rem]">
                                {title}
                            </span>
                        </header>
                        <main className="space-y-3 mb-6">
                            {auth.phone && (
                                <TextInput
                                    name="phone"
                                    label={t('fields.phone.label') as string}
                                    placeholder={
                                        t('fields.phone.placeholder') as string
                                    }
                                    disabled
                                />
                            )}
                            <OtpInput
                                name="code"
                                label={t('fields.code.label') as string}
                                disabled={isSubmitting}
                                setCode={setFieldValue}
                            />
                            <Resend2fa />
                        </main>
                        <footer>
                            <PrimaryButton
                                trackingId="verify2FACode"
                                trackingAction="click"
                                className="w-full"
                                type="submit"
                                loading={isSubmitting}
                                disabled={!isValid || isValidating}
                            >
                                <span>{t('buttons.verifyCode')}</span>
                            </PrimaryButton>
                        </footer>
                    </form>
                )}
            </Formik>
        )
    },
)
