import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { en, fr } from './languages'

i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: process.env.NODE_ENV === 'development',
        fallbackLng: 'en',
        resources: {
            en: {
                translation: en,
            },
            fr: {
                translation: fr,
            },
        },
        interpolation: {
            escapeValue: false,
        },
    })

export default i18next
