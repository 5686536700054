import { Combobox } from '@headlessui/react'
import { useField } from 'formik'
import React, { useCallback, useState } from 'react'
import { classNames, Option } from '../../util/misc'
import { FormValidationMessage } from '../layout'

interface Props {
    name: string
    label?: string | React.ReactNode
    className?: string
    placeholder?: string
    options: Option[]
    disabled?: boolean
    editEnabled?: boolean
    onKeyPress?: any
}

const CheckElement: React.FC = () => {
    return (
        <img
            src="/img/icons/check-circle.svg"
            alt="Check in circle"
            className="w-3 ml-2"
        />
    )
}

export const AutoCompleteInput: React.FC<Props> = ({
    name,
    label,
    className,
    options,
    disabled,
    onKeyPress,
    editEnabled = true,
}) => {
    const [field, meta, helpers] = useField(name)

    const [query, setQuery] = useState('')
    const [isOpen, setIsOpen] = useState(false)

    const filteredOptions =
        query === ''
            ? options
            : options.filter((options) => {
                  return options.display
                      .toLowerCase()
                      .includes(query.toLowerCase())
              })

    const onSelect = useCallback(
        (value: string) => {
            const option = options
                .filter((options) => {
                    return options.display.toLowerCase() === value.toLowerCase()
                })
                .pop()
            helpers.setValue(option)
        },
        [options],
    )

    const close = useCallback(() => {
        setTimeout(() => {
            setIsOpen(false)
        }, 200)
    }, [])

    return (
        <div className={classNames('flex flex-col', className)}>
            {label && (
                <label htmlFor={name} className="block text-[11px] mb-2">
                    {label}
                </label>
            )}
            {!editEnabled ? (
                <p>{field.value.display}</p>
            ) : (
                <Combobox value={field.value?.display} onChange={onSelect}>
                    {({ open }) => (
                        <div>
                            <Combobox.Input
                                onKeyPress={onKeyPress}
                                autoComplete="off"
                                onFocus={() => setIsOpen(true)}
                                onBlur={close}
                                onChange={(event) =>
                                    setQuery(event.target.value)
                                }
                                className={classNames(
                                    'relative w-full bg-[#F6F7F9] h-11 inline-flex px-4 items-center justify-between rounded-[.25rem]',
                                    'active:focus:focus-within:ring-accent-2',
                                    open && 'ring-2 ring-accent-2',
                                    disabled && 'text-accent-2/40',
                                )}
                            />
                            {isOpen && (
                                <Combobox.Options
                                    static
                                    className={classNames(
                                        'top-full max-h-[300px] overflow-y-scroll transform translate-y-3 z-[1] right-0 w-[9rem] origin-top-right bg-[#080922] divide-y divide-[#fff]/10 rounded-md',
                                        '!w-full bg-[#f6f7f9] !divide-[#10123A]/[3%]',
                                    )}
                                >
                                    {filteredOptions.map((option) => (
                                        <Combobox.Option
                                            key={option.value}
                                            value={option.display}
                                        >
                                            {({ selected }) => (
                                                <div
                                                    className={classNames(
                                                        'flex items-center text-[13px] mx-4 py-2 text-white cursor-pointer',
                                                        'text-accent-2',
                                                        option.disabled &&
                                                            'opacity-60',
                                                    )}
                                                >
                                                    <span>
                                                        {option.display}
                                                    </span>
                                                    {selected && (
                                                        <CheckElement />
                                                    )}
                                                </div>
                                            )}
                                        </Combobox.Option>
                                    ))}
                                </Combobox.Options>
                            )}
                        </div>
                    )}
                </Combobox>
            )}
            {meta.touched && meta.error && (
                <div className="mt-[10px]">
                    <FormValidationMessage message={meta.error[0]} />
                </div>
            )}
        </div>
    )
}
