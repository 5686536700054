import React, { useRef, useState, useEffect } from 'react'

import { classNames } from '../../util/misc'

interface Props {
    initials: string
    picture?: string | null
    wrapperClassNames?: string
    onClick?: any
    wrapperRef?: any
}

export const ProfilePictureCircle: React.FC<Props> = ({
    initials,
    picture,
    wrapperClassNames,
    onClick,
    wrapperRef,
}) => {
    return (
        <div
            ref={wrapperRef}
            className={classNames(
                'bg-primary shrink-0 h-9 w-9 rounded-full flex',
                'justify-center items-center bg-cover bg-center',
                'bg-no-repeat select-none',
                wrapperClassNames,
            )}
            onClick={onClick}
            style={{
                backgroundImage: picture ? `url(${picture})` : undefined,
            }}
        >
            {!picture && <span className="text-white text-xs">{initials}</span>}
        </div>
    )
}
