import { AnimatePresence } from 'framer-motion'
import { observer } from 'mobx-react'
import React from 'react'
import { NotificationType } from '../../../util/constants'
import { useStores } from '../../../util/stores'
import { PopupNotification } from './popup-notification'

export const NotificationArea: React.FC = observer(() => {
    const { notifications } = useStores()

    return (
        <div className="absolute top-0 left-[47%] p-4 space-y-4 z-[60]">
            <AnimatePresence>
                {notifications.notifications.map(({ id, type, message }) => (
                    <PopupNotification key={id} type={type} message={message} />
                ))}
            </AnimatePresence>
        </div>
    )
})
