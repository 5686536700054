import { remove, uniqueId } from 'lodash'
import { action, makeAutoObservable } from 'mobx'
import { NotificationType } from '../util/constants'
import { Resettable } from '../util/misc'

export interface Notification {
    id: string
    type: NotificationType
    message: string
    timeout: NodeJS.Timeout
    remove: () => void
}

export class NotificationsStore implements Resettable {
    public notifications: Notification[] = []

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
    }

    @action
    public createNotification(
        type: NotificationType,
        message: string,
        durationInMilliseconds: number = 5000 /* 3 seconds */,
    ) {
        const id = uniqueId('task')
        const _remove = this.removeNotification.bind(this, id)
        const task: Notification = {
            id,
            type,
            message,
            timeout: setTimeout(_remove, durationInMilliseconds),
            remove: _remove,
        }

        this.notifications.push(task)
    }

    @action
    public removeNotification(id: string) {
        const [notification] = remove(this.notifications, { id })
        clearTimeout(notification.timeout)
    }

    @action
    public reset() {
        // Do nothing
    }
}
