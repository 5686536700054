import React, { RefObject, useCallback, useEffect, useRef } from 'react'
import { classNames } from '../../../util/misc'
import { DashboardPageHeader } from './dashboard-page-header'
import { SideBar } from './side-bar'

interface Props {
    title: string
    children: React.ReactNode
    backgroundColour?: string
    setIsHorizontalScrollVisible?: any
}

export const DashboardPageWrapper: React.FC<Props> = ({
    title,
    children,
    backgroundColour,
    setIsHorizontalScrollVisible,
}) => {
    const ref = useRef<HTMLDivElement>(null)

    const setHozScrollVisable = useCallback(() => {
        const el = ref.current
        if (el && setIsHorizontalScrollVisible) {
            setIsHorizontalScrollVisible(
                el.scrollWidth > el.getBoundingClientRect().width,
            )
        }
    }, [ref])

    useEffect(() => {
        setTimeout(() => {
            setHozScrollVisable()
        }, 600)

        setTimeout(() => {
            setHozScrollVisable()
        }, 1500)

        addEventListener('resize', () => {
            setHozScrollVisable()
        })
    }, [ref])

    return (
        <div className="h-screen w-screen flex">
            <SideBar />
            <div
                ref={ref}
                className={classNames(
                    backgroundColour ? backgroundColour : '',
                    'flex-1 flex flex-col overflow-y-auto',
                    'scrollbar-thin scrollbar-thumb-[#c4c4c4] scrollbar-thumb-rounded',
                )}
            >
                <DashboardPageHeader title={title} />
                <main className="flex-1">{children}</main>
            </div>
        </div>
    )
}
