import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Bar,
    BarChart,
    Brush,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import {
    ValueType,
    NameType,
    Formatter,
    // @ts-ignore
} from 'recharts/lib/component/DefaultTooltipContent'
import { RoundedBar } from '../../../components/charts'
import { formatMonthYear } from '../../../util/misc'

const MONTHS_TO_SHOW = 10

interface Data {
    month: string
    bookedSessionCount: number
}

interface Props {
    data: Data[]
}

const CustomizedXTick = (props: any) => {
    const { x, y, payload } = props

    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={0}
                fontSize={12}
                dy={5}
                textAnchor="end"
                fill="#10123A"
                transform="rotate(-35)"
            >
                {formatMonthYear(payload.value)}
            </text>
        </g>
    )
}

export const MonthlyBookedSessionsChart: React.FC<Props> = ({ data }) => {
    const { t } = useTranslation()

    const formatter: Formatter<NameType, NameType> = useMemo(
        () => (value: ValueType, name: NameType) =>
            [
                value as NameType,
                t(`dashboard.monthlyBookedSessions.count`) as NameType,
            ],
        [t],
    )

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                data={data}
                margin={{ left: -20, right: 40 }}
                barSize={16}
            >
                <CartesianGrid stroke="#F0F0F0" />
                <XAxis
                    type="category"
                    dataKey="month"
                    strokeWidth={1}
                    fontSize={12}
                    tickLine={false}
                    tick={CustomizedXTick}
                    axisLine={{ stroke: '#F0F0F0' }}
                    tickFormatter={formatMonthYear}
                    height={60}
                    interval={0}
                />
                <YAxis
                    type="number"
                    strokeWidth={1}
                    fontSize={12}
                    tickLine={false}
                    tick={{ fill: '#10123A' }}
                    axisLine={{ stroke: '#F0F0F0' }}
                    allowDecimals={false}
                />
                <Tooltip
                    wrapperStyle={{ outline: 'none' }}
                    cursor={{ fill: '#EDEDED' }}
                    formatter={formatter}
                    labelFormatter={formatMonthYear}
                    itemStyle={{
                        fontSize: '12px',
                        marginTop: -8,
                        color: 'white',
                    }}
                    labelStyle={{
                        color: 'white',
                        marginBottom: 8,
                    }}
                    contentStyle={{
                        background: '#080922',
                        padding: 8,
                        borderRadius: 6,
                        border: 'none',
                    }}
                />
                <Bar
                    dataKey="bookedSessionCount"
                    fill="#3095AA"
                    shape={<RoundedBar barSize={16} />}
                    minPointSize={1}
                />
            </BarChart>
        </ResponsiveContainer>
    )
}
