import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { getStaticsMap, getStaticsValue } from '../../../stores'
import { useStores } from '../../../util/stores'
import { Formik, FormikHelpers } from 'formik'
import { EditCoacheeModel } from '../../../models/request'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Subscription } from 'rxjs'
import { validateModel } from '../../../util/validation'
import { cancelSubscriptions, Option } from '../../../util/misc'
import {
    AutoCompleteInput,
    TextInput,
    TimezoneInput,
} from '../../../components/input'
import { Coachee } from '../../../models/response'
import { NumberInput } from '../../../components/input/number-input'
import { EditOption } from '../components/edit-option'
import { AccountLevel } from '../../../util/constants'

const EDITIABLE_FIELDS = {
    Country: 'country',
    City: 'city',
    TimeZone: 'timezone',
    MinsLimit: 'minsLimit',
    Department: 'department',
}

interface Props {
    coachee: Coachee
}
export const CoacheeEditableFields: React.FC<Props> = observer(
    ({ coachee }) => {
        const { t } = useTranslation()
        const { statics, company, user } = useStores()
        const countries = getStaticsMap(statics.countries)
        const subscriptions: Subscription[] = []
        const [editOption, setEditOption] = useState(null as string | null)
        const isAdmin = user.canAccess(AccountLevel.ADMIN)

        useEffect(() => {
            if (isAdmin && company.activeCompanyId) {
                const subscription = company.getDepartments().subscribe()
                subscriptions.push(subscription)
            }

            return () => {
                cancelSubscriptions(subscriptions)
            }
        }, [company.activeCompanyId, isAdmin])

        const updateCoachee = useCallback(
            (
                values: EditCoacheeModel,
                helpers: FormikHelpers<EditCoacheeModel>,
            ) => {
                helpers.setSubmitting(true)
                const subscription = company.updateCoachee(values).subscribe({
                    next(response) {
                        helpers.setSubmitting(false)

                        if (response.ok) {
                            close()
                        }
                    },
                })

                subscriptions.push(subscription)
            },
            [close],
        )

        const handleWithClickedOption = useCallback(
            (saveNow: boolean, fieldToEdit: string, handleSubmit: any) => {
                if (saveNow) {
                    setEditOption(null)
                    handleSubmit()
                } else {
                    setEditOption(fieldToEdit)
                }
            },
            [close],
        )

        const getEditCoacheeModel = useCallback(() => {
            const coacheeModel = new EditCoacheeModel(
                coachee._id,
                {
                    value: coachee.address?.country,
                    display: coachee.address?.country
                        ? countries[coachee.address.country] ?? null
                        : null,
                } as Option,
                coachee.address?.city ?? '',
                {
                    value: coachee.timeZone,
                    display: coachee.timeZone,
                } as Option,
                coachee.minsLimit,
                {
                    value: coachee.department?._id,
                    display: coachee.department?.name,
                } as Option,
            )
            return coacheeModel
        }, [close])

        const countryOptions = useMemo(() => {
            return statics.countries.map(
                (country): Option => ({
                    value: country.key,
                    display: country.name,
                }),
            )
        }, [statics.countries])

        const departmentOptions = useMemo(() => {
            return company.departments.map(
                (department): Option => ({
                    value: department._id,
                    display: department.name,
                }),
            )
        }, [company.departments, company.activeCompanyId])

        return (
            <Formik
                validate={validateModel}
                validateOnChange={true}
                initialValues={getEditCoacheeModel()}
                onSubmit={updateCoachee}
            >
                {({ handleSubmit, isSubmitting, isValid }) => (
                    <form>
                        {coachee.coachingLanguage && (
                            <div className="flex border-solid border-b-[1px] justify-between mb-4">
                                <div className="grid">
                                    <span className="text-sm text-gray-600">
                                        {t(
                                            'modals.editCoachee.coachingLanguages',
                                        )}
                                    </span>
                                    <span className="text-m mb-4">
                                        {getStaticsValue(
                                            statics.supportedLanguages,
                                            coachee.coachingLanguage,
                                        )}
                                    </span>
                                </div>
                            </div>
                        )}

                        {coachee.minsLimit && (
                            <div className="flex border-solid border-b-[1px] justify-between mt-4">
                                <div className="grid">
                                    <NumberInput
                                        min="0"
                                        step="25"
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter' && isValid) {
                                                handleWithClickedOption(
                                                    editOption ===
                                                        EDITIABLE_FIELDS.MinsLimit,
                                                    EDITIABLE_FIELDS.MinsLimit,
                                                    handleSubmit,
                                                )
                                            }
                                        }}
                                        editEnabled={
                                            editOption ===
                                            EDITIABLE_FIELDS.MinsLimit
                                        }
                                        name="minsLimit"
                                        label={
                                            t(
                                                'fields.minsLimit.label',
                                            ) as string
                                        }
                                        placeholder={
                                            t(
                                                'fields.minsLimit.placeholder',
                                            ) as string
                                        }
                                    />
                                </div>

                                <div className="flex place-items-center">
                                    <EditOption
                                        isEditing={
                                            editOption ===
                                            EDITIABLE_FIELDS.MinsLimit
                                        }
                                        onClick={() => {
                                            if (isValid) {
                                                handleWithClickedOption(
                                                    editOption ===
                                                        EDITIABLE_FIELDS.MinsLimit,
                                                    EDITIABLE_FIELDS.MinsLimit,
                                                    handleSubmit,
                                                )
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        {departmentOptions && departmentOptions.length > 0 && (
                            <div className="flex border-solid border-b-[1px] justify-between">
                                <div className="grid">
                                    <AutoCompleteInput
                                        onKeyPress={(e: any) => {
                                            if (e.key === 'Enter' && isValid) {
                                                handleWithClickedOption(
                                                    editOption ===
                                                        EDITIABLE_FIELDS.Department,
                                                    EDITIABLE_FIELDS.Department,
                                                    handleSubmit,
                                                )
                                            }
                                        }}
                                        editEnabled={
                                            editOption ===
                                            EDITIABLE_FIELDS.Department
                                        }
                                        name="department"
                                        options={departmentOptions}
                                        label={
                                            t(
                                                'modals.editCoachee.department',
                                            ) as string
                                        }
                                        placeholder={
                                            t(
                                                'fields.department.placeholder',
                                            ) as string
                                        }
                                    />
                                </div>
                                <div className="flex place-items-center">
                                    <EditOption
                                        isEditing={
                                            editOption ===
                                            EDITIABLE_FIELDS.Department
                                        }
                                        onClick={() =>
                                            isValid &&
                                            handleWithClickedOption(
                                                editOption ===
                                                    EDITIABLE_FIELDS.Department,
                                                EDITIABLE_FIELDS.Department,
                                                handleSubmit,
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        )}

                        <div className="flex border-solid border-b-[1px] justify-between">
                            <div className="grid">
                                <AutoCompleteInput
                                    onKeyPress={(e: any) => {
                                        if (e.key === 'Enter' && isValid) {
                                            handleWithClickedOption(
                                                editOption ===
                                                    EDITIABLE_FIELDS.Country,
                                                EDITIABLE_FIELDS.Country,
                                                handleSubmit,
                                            )
                                        }
                                    }}
                                    editEnabled={
                                        editOption === EDITIABLE_FIELDS.Country
                                    }
                                    name="country"
                                    options={countryOptions}
                                    label={
                                        t(
                                            'modals.editCoachee.country',
                                        ) as string
                                    }
                                    placeholder={
                                        t(
                                            'fields.country.placeholder',
                                        ) as string
                                    }
                                />
                            </div>
                            <div className="flex place-items-center">
                                <EditOption
                                    isEditing={
                                        editOption === EDITIABLE_FIELDS.Country
                                    }
                                    onClick={() =>
                                        isValid &&
                                        handleWithClickedOption(
                                            editOption ===
                                                EDITIABLE_FIELDS.Country,
                                            EDITIABLE_FIELDS.Country,
                                            handleSubmit,
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex border-solid border-b-[1px] justify-between mt-4">
                            <div className="grid">
                                <TextInput
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter' && isValid) {
                                            handleWithClickedOption(
                                                editOption ===
                                                    EDITIABLE_FIELDS.City,
                                                EDITIABLE_FIELDS.City,
                                                handleSubmit,
                                            )
                                        }
                                    }}
                                    editEnabled={
                                        editOption === EDITIABLE_FIELDS.City
                                    }
                                    name="city"
                                    label={
                                        t(
                                            'modals.editCoachee.location',
                                        ) as string
                                    }
                                    placeholder={
                                        t('fields.city.placeholder') as string
                                    }
                                />
                            </div>

                            <div className="flex place-items-center">
                                <EditOption
                                    isEditing={
                                        editOption === EDITIABLE_FIELDS.City
                                    }
                                    onClick={() =>
                                        isValid &&
                                        handleWithClickedOption(
                                            editOption ===
                                                EDITIABLE_FIELDS.City,
                                            EDITIABLE_FIELDS.City,
                                            handleSubmit,
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex justify-between mt-4">
                            <div className="grid">
                                <TimezoneInput
                                    onKeyDown={(e: any) => {
                                        if (e.key === 'Enter' && isValid) {
                                            handleWithClickedOption(
                                                editOption ===
                                                    EDITIABLE_FIELDS.TimeZone,
                                                EDITIABLE_FIELDS.TimeZone,
                                                handleSubmit,
                                            )
                                        }
                                    }}
                                    isEditing={
                                        editOption === EDITIABLE_FIELDS.TimeZone
                                    }
                                    name="timeZone"
                                    options={countryOptions}
                                    label={
                                        t(
                                            'modals.editCoachee.timeZone',
                                        ) as string
                                    }
                                    placeholder={
                                        t(
                                            'fields.timeZone.placeholder',
                                        ) as string
                                    }
                                />
                            </div>

                            <div className="flex place-items-center">
                                <EditOption
                                    isEditing={
                                        editOption === EDITIABLE_FIELDS.TimeZone
                                    }
                                    onClick={() =>
                                        isValid &&
                                        handleWithClickedOption(
                                            editOption ===
                                                EDITIABLE_FIELDS.TimeZone,
                                            EDITIABLE_FIELDS.TimeZone,
                                            handleSubmit,
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        )
    },
)
