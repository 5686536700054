import React from 'react'
import { observer } from 'mobx-react'
import { CenterModal } from '../../../components/modals'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../../components/buttons'
import { useStores } from '../../../util/stores'

interface Props {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    adminDetail: {
        firstName: string
        email: string
    }
}

export const UpgradePlanModal: React.FC<Props> = observer(
    ({ isOpen, setIsOpen, adminDetail }) => {
        const { t } = useTranslation()
        const { company } = useStores()

        const close = () => {
            setIsOpen(false)
        }

        const emailSupport = () => {
            const subject = `${company.activeCompany?.label} upgrade request`

            const content = `${adminDetail.firstName} has requested an upgrade via the company dashboard. There email address is: ${adminDetail.email}`

            company
                .sendEmailToSupport(content, subject, adminDetail.email)
                .subscribe({
                    next(response) {
                        if (response.ok) {
                            setIsOpen(false)
                        }
                    },
                })
        }

        return (
            <CenterModal
                isOpen={isOpen}
                setIsOpen={close}
                className="max-w-[32rem]"
            >
                <header className="flex flex-col space-y-3 mb-6 mx-9">
                    <span className="text-2xl">
                        {t('modals.upgradePlan.heading')}
                    </span>
                    <span className="text-sm md:text-lg text-[#10123a]">
                        {t('modals.upgradePlan.subText')}
                    </span>
                </header>
                <main>
                    <footer className="px-9 py-5 flex space-x-8">
                        <PrimaryButton
                            trackingId="upgradePlan"
                            trackingAction="cancel"
                            type="button"
                            className="flex-1 btn-open"
                            onClick={close}
                        >
                            <span>{t('buttons.cancel')}</span>
                        </PrimaryButton>
                        <PrimaryButton
                            trackingId="upgradePlan"
                            trackingAction="upgradePlan"
                            type="submit"
                            className="flex-1"
                            onClick={emailSupport}
                        >
                            <span>{t('buttons.sendNudge')}</span>
                        </PrimaryButton>
                    </footer>
                </main>
            </CenterModal>
        )
    },
)
