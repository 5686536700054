import { useField } from 'formik'
import React, { useCallback } from 'react'
import { classNames, Option } from '../../util/misc'
import { FormValidationMessage } from '../layout'
import { ListBox } from './list-box'

interface Props {
    name: string
    label?: string | React.ReactNode
    className?: string
    placeholder?: string
    options: Option[]
    disabled?: boolean
    onChange?: any
}

const CheckElement: React.FC = () => {
    return (
        <img
            src="/img/icons/check-circle.svg"
            alt="Check in circle"
            className="w-3 ml-2"
        />
    )
}

export const SelectInput: React.FC<Props> = ({
    name,
    label,
    className,
    placeholder,
    options,
    disabled,
    onChange,
}) => {
    const [field, meta, helpers] = useField(name)

    const onSelect = useCallback((option: Option) => {
        if (onChange) {
            onChange(option)
        }
        helpers.setValue(option)
    }, [])

    return (
        <div className={classNames('flex flex-col', className)}>
            {label && (
                <label htmlFor={name} className="block text-[11px] mb-2">
                    {label}
                </label>
            )}
            <ListBox
                onSelect={onSelect}
                value={field.value}
                options={options}
                placeholder={placeholder}
                buttonClassName="!h-12"
                optionsClassName="!w-full bg-[#f6f7f9] !divide-[#10123A]/[3%]"
                optionClassName="text-accent-2"
                optionClassNameActive="!text-accent"
                optionClassNameSelected="!text-accent flex-row-reverse justify-between"
                disabled={disabled}
                checkElement={<CheckElement />}
            />
            {meta.touched && meta.error && (
                <div className="mt-[10px]">
                    <FormValidationMessage message={meta.error[0]} />
                </div>
            )}
        </div>
    )
}
