import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Bar,
    BarChart,
    Brush,
    CartesianGrid,
    LabelList,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import {
    ValueType,
    NameType,
    Formatter,
    // @ts-ignore
} from 'recharts/lib/component/DefaultTooltipContent'
import { RoundedBar } from '../../../components/charts'
import { QuarterlyAggregate } from '../../../models/response'
import { formatQuarter } from '../../../util/misc'

const CustomLabel = (props: any) => {
    const { x, y, width, height, value } = props

    return (
        <g transform={`translate(${x + width / 2},${y + height + 10})`}>
            <text
                x={0}
                y={0}
                fontSize={12}
                dy={5}
                textAnchor="end"
                fill="#10123A"
                transform="rotate(-35)"
            >
                {value}
            </text>
        </g>
    )
}

interface Props {
    data: QuarterlyAggregate[]
}

export const EngagementTrackerChart: React.FC<Props> = ({
    data: quarterlyAggregates,
}) => {
    const { t } = useTranslation()

    const getLabelValue = useCallback(
        (name: string) => () =>
            t(`dashboard.employeeEngagementTracker.${name}`),
        [t],
    )

    const formatter: Formatter<NameType, NameType> = useMemo(
        () => (value: ValueType, name: NameType) =>
            [
                value as NameType,
                t(`dashboard.employeeEngagementTracker.${name}`) as NameType,
            ],
        [t],
    )

    const data = useMemo(() => {
        return quarterlyAggregates
            .map((aggregate) => ({
                // Choose day in the middle of the month to negate all timezone differences (5th)
                quarter: new Date(
                    aggregate.year,
                    3 * (aggregate.quarter - 1),
                    5,
                ),
                adaptability: aggregate.engagement.adaptability,
                alignment: aggregate.engagement.alignment,
                motivation: aggregate.engagement.motivation,
                resilience: aggregate.engagement.resilience,
                selfEfficacy: aggregate.engagement.selfEfficacy,
            }))
            .filter(
                (data) =>
                    data.adaptability !== 0 &&
                    data.alignment !== 0 &&
                    data.motivation !== 0 &&
                    data.resilience !== 0 &&
                    data.selfEfficacy !== 0,
            )
    }, [quarterlyAggregates])

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                data={data}
                margin={{ left: -20, right: 40, bottom: 40 }}
                barGap={48}
                maxBarSize={26}
            >
                <CartesianGrid stroke="#F0F0F0" />
                <XAxis
                    type="category"
                    dataKey="quarter"
                    fontSize={12}
                    tickLine={false}
                    tickFormatter={formatQuarter}
                    textAnchor="left"
                    tick={{ fill: '#10123A', x: -50 }}
                    interval={0}
                    orientation="top"
                    strokeWidth={0}
                    xAxisId={1}
                />
                <XAxis
                    type="category"
                    dataKey="quarter"
                    strokeWidth={1}
                    tick={{ fill: 'transparent' }}
                    axisLine={{ stroke: '#F0F0F0' }}
                    orientation="bottom"
                    tickFormatter={formatQuarter}
                    height={60}
                />
                <YAxis
                    type="number"
                    strokeWidth={1}
                    fontSize={12}
                    tickLine={false}
                    tick={{ fill: '#10123A' }}
                    axisLine={{ stroke: '#F0F0F0' }}
                    ticks={[0, 1, 2, 3, 4, 5]}
                    domain={[0, 5]}
                    interval={0}
                />
                <Tooltip
                    wrapperStyle={{ outline: 'none' }}
                    cursor={{ fill: '#EDEDED' }}
                    labelFormatter={formatQuarter}
                    formatter={formatter}
                    itemStyle={{
                        fontSize: '12px',
                        marginTop: -8,
                        color: 'white',
                    }}
                    labelStyle={{
                        color: 'white',
                        marginBottom: 8,
                    }}
                    contentStyle={{
                        background: '#080922',
                        padding: 8,
                        borderRadius: 6,
                        border: 'none',
                    }}
                />
                <Bar
                    dataKey="adaptability"
                    shape={<RoundedBar />}
                    fill="#3095AA"
                    isAnimationActive={false}
                >
                    <LabelList
                        content={CustomLabel}
                        valueAccessor={getLabelValue('adaptability')}
                    />
                </Bar>
                <Bar
                    dataKey="alignment"
                    shape={<RoundedBar />}
                    fill="#6EB5C3"
                    isAnimationActive={false}
                >
                    <LabelList
                        content={CustomLabel}
                        valueAccessor={getLabelValue('alignment')}
                    />
                </Bar>
                <Bar
                    dataKey="motivation"
                    shape={<RoundedBar />}
                    fill="#4FA5B7"
                    isAnimationActive={false}
                >
                    <LabelList
                        content={CustomLabel}
                        valueAccessor={getLabelValue('motivation')}
                    />
                </Bar>
                <Bar
                    dataKey="resilience"
                    shape={<RoundedBar />}
                    fill="#8DC5D0"
                    isAnimationActive={false}
                >
                    <LabelList
                        content={CustomLabel}
                        valueAccessor={getLabelValue('resilience')}
                    />
                </Bar>
                <Bar
                    dataKey="selfEfficacy"
                    shape={<RoundedBar />}
                    fill="#ACD5DD"
                    isAnimationActive={false}
                >
                    <LabelList
                        content={CustomLabel}
                        valueAccessor={getLabelValue('selfEfficacy')}
                    />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    )
}
