import { Formik, FormikHelpers } from 'formik'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import { CenterModal } from '../../../components/modals'
import { Trans, useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../../components/buttons'
import { validateModel } from '../../../util/validation'
import { Subscription } from 'rxjs'
import { useStores } from '../../../util/stores'
import { cancelSubscriptions, Option } from '../../../util/misc'
import { DeactivateCoacheeModel } from '../../../models/request'

import { SelectInput, TextareaInput } from '../../../components/input'

interface Props {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    coacheeDetails: {
        _id: string
        fullName: string
    }
}

export const DeactivateCoacheeModal: React.FC<Props> = observer(
    ({ isOpen, setIsOpen, coacheeDetails }) => {
        const { t } = useTranslation()
        const { company, statics, user } = useStores()
        const subscriptions: Subscription[] = []
        const [coacheeDeactivateDetails, setCoacheeDeactivateDetails] =
            useState(coacheeDetails)

        const close = useCallback(() => {
            user.trackEvent('button_clicked', {
                name: 'deactivateCoachee',
                action: 'close',
            }).subscribe()
            setIsOpen(false)
        }, [])

        const deactivateCoachee = useCallback(
            (
                values: DeactivateCoacheeModel,
                helpers: FormikHelpers<DeactivateCoacheeModel>,
            ) => {
                helpers.setSubmitting(true)
                const subscription = company
                    .deactivateCoachee(values)
                    .subscribe({
                        next(response) {
                            if (response.ok) {
                                close()
                            }
                        },
                    })

                subscriptions.push(subscription)
            },
            [close],
        )

        const reasonOptions = useMemo(() => {
            return statics.disableReasons.map(
                (reason): Option => ({
                    value: reason.key,
                    display: reason.name,
                }),
            )
        }, [statics.disableReasons])

        useEffect(() => {
            return () => {
                cancelSubscriptions(subscriptions)
            }
        }, [])

        return (
            <CenterModal
                isOpen={isOpen}
                setIsOpen={close}
                className="max-w-[32rem]"
            >
                <header className="flex flex-col space-y-3 mb-6 mx-9">
                    <span className="text-2xl">
                        <Trans
                            t={t}
                            i18nKey="modals.deactivateCoachee.heading"
                            tOptions={{ name: coacheeDetails.fullName }}
                        >
                            <b />
                        </Trans>
                    </span>
                    <span className="text-xs text-[#56576A]">
                        {t('modals.deactivateCoachee.subText')}
                    </span>
                </header>
                <main>
                    <Formik
                        validate={validateModel}
                        initialValues={
                            new DeactivateCoacheeModel(coacheeDetails._id)
                        }
                        onSubmit={deactivateCoachee}
                    >
                        {({ handleSubmit, values, isSubmitting, isValid }) => (
                            <form
                                onSubmit={handleSubmit}
                                className="divide-y divide-[black]/10"
                            >
                                <main className="space-y-4 mx-9 mb-6">
                                    <SelectInput
                                        name="reason"
                                        options={reasonOptions}
                                        label={
                                            t('fields.reason.label') as string
                                        }
                                        placeholder={
                                            t(
                                                'fields.reason.placeholder',
                                            ) as string
                                        }
                                    />
                                    {values.reason?.value === 'other' && (
                                        <TextareaInput
                                            name="reasonText"
                                            placeholder={
                                                t(
                                                    'fields.reasonText.placeholder',
                                                ) as string
                                            }
                                        />
                                    )}
                                </main>
                                <footer className="px-9 py-5 flex space-x-8">
                                    <PrimaryButton
                                        trackingId="deactivateCoachee"
                                        trackingAction="cancel"
                                        type="button"
                                        className="flex-1 btn-open"
                                        onClick={close}
                                        disabled={isSubmitting}
                                    >
                                        <span>{t('buttons.cancel')}</span>
                                    </PrimaryButton>
                                    <PrimaryButton
                                        trackingId="deactivateCoachee"
                                        trackingAction="deactivate"
                                        type="submit"
                                        className="flex-1 btn-red"
                                        disabled={isSubmitting || !isValid}
                                    >
                                        <span>{t('buttons.remove')}</span>
                                    </PrimaryButton>
                                </footer>
                            </form>
                        )}
                    </Formik>
                </main>
            </CenterModal>
        )
    },
)
