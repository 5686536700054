import { HTMLMotionProps, motion } from 'framer-motion'
import { forwardRef, MouseEventHandler, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { classNames } from '../../util/misc'
import { useStores } from '../../util/stores'

interface Props extends HTMLMotionProps<'button'> {
    trackingId: string
    trackingAction: string
    loading?: boolean
    onClick?: MouseEventHandler<HTMLButtonElement>
}

export const PrimaryButton = forwardRef<any, Props>(
    (
        {
            trackingId,
            trackingAction,
            loading,
            children,
            className,
            onClick,
            ...props
        },
        ref,
    ) => {
        const { t } = useTranslation()
        const { user } = useStores()

        const trackClick = useCallback((e: any) => {
            user.trackEvent('button_clicked', {
                name: trackingId,
                action: trackingAction,
            }).subscribe()
        }, [])

        const internalOnClick = useCallback((e: any) => {
            e.currentTarget.blur()
            trackClick(e)
            if (onClick) {
                onClick(e)
            }
        }, [])

        return (
            <motion.button
                whileHover={{ scale: 1.025 }}
                whileTap={{ scale: 0.95 }}
                className={classNames(
                    'rounded-full',
                    'bg-accent active:bg-accent-2 disabled:bg-[#e7e7eb]',
                    'text-white text-[13px]',
                    'h-[50px]',
                    'px-4',
                    className,
                )}
                onClick={
                    loading
                        ? (e) => e.preventDefault()
                        : (e) => internalOnClick(e)
                }
                {...props}
                ref={ref}
            >
                {loading ? <span>{t('messages.loading')}...</span> : children}
            </motion.button>
        )
    },
)
