import { entries, omit } from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Bar,
    BarChart,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import { RoundedBar } from '../../../components/charts'
import { CumulativeAggregate } from '../../../models/response'

interface Props {
    data: CumulativeAggregate['coachRating']
}

export const CoachRatingChart: React.FC<Props> = ({ data: coachRating }) => {
    const { t } = useTranslation()

    const tickFormatter = useMemo(
        () => (value: number) =>
            t(
                +value === 1
                    ? `dashboard.coachRating.starSingular`
                    : 'dashboard.coachRating.starPlural',
                { number: value },
            ),
        [],
    )

    const data = useMemo(() => {
        return entries(omit(coachRating, 'total', 'other'))
            .map(([class_, value]) => ({
                rating: class_,
                value,
            }))
            .sort((a, b) => +b.rating - +a.rating)
    }, [coachRating])

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart data={data} margin={{ left: -20, right: 40 }}>
                <CartesianGrid stroke="#F0F0F0" />
                <XAxis
                    type="category"
                    dataKey="rating"
                    strokeWidth={1}
                    fontSize={12}
                    tickLine={false}
                    tick={{ fill: '#10123A' }}
                    tickFormatter={tickFormatter}
                    interval={0}
                    axisLine={{ stroke: '#F0F0F0' }}
                />
                <YAxis
                    type="number"
                    strokeWidth={1}
                    fontSize={12}
                    tickLine={false}
                    tick={{ fill: '#10123A' }}
                    axisLine={{ stroke: '#F0F0F0' }}
                    allowDecimals={false}
                />
                <Tooltip
                    wrapperStyle={{ outline: 'none' }}
                    labelFormatter={tickFormatter}
                    itemStyle={{
                        fontSize: '12px',
                        marginTop: -8,
                        color: 'white',
                    }}
                    labelStyle={{
                        color: 'white',
                        marginBottom: 8,
                    }}
                    contentStyle={{
                        background: '#080922',
                        padding: 8,
                        borderRadius: 6,
                        border: 'none',
                    }}
                />
                <Bar
                    dataKey="value"
                    stackId="a"
                    shape={<RoundedBar />}
                    fill="#3095AA"
                    barSize={22}
                    minPointSize={0}
                />
            </BarChart>
        </ResponsiveContainer>
    )
}
