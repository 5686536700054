import {
    IsEmail,
    IsNotEmpty,
    IsOptional,
    MaxLength,
    MinLength,
} from 'class-validator'
import { t } from 'i18next'
import { Validation } from '../../../util/constants'
import { Option } from '../../../util/misc'
import { stores } from '../../../util/stores'
import { BaseModel } from '../base.model'

export class InviteUserModel implements BaseModel {
    @MinLength(Validation.SHORT_STRING_MIN_LENGTH, {
        message: t('validation.tooShort') as string,
    })
    @MaxLength(Validation.SHORT_STRING_MAX_LENGTH, {
        message: t('validation.tooLong') as string,
    })
    public firstName: string = ''

    @MinLength(Validation.SHORT_STRING_MIN_LENGTH, {
        message: t('validation.tooShort') as string,
    })
    @MaxLength(Validation.SHORT_STRING_MAX_LENGTH, {
        message: t('validation.tooLong') as string,
    })
    public lastName: string = ''

    @IsEmail(undefined, { message: t('validation.invalidFormat') as string })
    public email: string = ''

    @IsOptional()
    public appLanguage: Option | null = null

    @IsOptional()
    public departments: Option[] = []

    @IsNotEmpty({ message: t('validation.selectAnOption') as string })
    public level: Option | null = null

    public getRequestBody() {
        return {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            departments: this.departments.map((department) => department.value),
            appLanguage: this.appLanguage?.value,
            level: this.level?.value,
            company: stores.company.activeCompanyId,
        }
    }
}
