import { observer } from 'mobx-react'
import React, { useCallback, useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../../components/buttons'
import { ModalProps, SideModal } from '../../../components/modals'
import { CompanyUser } from '../../../models/response'
import { AccountLevel } from '../../../util/constants'
import { useStores } from '../../../util/stores'
import { ManageAccessUser } from '../components'
import { InviteUserModal } from './invite-user.modal'
import { RemoveAccessModal } from './remove-access.modal'
import { ChangeLevelsModal } from './change-levels.model'
import { Option } from '../../../util/misc'
import { Subscription } from 'rxjs'
import { cancelSubscriptions } from '../../../util/misc'

export const ManageAccessModal: React.FC<ModalProps> = observer(
    ({ isOpen, setIsOpen }) => {
        const { t } = useTranslation()
        const { company, user } = useStores()
        const [isRemoveAccessModalOpen, setIsRemoveAccessModalOpen] =
            useState(false)
        const [isChangeLevelsModalOpen, setIsChangeLevelsModalOpen] =
            useState(false)
        const [changeLevelsData, setChangeLevelsData] = useState({} as Option)

        const [isInviteUserModalOpen, setIsInviteUserModalOpen] =
            useState(false)

        const activeCompanyUser = useRef<CompanyUser | null>(null)

        const internalSetIsOpen = useCallback((e: any) => {
            user.trackEvent('button_clicked', {
                name: 'manageAccess',
                action: 'close',
            }).subscribe()
            return setIsOpen(false)
        }, [])

        const removeCompanyUser = useCallback(
            (companyUser: CompanyUser) => () => {
                user.trackEvent('remove_company_user', {
                    loggedInAdmin: companyUser._id,
                    actingOn: user.companyUserId,
                }).subscribe()
                activeCompanyUser.current = companyUser
                setIsRemoveAccessModalOpen(true)
            },
            [],
        )
        const subscriptions: Subscription[] = []

        const resendInvite = useCallback(
            (companyUser: CompanyUser) => () => {
                user.trackEvent('resend_admin_welcome_email', {
                    loggedInAdmin: companyUser._id,
                    actingOn: user.companyUserId,
                }).subscribe()
                activeCompanyUser.current = companyUser
                const subscription = company
                    .resendCompanyAdminInvite(companyUser._id)
                    .subscribe()
                subscriptions.push(subscription)
            },
            [],
        )

        useEffect(() => {
            return () => {
                cancelSubscriptions(subscriptions)
            }
        }, [company.companyAssociations])

        const changeLevelCompanyUser = (
            companyUser: CompanyUser,
            data: Option,
        ) => {
            activeCompanyUser.current = companyUser
            setChangeLevelsData(data)
            setIsChangeLevelsModalOpen(true)
        }

        const inviteUser = useCallback(() => {
            setIsInviteUserModalOpen(true)
        }, [])

        return (
            <>
                {activeCompanyUser.current && (
                    <>
                        <RemoveAccessModal
                            isOpen={isRemoveAccessModalOpen}
                            setIsOpen={setIsRemoveAccessModalOpen}
                            companyUser={activeCompanyUser.current}
                        />
                        <ChangeLevelsModal
                            isOpen={isChangeLevelsModalOpen}
                            setIsOpen={setIsChangeLevelsModalOpen}
                            companyUser={activeCompanyUser.current}
                            option={changeLevelsData}
                        />
                    </>
                )}
                <SideModal
                    isOpen={isOpen}
                    setIsOpen={internalSetIsOpen}
                    width={
                        company.anyUserIsViewer
                            ? 'max-w-[40rem]'
                            : 'max-w-[30rem]'
                    }
                >
                    <header className="flex flex-col space-y-3 mb-6">
                        <span className="text-2xl">
                            {t('modals.manageAccess.heading')}
                        </span>
                        <span className="text-xs">
                            {t('modals.manageAccess.subText')}
                        </span>
                        {user.canAccess(AccountLevel.ADMIN) && (
                            <div>
                                <PrimaryButton
                                    trackingId="inviteUser"
                                    trackingAction="open"
                                    className="h-8"
                                    onClick={inviteUser}
                                >
                                    <span>{t('buttons.inviteUser')}</span>
                                </PrimaryButton>
                            </div>
                        )}
                    </header>
                    <main>
                        <table className="w-full">
                            <thead className="text-left text-xs">
                                <tr>
                                    <th className="border-b border-[#DDDEE34d] pb-2">
                                        <span className="font-normal">
                                            {t('modals.manageAccess.name')}
                                        </span>
                                    </th>
                                    <th className="border-b border-[#DDDEE34d] pb-2 pl-4">
                                        <span className="font-normal">
                                            {t('modals.manageAccess.role')}
                                        </span>
                                    </th>
                                    {user.canAccess(AccountLevel.ADMIN) && (
                                        <th className="border-b border-[#DDDEE34d] pb-2 !w-7" />
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {company.companyAssociations.length > 0 &&
                                    company.companyAssociations.map(
                                        (
                                            { companyUser, level, departments },
                                            i,
                                        ) => {
                                            return (
                                                <ManageAccessUser
                                                    key={i}
                                                    companyUser={companyUser}
                                                    level={level}
                                                    departments={departments}
                                                    removeCompanyUser={
                                                        removeCompanyUser
                                                    }
                                                    changeLevelCompanyUser={
                                                        changeLevelCompanyUser
                                                    }
                                                    resendInvite={resendInvite}
                                                />
                                            )
                                        },
                                    )}
                            </tbody>
                        </table>
                    </main>
                </SideModal>
                <InviteUserModal
                    isOpen={isInviteUserModalOpen}
                    setIsOpen={setIsInviteUserModalOpen}
                />
            </>
        )
    },
)
