import { entries, get, keys, setWith, uniq } from 'lodash'
import { observer } from 'mobx-react'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
    CartesianGrid,
    LabelList,
    Line,
    LineChart as RechartsLineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import {
    ValueType,
    NameType,
    Formatter,
    // @ts-ignore
} from 'recharts/lib/component/DefaultTooltipContent'
import { Static } from '../../models/response'
import { getStaticsMap } from '../../stores'
import {
    formatMonthDayYear,
    formatMonthYear,
    formatShortMonthYear,
} from '../../util/misc'
import { useStores } from '../../util/stores'
import { getCurrentBreakpoint } from '../../util/break-point'

interface Data {
    name: string
    data: {
        month: string
        value: number
        name: string
    }[]
}

interface Props {
    dataKey: string
    staticsKey: string
    color: string
}

const CustomizedXTick = (props: any) => {
    const { x, y, payload } = props

    const isSmallBreakPoint =
        getCurrentBreakpoint() === 'lg' ||
        getCurrentBreakpoint() === 'md' ||
        getCurrentBreakpoint() === 'sm'

    const transformValue = isSmallBreakPoint ? -35 : 0
    const xValue = isSmallBreakPoint ? 0 : 30

    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={xValue}
                y={0}
                fontSize={12}
                dy={5}
                textAnchor="end"
                fill="#10123A"
                transform={`rotate(${transformValue})`}
            >
                {formatMonthYear(payload.value)}
            </text>
        </g>
    )
}

export const MonthlyLineChart: React.FC<Props> = observer(
    ({ dataKey: key, staticsKey, color }) => {
        const { company, statics } = useStores()
        const { t } = useTranslation()
        const yAxisLabelPositions: Record<number, string> = {}

        const tooltipFormatter: Formatter<ValueType, NameType> = useCallback(
            (value: ValueType, name: NameType) => {
                return [
                    ((+value).toFixed(0) + '%') as NameType,
                    name as NameType,
                ]
            },
            [],
        )

        const getLabelTitle = useCallback((date: Date) => {
            if (new Date(date).getMonth() === new Date().getMonth()) {
                return formatMonthDayYear(new Date())
            } else {
                return formatShortMonthYear(date)
            }
        }, [])

        const yTickFormatter = useCallback((value: string) => {
            return value + '%'
        }, [])

        const findYValue = useCallback(
            (y: number, key: string, name: string) => {
                const upperNumber = parseFloat(key) + 10
                const lowerNumber = parseFloat(key) - 10

                if (
                    yAxisLabelPositions[key as any] !== name &&
                    y >= lowerNumber &&
                    y <= upperNumber
                ) {
                    y = y + 8
                    y = findYValue(y, key, name)
                }
                return y
            },
            [],
        )

        const getLabelYPosition = useCallback((y: number, name: string) => {
            const length = Object.keys(yAxisLabelPositions).length
            y = +y! + 5

            for (let i = 0; i < length; i++) {
                if (yAxisLabelPositions[y] && yAxisLabelPositions[y] !== name) {
                    y = y + 10
                }
            }
            for (const key in yAxisLabelPositions) {
                y = findYValue(y, key, name)
            }

            yAxisLabelPositions[y] = name
            return y
        }, [])

        const data: Data[] = useMemo(() => {
            if (!company.monthlyAggregates.length) {
                return []
            }

            const values: Record<string, Record<string, number>> = {}
            company.monthlyAggregates.forEach((aggregate) => {
                const data_: Record<string, number> = get(aggregate, key)
                entries(data_).forEach(([key_, value]) => {
                    setWith(values, [key_, aggregate.month], value, Object)
                })
            })

            return uniq(keys(values)).map((key_) => {
                const value = values[key_]
                const map = getStaticsMap(
                    (statics as any)[staticsKey] as Static[],
                )

                const name = map[key_] ?? t('extras.other')

                return {
                    name,
                    data: entries(value).map(([month, value_]) => ({
                        month,
                        value: value_,
                        name,
                    })),
                }
            })
        }, [t, company.monthlyAggregates])

        return (
            <ResponsiveContainer width="100%" height="100%">
                <RechartsLineChart margin={{ right: 150 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        tick={CustomizedXTick}
                        height={80}
                        type="category"
                        dataKey="month"
                        strokeWidth={1}
                        fontSize={12}
                        tickLine={false}
                        tickFormatter={formatMonthYear}
                        interval={0}
                        axisLine={{ stroke: '#F0F0F0' }}
                        allowDuplicatedCategory={false}
                    />
                    <YAxis
                        type="number"
                        dataKey="value"
                        strokeWidth={1}
                        fontSize={12}
                        tickLine={false}
                        tickFormatter={yTickFormatter}
                        domain={[0, 'dataMax + 5']}
                        scale="linear"
                        tick={{ fill: '#10123A' }}
                        axisLine={{ stroke: '#F0F0F0' }}
                    />
                    <Tooltip
                        wrapperStyle={{ outline: 'none' }}
                        cursor={{ fill: '#EDEDED' }}
                        labelFormatter={getLabelTitle}
                        formatter={tooltipFormatter}
                        position={{ y: 0 }}
                        itemStyle={{
                            fontSize: '10px',
                            marginTop: -8,
                            color: 'white',
                        }}
                        labelStyle={{
                            color: 'white',
                            marginBottom: 8,
                        }}
                        contentStyle={{
                            background: '#080922',
                            padding: 8,
                            borderRadius: 6,
                            border: 'none',
                        }}
                    />
                    {data.map((s) => (
                        <Line
                            dataKey="value"
                            data={s.data}
                            name={s.name}
                            key={s.name}
                            type="monotone"
                            isAnimationActive={false}
                            connectNulls
                            stroke={color}
                            activeDot={{ r: 4 }}
                        >
                            <LabelList
                                dataKey="name"
                                position="top"
                                // tslint:disable-next-line: jsx-no-lambda
                                content={(props) => {
                                    const index: number = (props as any).index
                                    const isLastElement =
                                        index === s.data.length - 1

                                    if (!isLastElement) return null

                                    const name: string = props.value as string
                                    const x = +props.x! + 20
                                    const y = getLabelYPosition(+props.y!, name)

                                    return isLastElement ? (
                                        <g>
                                            <text
                                                x={x}
                                                y={y}
                                                fontSize={12}
                                                fill="#10123A"
                                                transform={`rotate(-20 ${x} ${y})`}
                                            >
                                                {props.value}
                                            </text>
                                        </g>
                                    ) : null
                                }}
                            />
                        </Line>
                    ))}
                </RechartsLineChart>
            </ResponsiveContainer>
        )
    },
)
