import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Bar,
    BarChart,
    Brush,
    CartesianGrid,
    Cell,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import {
    ValueType,
    NameType,
    Formatter,
    // @ts-ignore
} from 'recharts/lib/component/DefaultTooltipContent'
import { RoundedBar } from '../../../components/charts'
import { formatMonthYearByUTC } from '../../../util/misc'

interface Data {
    month: string
    maybe: number
    no: number
    yes: number
    unknown: number
}

interface Props {
    data: Data[]
}

export const AttritionRiskChart: React.FC<Props> = ({ data }) => {
    const { t } = useTranslation()

    const formatter: Formatter<NameType, NameType> = useMemo(
        () => (value: ValueType, name: NameType) =>
            [
                (value + '%') as NameType,
                t(`dashboard.attritionRisk.legend.${name}`) as NameType,
            ],
        [t],
    )

    const yTickFormatter = useCallback((value: string) => {
        return value + '%'
    }, [])

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart data={data} margin={{ left: -20, right: 20, top: 20 }}>
                <CartesianGrid stroke="#F0F0F0" />
                <YAxis
                    type="number"
                    tickCount={10}
                    strokeWidth={1}
                    fontSize={12}
                    tickLine={false}
                    tick={{ fill: '#10123A' }}
                    ticks={[0, 20, 40, 60, 80, 100]}
                    tickFormatter={yTickFormatter}
                    axisLine={{ stroke: '#F0F0F0' }}
                    interval={0}
                    domain={[0, 100]}
                />
                <XAxis
                    type="category"
                    dataKey="month"
                    strokeWidth={1}
                    fontSize={12}
                    tickLine={false}
                    tickFormatter={formatMonthYearByUTC}
                    tick={{ fill: '#10123A' }}
                    axisLine={{ stroke: '#F0F0F0' }}
                />
                <Tooltip
                    wrapperStyle={{ outline: 'none' }}
                    cursor={{ fill: '#EDEDED' }}
                    formatter={formatter}
                    labelFormatter={formatMonthYearByUTC}
                    itemStyle={{
                        fontSize: '10px',
                        marginTop: -8,
                    }}
                    labelStyle={{
                        color: 'white',
                        marginBottom: 8,
                    }}
                    contentStyle={{
                        background: '#080922',
                        padding: 8,
                        borderRadius: 6,
                        border: 'none',
                    }}
                />
                <Bar
                    dataKey="yes"
                    fill="#FF7558"
                    shape={<RoundedBar />}
                    barSize={46}
                    minPointSize={1}
                />
                <Bar
                    dataKey="maybe"
                    fill="#FFAC9B"
                    shape={<RoundedBar />}
                    barSize={46}
                    minPointSize={1}
                />
                <Bar
                    dataKey="no"
                    fill="#3095AA"
                    shape={<RoundedBar />}
                    barSize={46}
                    minPointSize={1}
                />
                <Bar
                    dataKey="unknown"
                    fill="#83BFCC"
                    shape={<RoundedBar />}
                    barSize={46}
                    minPointSize={1}
                />
            </BarChart>
        </ResponsiveContainer>
    )
}
