import {
    Allow,
    ValidateIf,
    IsNotEmpty,
    MaxLength,
    MinLength,
} from 'class-validator'
import { t } from 'i18next'
import { Validation } from '../../../util/constants'
import { Option } from '../../../util/misc'
import { stores } from '../../../util/stores'
import { BaseModel } from '../base.model'

export class DeactivateCoacheeModel implements BaseModel {
    constructor(coacheeId: string) {
        this.coacheeId = coacheeId
    }

    @IsNotEmpty({ message: t('validation.selectAnOption') as string })
    public coacheeId: string = ''

    @ValidateIf((o: DeactivateCoacheeModel) => o.reason?.value === 'other')
    @MinLength(Validation.SHORT_STRING_MIN_LENGTH, {
        message: t('validation.tooShort') as string,
    })
    @MaxLength(Validation.SHORT_STRING_MAX_LENGTH, {
        message: t('validation.tooLong') as string,
    })
    public reasonText: string = ''

    @IsNotEmpty({ message: t('validation.selectAnOption') as string })
    public reason: Option | null = null

    public getRequestBody() {
        return {
            coacheeId: this.coacheeId,
            reason: this.reason?.value,
            otherReason: this.reasonText,
        }
    }
}
