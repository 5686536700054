import { HTMLMotionProps, motion } from 'framer-motion'
import React from 'react'
import { classNames } from '../../util/misc'

interface Props extends HTMLMotionProps<'button'> {}

export const ModalCloseButton: React.FC<Props> = ({
    children,
    className,
    onClick,
    ...props
}) => {
    return (
        <motion.button
            className={classNames(
                'rounded-full h-5 w-5 mr-[10px] bg-contain',
                className,
            )}
            style={{ backgroundImage: 'url(/img/icons/x-circle.svg)' }}
            onClick={onClick}
            {...props}
        />
    )
}
