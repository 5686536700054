import { find } from 'lodash'
import { observer } from 'mobx-react'
import React, { useMemo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ListBox, SELECT_ALL_VALUE } from '../../../components/input'
import { MultiSelectInput } from '../../../components/input/multi-select-input'
import { ProfilePictureCircle } from '../../../components/misc'
import { CompanyUser, Department } from '../../../models/response'
import {
    AccountLevel,
    accountLevelAccess,
    accountLevelOptions,
} from '../../../util/constants'
import { classNames, formatDate, Option } from '../../../util/misc'
import { useStores } from '../../../util/stores'

interface Props {
    companyUser: CompanyUser
    level: AccountLevel
    departments?: Department[]
    removeCompanyUser: (companyUser: CompanyUser) => any
    changeLevelCompanyUser: (companyUser: CompanyUser, level: Option) => any
    resendInvite: (companyUser: CompanyUser) => any
}

export const ManageAccessUser: React.FC<Props> = observer(
    ({
        companyUser,
        level,
        departments,
        removeCompanyUser,
        changeLevelCompanyUser,
        resendInvite,
    }) => {
        const { user, company } = useStores()
        const { t } = useTranslation()
        const firstName = companyUser.user.firstName.trim()
        const lastName = companyUser.user.lastName.trim()
        const initials = firstName[0].toUpperCase() + lastName[0].toUpperCase()
        const createdAt = companyUser.firstConnectedAt
        const inviteSentAt = companyUser._createdAt
        const isCurrentUser =
            user.activeAssociation?.companyUser._id === companyUser._id

        const onSelectLevel = useCallback((option: Option) => {
            if (level !== option.value) {
                changeLevelCompanyUser(companyUser, option)
            }
        }, [])

        const [selectedDepartments, setSelectedDepartments] =
            useState(departments)

        const onSelectDepartment = useCallback((options: Option[]) => {
            const departmentList = options
                .map((option) => option.value as string)
                .filter((option) => option !== SELECT_ALL_VALUE)

            company
                .updateCompanyUserDepartments(departmentList, companyUser._id)
                .subscribe({
                    next() {
                        setSelectedDepartments(
                            options.map((option) => {
                                return {
                                    _id: option.value,
                                    name: option.display,
                                } as Department
                            }),
                        )
                    },
                })
        }, [])

        const allLevelOptions = useMemo(
            () => accountLevelOptions(user.accountLevel),
            [user.accountLevel],
        )

        // Only show the SAMA admin option to SAMA admins
        const listBoxOptions = useMemo(
            () =>
                allLevelOptions.filter((option) =>
                    user.accountLevel === AccountLevel.SAMA_ADMIN
                        ? true
                        : user.accountLevel
                        ? accountLevelAccess[user.accountLevel].includes(
                              option.value as AccountLevel,
                          )
                        : false,
                ),
            [allLevelOptions, level, user.accountLevel],
        )

        const activeOption = useMemo(
            () =>
                find(allLevelOptions, {
                    value: level,
                }),
            [allLevelOptions, level],
        )

        return (
            <tr className="border-b border-[#dddee34d]">
                <td className="py-4">
                    <div className="flex items-center space-x-3">
                        <ProfilePictureCircle
                            initials={initials}
                            picture={companyUser.profilePicture}
                        />
                        <div
                            className={
                                'flex flex-col space-y-[-.25rem] break-word'
                            }
                        >
                            <span className="text-[16px] space-x-2">
                                <span>
                                    {firstName} {lastName}
                                </span>
                            </span>
                            <span
                                className={classNames(
                                    'text-[14px] text-[#56576A]',
                                    !createdAt && '!text-primary',
                                )}
                            >
                                {createdAt &&
                                    t('modals.manageAccess.createdAt', {
                                        formattedDate: formatDate(createdAt),
                                    })}
                                {!createdAt &&
                                    t('modals.manageAccess.inviteSent', {
                                        formattedDate: formatDate(inviteSentAt),
                                    })}
                            </span>
                            {user.canAccess(AccountLevel.ADMIN) &&
                                !createdAt && (
                                    <span
                                        onClick={resendInvite(companyUser)}
                                        className={classNames(
                                            'cursor-pointer text-[14px] text-[#2E7B8B]',
                                        )}
                                    >
                                        {t('modals.manageAccess.resendInvite')}
                                    </span>
                                )}
                        </div>
                    </div>
                </td>
                <td className="py-4 pl-4 inline-flex">
                    <ListBox
                        options={listBoxOptions}
                        buttonClassName="min-w-[120px]"
                        optionsClassName="min-w-[110px] !w-full"
                        value={activeOption}
                        onSelect={onSelectLevel}
                        disabled={
                            // You have to at least be an admin but
                            // also be able to access that specific
                            // level AND the user cannot be you
                            !user.canAccess(AccountLevel.ADMIN, level) ||
                            isCurrentUser
                        }
                    />
                    {level === AccountLevel.VIEWER &&
                        company.departments &&
                        company.departments.length > 0 && (
                            <MultiSelectInput
                                name="departments"
                                width="w-[160px]"
                                onChange={onSelectDepartment}
                                selected={selectedDepartments?.map(
                                    (department): Option => ({
                                        value: department._id,
                                        display: department.name,
                                    }),
                                )}
                                options={company.getCompanyDepartments()}
                                className="pl-5"
                                placeholder={
                                    t(
                                        'fields.departmentsAccess.placeholder',
                                    ) as string
                                }
                            />
                        )}
                </td>
                {user.canAccess(AccountLevel.ADMIN) && (
                    <td className="py-4 pl-4 !w-6">
                        {!isCurrentUser && user.canAccess(level) && (
                            <button
                                type="button"
                                onClick={removeCompanyUser(companyUser)}
                            >
                                <img
                                    src="/img/icons/trash.svg"
                                    alt="Trash"
                                    className="w-3"
                                />
                            </button>
                        )}
                    </td>
                )}
            </tr>
        )
    },
)
