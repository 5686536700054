import { Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../../components/buttons'
import { TextInput } from '../../../components/input'
import { FormNotification } from '../../../components/layout'
import { RequestMagicLinkModel } from '../../../models/request'
import { NotificationType } from '../../../util/constants'
import { useStores } from '../../../util/stores'
import { validateModel } from '../../../util/validation'
import { AuthPageWrapper } from '../components'

export const LogInPage: React.FC = observer(() => {
    const { t } = useTranslation()
    const { auth } = useStores()
    const [success, setSuccess] = useState(false)

    const onSubmit = useCallback(
        (
            values: RequestMagicLinkModel,
            helpers: FormikHelpers<RequestMagicLinkModel>,
        ) => {
            helpers.setSubmitting(true)
            auth.requestMagicLink(values).subscribe({
                next(response) {
                    if (response.ok) {
                        setSuccess(true)
                    }
                },
                complete() {
                    helpers.setSubmitting(false)
                },
            })
        },
        [],
    )

    return (
        <AuthPageWrapper>
            <Formik
                validate={validateModel}
                initialValues={new RequestMagicLinkModel()}
                onSubmit={onSubmit}
            >
                {({ handleSubmit, isSubmitting, isValid, isValidating }) => (
                    <form onSubmit={handleSubmit} className="flex flex-col">
                        <header className="mb-10">
                            <span className="inline-flex text-[2rem]">
                                {t('titles.logIn')}
                            </span>
                        </header>
                        <main className="space-y-3 mb-6">
                            <TextInput
                                name="email"
                                label={t('fields.email.label') as string}
                                placeholder={
                                    t('fields.email.placeholder') as string
                                }
                                disabled={isSubmitting || success}
                            />
                            {success && (
                                <FormNotification
                                    type={NotificationType.INFO}
                                    message={t('messages.emailSent')}
                                />
                            )}
                        </main>
                        <footer>
                            {!success && (
                                <PrimaryButton
                                    trackingId="sendVerificationCode"
                                    trackingAction="click"
                                    className="w-full"
                                    type="submit"
                                    loading={isSubmitting}
                                    disabled={!isValid || isValidating}
                                >
                                    <span>
                                        {t('buttons.sendVerificationCode')}
                                    </span>
                                </PrimaryButton>
                            )}
                        </footer>
                    </form>
                )}
            </Formik>
        </AuthPageWrapper>
    )
})

export default LogInPage
