import { Formik, FormikHelpers } from 'formik'
import React, { useCallback, useMemo, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../../components/buttons'
import { ModalProps, SideModal } from '../../../components/modals'
import { AccountLevel } from '../../../util/constants'
import { useStores } from '../../../util/stores'
import { InviteCoacheeModel } from '../../../models/request'
import { Subscription } from 'rxjs'
import {
    SelectInput,
    TextInput,
    TimezoneInput,
    AutoCompleteInput,
} from '../../../components/input'
import { validateModel } from '../../../util/validation'
import { cancelSubscriptions, Option } from '../../../util/misc'

export const AddCoacheeModel: React.FC<ModalProps> = observer(
    ({ isOpen, setIsOpen }) => {
        const { t } = useTranslation()
        const { company, user, statics } = useStores()
        const subscriptions: Subscription[] = []
        const isAdmin = user.canAccess(AccountLevel.ADMIN)

        const countryOptions = useMemo(() => {
            return statics.countries.map(
                (country): Option => ({
                    value: country.key,
                    display: country.name,
                }),
            )
        }, [statics.countries])

        const close = useCallback(() => {
            user.trackEvent('button_clicked', {
                name: 'addCoachee',
                action: 'close',
            }).subscribe()
            setIsOpen(false)
        }, [])

        useEffect(() => {
            if (isAdmin && company.activeCompanyId) {
                const subscription = company.getDepartments().subscribe()
                subscriptions.push(subscription)
            }
            return () => {
                cancelSubscriptions(subscriptions)
            }
        }, [company.activeCompanyId, isAdmin])

        const departmentOptions = useMemo(() => {
            return company.departments.map(
                (department): Option => ({
                    value: department._id,
                    display: department.name,
                }),
            )
        }, [company.departments, company.activeCompanyId])

        const inviteCoachee = useCallback(
            (
                values: InviteCoacheeModel,
                helpers: FormikHelpers<InviteCoacheeModel>,
            ) => {
                helpers.setSubmitting(true)

                const subscription = company.addCoachee(values).subscribe({
                    next(response) {
                        helpers.setSubmitting(false)

                        if (response.ok) {
                            close()
                        }
                    },
                })

                subscriptions.push(subscription)
            },
            [close],
        )

        return (
            <>
                <SideModal isOpen={isOpen} setIsOpen={close}>
                    <header className="flex flex-col space-y-3 mb-6">
                        <span className="text-2xl">
                            {t('modals.addCoachee.heading')}
                            <span className="text-sm">
                                {' '}
                                - {company.numOfActiveLicences.toString()}{' '}
                                {t('modals.addCoachee.seatsLeft')}
                            </span>
                        </span>
                        {company.numOfActiveLicences > 0 && (
                            <span className="text-xs">
                                {t('modals.addCoachee.subText')}
                            </span>
                        )}
                    </header>
                    <main>
                        {company.numOfActiveLicences === 0 && (
                            <p className="text-red text-center w-[64%] m-auto">
                                {t('modals.addCoachee.noCoacheesRemaining')}
                            </p>
                        )}
                        {company.numOfActiveLicences > 0 && (
                            <Formik
                                validate={validateModel}
                                initialValues={new InviteCoacheeModel()}
                                onSubmit={inviteCoachee}
                            >
                                {({ handleSubmit, isSubmitting, isValid }) => (
                                    <form onSubmit={handleSubmit}>
                                        <main className="space-y-4 mb-6">
                                            <TextInput
                                                name="firstName"
                                                label={
                                                    t(
                                                        'fields.firstName.label',
                                                    ) as string
                                                }
                                                placeholder={
                                                    t(
                                                        'fields.firstName.placeholder',
                                                    ) as string
                                                }
                                            />
                                            <TextInput
                                                name="lastName"
                                                label={
                                                    t(
                                                        'fields.lastName.label',
                                                    ) as string
                                                }
                                                placeholder={
                                                    t(
                                                        'fields.lastName.placeholder',
                                                    ) as string
                                                }
                                            />
                                            <TextInput
                                                name="email"
                                                label={
                                                    t(
                                                        'fields.email.label',
                                                    ) as string
                                                }
                                                placeholder={
                                                    t(
                                                        'fields.email.placeholder',
                                                    ) as string
                                                }
                                            />
                                            {company.getActiveLanguages && (
                                                <SelectInput
                                                    name="appLanguage"
                                                    options={
                                                        company.getActiveLanguages
                                                    }
                                                    label={
                                                        t(
                                                            'fields.appLanguage.label',
                                                        ) as string
                                                    }
                                                    placeholder={
                                                        t(
                                                            'fields.appLanguage.placeholder',
                                                        ) as string
                                                    }
                                                />
                                            )}
                                            <AutoCompleteInput
                                                name="country"
                                                options={countryOptions}
                                                label={
                                                    t(
                                                        'fields.country.label',
                                                    ) as string
                                                }
                                                placeholder={
                                                    t(
                                                        'fields.country.placeholder',
                                                    ) as string
                                                }
                                            />
                                            <TextInput
                                                name="city"
                                                label={
                                                    t(
                                                        'fields.city.label',
                                                    ) as string
                                                }
                                                placeholder={
                                                    t(
                                                        'fields.city.placeholder',
                                                    ) as string
                                                }
                                            />

                                            {departmentOptions &&
                                                departmentOptions.length >
                                                    0 && (
                                                    <AutoCompleteInput
                                                        editEnabled={true}
                                                        name="department"
                                                        options={
                                                            departmentOptions
                                                        }
                                                        label={
                                                            t(
                                                                'modals.editCoachee.department',
                                                            ) as string
                                                        }
                                                        placeholder={
                                                            t(
                                                                'fields.department.placeholder',
                                                            ) as string
                                                        }
                                                    />
                                                )}

                                            <TimezoneInput
                                                name="timeZone"
                                                options={countryOptions}
                                                label={
                                                    t(
                                                        'fields.timeZone.label',
                                                    ) as string
                                                }
                                                placeholder={
                                                    t(
                                                        'fields.timeZone.placeholder',
                                                    ) as string
                                                }
                                            />
                                        </main>
                                        <footer className="px-9 pb-9">
                                            <PrimaryButton
                                                trackingId="inviteCoachee"
                                                trackingAction="save"
                                                type="submit"
                                                className="w-full"
                                                disabled={
                                                    isSubmitting || !isValid
                                                }
                                            >
                                                <span>
                                                    {t('buttons.inviteCoachee')}
                                                </span>
                                            </PrimaryButton>
                                        </footer>
                                    </form>
                                )}
                            </Formik>
                        )}
                    </main>
                </SideModal>
            </>
        )
    },
)
