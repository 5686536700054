import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Bar,
    BarChart,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import {
    ValueType,
    NameType,
    Formatter,
    // @ts-ignore
} from 'recharts/lib/component/DefaultTooltipContent'
import { RoundedBar } from '../../../components/charts'
import { CumulativeAggregate } from '../../../models/response'

const NAME_DELIMITER = '$^]!' // Uncommon character combination

export const CustomizedXTick = (props: any) => {
    const { x, y, payload } = props
    let nameArray: string[] = []
    if (payload.value.length > 1) {
        nameArray = (payload.value as string).split(NAME_DELIMITER)
    }

    const firstName = nameArray[0] ?? 'User ' + payload.value
    const lastName = nameArray[1] ?? ''
    return (
        <g transform={`translate(${x - 8},${y})`}>
            <text
                x={0}
                y={0}
                fontSize={12}
                dy={5}
                textAnchor="end"
                fill="#10123A"
                transform="rotate(-35)"
            >
                {firstName}
            </text>
            <text
                x={16}
                y={10}
                fontSize={12}
                dy={5}
                textAnchor="end"
                fill="#10123A"
                transform="rotate(-35)"
            >
                {lastName}
            </text>
        </g>
    )
}

interface Props {
    data: CumulativeAggregate['sessionsByCoachee']
}

export const TotalSessionsChart: React.FC<Props> = ({ data }) => {
    const { t } = useTranslation()
    const showNames = useMemo(() => !!data[0].firstName, [data])

    const dataWithNames = useMemo(() => {
        if (!showNames) return data

        return data.map((datum) => ({
            ...datum,
            name: datum.firstName + NAME_DELIMITER + datum.lastName,
        }))
    }, [data])

    const formatter: Formatter<NameType, NameType> = useMemo(
        () => (value: ValueType, name: NameType) =>
            [
                value as NameType,
                t(`dashboard.totalAudioOrVideoSessions.${name}`) as NameType,
            ],
        [t],
    )

    const tickFormatter = useCallback(
        (value: any) => {
            return showNames
                ? value ?? ''
                : t(`dashboard.totalAudioOrVideoSessions.user`, {
                      number: value,
                  })
        },
        [t],
    )

    const nameFormatter = useCallback((name: string) => {
        if (name.length > 1) {
            const nameArray = (name as string).split(NAME_DELIMITER)
            return nameArray.join(' ')
        } else {
            return name
        }
    }, [])

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                data={dataWithNames}
                margin={{ left: -20, right: 40, bottom: 20 }}
            >
                <CartesianGrid stroke="#F0F0F0" />
                <XAxis
                    type="category"
                    height={39}
                    dataKey={showNames ? 'name' : 'user'}
                    strokeWidth={1}
                    fontSize={12}
                    tickLine={false}
                    tick={CustomizedXTick}
                    tickFormatter={tickFormatter}
                    angle={-45}
                    interval={0}
                    axisLine={{ stroke: '#F0F0F0' }}
                />
                <YAxis
                    type="number"
                    strokeWidth={1}
                    fontSize={12}
                    tickLine={false}
                    tick={{ fill: '#10123A' }}
                    axisLine={{ stroke: '#F0F0F0' }}
                    allowDecimals={false}
                />
                <Tooltip
                    wrapperStyle={{ outline: 'none' }}
                    cursor={{ fill: '#EDEDED' }}
                    formatter={formatter}
                    labelFormatter={nameFormatter}
                    position={{ y: 0 }}
                    itemStyle={{
                        fontSize: '12px',
                        marginTop: -8,
                    }}
                    labelStyle={{
                        color: 'white',
                        marginBottom: 8,
                    }}
                    contentStyle={{
                        background: '#080922',
                        padding: 8,
                        borderRadius: 6,
                        border: 'none',
                    }}
                />
                <Bar
                    dataKey="lateCancel"
                    stackId="a"
                    shape={<RoundedBar />}
                    fill="#FFE3DE"
                    barSize={23}
                />
                <Bar
                    dataKey="completed"
                    stackId="a"
                    shape={<RoundedBar />}
                    fill="#FF7558"
                    barSize={23}
                />
            </BarChart>
        </ResponsiveContainer>
    )
}
