import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../../components/buttons'
import { CenterModal, ModalProps } from '../../../components/modals'

interface Props extends ModalProps {
    onConfirm: any
    name: string
    isOpen: boolean
    setIsOpen: any
    title: string
    subText: string
}

export const BasicConfirmModal: React.FC<Props> = observer(
    ({ isOpen, setIsOpen, onConfirm, name, title, subText }) => {
        const { t } = useTranslation()

        const close = useCallback(() => {
            setIsOpen(false)
        }, [])

        return (
            <CenterModal isOpen={isOpen} setIsOpen={setIsOpen}>
                <header className="flex flex-col space-y-3 mb-6 mx-9">
                    <span className="text-2xl">{title}</span>
                    <span className="text-xs text-[#56576A]">{subText}</span>
                </header>

                <footer className="px-9 py-5 flex space-x-8">
                    <PrimaryButton
                        trackingId={name}
                        trackingAction="cancel"
                        type="button"
                        className="flex-1 btn-open"
                        onClick={close}
                    >
                        <span>{t('buttons.cancel')}</span>
                    </PrimaryButton>
                    <PrimaryButton
                        trackingId={name}
                        trackingAction="confirm"
                        onClick={() => onConfirm()}
                        type="button"
                        className="flex-1 btn-[#3095aa]"
                    >
                        <span>{t('buttons.confirm')}</span>
                    </PrimaryButton>
                </footer>
            </CenterModal>
        )
    },
)
