import { IsOptional, MaxLength, MinLength } from 'class-validator'
import { t } from 'i18next'
import { Validation } from '../../../util/constants'
import { BaseModel } from '../base.model'

export class DepartmentModel implements BaseModel {
    constructor(_id?: string, name: string = '') {
        ;(this._id = _id), (this.name = name)
    }

    @IsOptional()
    public _id: string | undefined

    @MinLength(Validation.SHORT_STRING_MIN_LENGTH, {
        message: t('validation.tooShort') as string,
    })
    @MaxLength(Validation.SHORT_STRING_MAX_LENGTH, {
        message: t('validation.tooLong') as string,
    })
    public name: string = ''

    public getRequestBody() {
        return {
            _id: this._id,
            name: this.name,
        }
    }
}
