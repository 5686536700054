import { Navigate } from 'react-router-dom'
import { RouteLink } from '../util/constants'
import {
    LogInPage,
    MagicLoginPage,
    MagicSignupPage,
    SetPhonePage,
    SignUpPage,
    TwoFaPage,
} from './auth'
import { DashboardPage } from './dashboard'
import { AccountPage } from './account'
import { DownloadPdfPage } from './download'

type Component = React.FC | React.LazyExoticComponent<any>
type RoutePath = RouteLink & string

export enum AuthRequirement {
    AUTHENTICATED,
    UNAUTHENTICATED,
    AWAITING_2FA,
    NOT_AWAITING_2FA,
    HAS_PHONE,
    DOES_NOT_HAVE_PHONE,
    FULLY_AUTHENTICATED,
    FULLY_UNAUTHENTICATED,
}

export type Route = [
    RoutePath[] /* Paths for route */,
    string /* Title */,
    Component /* Component to render */,
    AuthRequirement[],
]

export const routes: Route[] = [
    [[RouteLink.LOG_IN], 'logIn', LogInPage, [AuthRequirement.UNAUTHENTICATED]],
    [
        [RouteLink.SET_PHONE],
        'setPhoneNumber',
        SetPhonePage,
        [AuthRequirement.DOES_NOT_HAVE_PHONE, AuthRequirement.AUTHENTICATED],
    ],
    [
        [RouteLink.MAGIC_LOGIN],
        'magicLogin',
        MagicLoginPage,
        [AuthRequirement.UNAUTHENTICATED],
    ],
    [
        [RouteLink.SIGN_UP],
        'signUp',
        SignUpPage,
        [AuthRequirement.AUTHENTICATED],
    ],
    [
        [RouteLink.MAGIC_SIGNUP],
        'magicSignup',
        MagicSignupPage,
        [AuthRequirement.UNAUTHENTICATED],
    ],
    [
        [RouteLink.TWO_FA],
        'twoFa',
        TwoFaPage,
        [AuthRequirement.HAS_PHONE, AuthRequirement.AWAITING_2FA],
    ],
    [
        [RouteLink.DASHBOARD],
        'dashboard',
        DashboardPage,
        [AuthRequirement.FULLY_AUTHENTICATED],
    ],
    [
        [RouteLink.ACCOUNT],
        'account',
        AccountPage,
        [AuthRequirement.FULLY_AUTHENTICATED],
    ],
    [
        [RouteLink.ACCOUNT_WILDCARD],
        'account',
        AccountPage,
        [AuthRequirement.FULLY_AUTHENTICATED],
    ],
    [
        [RouteLink.DOWNLOAD_PDF_REPORT],
        'downloadPdf',
        DownloadPdfPage,
        [AuthRequirement.FULLY_AUTHENTICATED],
    ],
    [
        [RouteLink.FAQS],
        'faqs',
        DashboardPage,
        [AuthRequirement.FULLY_AUTHENTICATED],
    ],
    [
        [RouteLink.INDEX],
        'redirecting',
        () => <Navigate to={RouteLink.DASHBOARD} replace />,
        [AuthRequirement.FULLY_AUTHENTICATED],
    ],
    [
        [RouteLink.ANY],
        'redirecting',
        () => <Navigate to={RouteLink.DASHBOARD} replace />,
        [],
    ],
]
