import { isFunction } from 'formik'
import { forEach, values } from 'lodash'
import {
    CompanyStore,
    NotificationsStore,
    StaticsStore,
    UserStore,
} from '../stores'
import { AuthStore } from '../stores/auth.store'

type Stores = {
    auth: AuthStore
    company: CompanyStore
    notifications: NotificationsStore
    statics: StaticsStore
    user: UserStore

    // Store functions
    reset: () => void
}

export const stores: Stores = {
    auth: new AuthStore(),
    company: new CompanyStore(),
    notifications: new NotificationsStore(),
    statics: new StaticsStore(),
    user: new UserStore(),

    reset(): void {
        forEach(values(this), (value: any) => {
            const reset = value.reset

            if (reset && isFunction(reset)) {
                reset()
            }
        })
    },
}

export const useStores = () => {
    return stores
}
