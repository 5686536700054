import { t } from 'i18next'
import { entries, get, padStart, range, shuffle } from 'lodash'
import { observer } from 'mobx-react'
import React, { useCallback, useMemo } from 'react'
import {
    Cell,
    Pie,
    PieChart as RechartsPieChart,
    ResponsiveContainer,
    Tooltip,
} from 'recharts'
// @ts-ignore
import { ValueType } from 'recharts/lib/component/DefaultTooltipContent'
import { Static } from '../../models/response'
import { getStaticsMap } from '../../stores'
import { useStores } from '../../util/stores'
import { LabelLine } from './label-line'

interface Data {
    name: string
    value: number
}

interface Props {
    dataKey: string
    staticsKey: string
    color: string
}

const transparencyValues = shuffle(
    range(3, 9).map((x) => Math.floor((x * 10 + 20) * 2.55)),
)

export const DonutChart: React.FC<Props> = observer(
    ({ dataKey: key, staticsKey, color }) => {
        const { company, statics } = useStores()

        const data: Data[] = useMemo(() => {
            const data_: Record<string, number> = get(
                company.monthlyAggregates[0],
                [key],
            )

            if (!data_) {
                return []
            }

            const map = getStaticsMap((statics as any)[staticsKey] as Static[])
            return entries(data_).map(([name, value]) => ({
                name: map[name] ?? (t('extras.other') as string),
                value,
            }))
        }, [t, company.monthlyAggregates])

        const tooltipFormatter = useCallback((value: ValueType) => {
            if (typeof value === 'number') {
                return value.toFixed(2) + '%'
            }
            return ''
        }, [])

        return (
            <ResponsiveContainer width="100%" height="100%">
                <RechartsPieChart>
                    <Tooltip
                        wrapperStyle={{ outline: 'none' }}
                        cursor={{ fill: '#EDEDED' }}
                        formatter={tooltipFormatter}
                        itemStyle={{
                            fontSize: '14px',
                            marginTop: -8,
                            color: 'white',
                        }}
                        labelStyle={{
                            color: 'white',
                            marginBottom: 8,
                        }}
                        contentStyle={{
                            background: '#080922',
                            padding: 8,
                            borderRadius: 6,
                            border: 'none',
                        }}
                    />
                    <Pie
                        data={data.filter((_) => _.value > 0)}
                        cx="50%"
                        cy="50%"
                        innerRadius={55}
                        outerRadius={90}
                        dataKey="value"
                        label={LabelLine}
                        labelLine={false}
                        animationBegin={200}
                        animationDuration={800}
                    >
                        {color
                            ? data.map((_, i) => (
                                  <Cell
                                      key={`cell-${i}`}
                                      fill={
                                          color +
                                          padStart(
                                              transparencyValues[
                                                  i % transparencyValues.length
                                              ].toString(16),
                                              2,
                                              '0',
                                          )
                                      }
                                  />
                              ))
                            : null}
                    </Pie>
                </RechartsPieChart>
            </ResponsiveContainer>
        )
    },
)
