import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Subscription } from 'rxjs'
import { PrimaryButton } from '../../../components/buttons'
import {
    DonutChart,
    LegendItem,
    MonthlyLineChart,
    MonthlyStackedBar,
} from '../../../components/charts'
import { cancelSubscriptions, classNames, history } from '../../../util/misc'

import { useStores } from '../../../util/stores'
import {
    AttritionRiskChart,
    CoachRatingChart,
    EngagementTrackerChart,
    MonthlyActiveUsers,
    MonthlyBookedSessionsChart,
    SessionDurationChart,
    TotalCoachingHours,
    TotalSessionsChart,
} from '../charts'
import {
    Block,
    ScollableBlock,
    DashboardPageWrapper,
    Filters,
} from '../components'
import { LogoDisplay } from '../../../components/misc/logo-display'
import { AccountLevel, RouteLink } from '../../../util/constants'
import { cleanQueryParams } from '../../../util/request'
import { InsufficentChartMessage } from '../components/insufficent-chart-message'
import { areaOfCoaching, seniorityLevel } from '../../../util/constants/graph'

export const DashboardPage: React.FC = observer(() => {
    const { t } = useTranslation()
    const { company, user } = useStores()
    const [sessionsByCoacheeLength, setSessionsByCoacheeLength] = useState(0)
    const [attritionRiskLength, setAttritionRiskLength] = useState(0)
    const [searchParams, setSearchParams] = useSearchParams()
    const subscriptions: Subscription[] = []
    const subscriptionsTwo: Subscription[] = []
    const isAdmin = user.canAccess(AccountLevel.ADMIN)

    const showBarChartRatherThanLine = user.canAccess(AccountLevel.SAMA_ADMIN)

    const getExtraWidth = useCallback(
        (numberOfObjects: number, oneBar: number, numPerPage: number) => {
            const numHidden = numberOfObjects - numPerPage
            return numHidden * oneBar > 0 ? numHidden * oneBar : 0
        },
        [company.activeCompanyId],
    )

    const getCompanyMonthlyAggregates = useCallback(() => {
        const subscription = company.getCompanyMonthlyAggregates().subscribe({
            next() {
                const numberOfObjects =
                    company.cumulativeAggregate?.sessionsByCoachee.length ?? 0
                setSessionsByCoacheeLength(
                    getExtraWidth(numberOfObjects, 40, 25),
                )
                setAttritionRiskLength(
                    getExtraWidth(
                        company.getCompanyMonthlyAttritionRisk()?.length,
                        200,
                        7,
                    ),
                )
            },
        })

        subscriptions.push(subscription)
    }, [company.activeCompanyId])

    const exportAggregates = useCallback(() => {
        const subscription = company.exportCompanyAggregates().subscribe()
        subscriptions.push(subscription)
    }, [company.activeCompanyId])

    const getCompanyCoachingHours = useCallback(() => {
        const subscription = company.getCompanyCoachingHours().subscribe()
        subscriptionsTwo.push(subscription)
    }, [company.activeCompanyId])

    useEffect(() => {
        if (company.activeCompanyId && company.chartOptions?.contractHours) {
            getCompanyCoachingHours()
        }

        return () => {
            cancelSubscriptions(subscriptionsTwo)
        }
    }, [company.activeCompanyId])

    useEffect(() => {
        if (isAdmin) {
            const subscription = company.listCompanyCoachees().subscribe()
            subscriptions.push(subscription)
            return () => {
                cancelSubscriptions(subscriptions)
            }
        }
    }, [company.activeCompanyId, isAdmin, company.selectedFilters])

    /**
     *  Get the query params from the url
     */
    useEffect(() => {
        company.resetFilters()
        const FILTER_PARAMS = [
            'areasOfCoaching',
            'seniorityLevels',
            'functions',
            'userStatus',
            'coacheeGenders',
            'departments',
        ]

        FILTER_PARAMS.forEach((param) => {
            const value = searchParams.get(param)
            if (value) {
                company.pushFilter(param, value)
            }
        })

        if (searchParams.has('startDate') && searchParams.has('endDate')) {
            company.setFilter(['date'], {
                start: searchParams.get('startDate'),
                end: searchParams.get('endDate'),
            })
        }
    }, [company.activeCompanyId, company.activeCompany])

    /**
     *  Set the query params in to the url
     */
    useEffect(() => {
        const query = cleanQueryParams(company.getFilterQuery())
        if (Array.from(query).length > 0) {
            history.push(
                RouteLink.DASHBOARD +
                    '?' +
                    cleanQueryParams(company.getFilterQuery()),
            )
        } else {
            history.push(RouteLink.DASHBOARD)
        }
    }, [company.activeCompanyId, company.selectedFilters])

    useEffect(() => {
        if (company.activeCompanyId) {
            getCompanyMonthlyAggregates()
        }

        return () => {
            cancelSubscriptions(subscriptions)
        }
    }, [company.activeCompanyId, company.selectedFilters])

    return (
        <DashboardPageWrapper title={t('titles.dashboard')}>
            <div className="px-4 py-4 lg:px-[3.375rem] lg:py-11 grid gap-[1.875rem]">
                <header className="flex flex-col items-center">
                    <LogoDisplay />
                    <span className="text-[2.85rem]">
                        {company.activeCompany?.label}
                    </span>
                    <div className="flex mt-5 space-x-3">
                        <Filters />
                        <PrimaryButton
                            trackingId="exportToPdf"
                            trackingAction="generate"
                            className="btn-white px-6"
                            onClick={exportAggregates}
                            disabled={company.exportDisabled}
                        >
                            <span>{t('buttons.exportToPdf')}</span>
                        </PrimaryButton>
                    </div>
                </header>
                <main className="grid grid-cols-[1fr] lg:grid-cols-2 gap-[0.85rem] w-full max-w-[90rem] mx-auto">
                    <div
                        className={classNames(
                            'h-full grid gap-[0.85rem]',
                            'aspect-square lg:aspect-[8] lg:col-span-2 grid-cols-1 lg:grid-cols-3',
                        )}
                    >
                        <Block className="flex flex-col justify-center pl-8 leading-[0.7]">
                            <span className="text-[36px] lg:text-[2.3vw] pb-[20px]">
                                {company.cumulativeAggregate?.licencesActive ??
                                    t('extras.n/a')}
                            </span>
                            <span className="text-[3.8vw] lg:text-[1.2vw] text-[#56576A]">
                                {t('dashboard.licencesActive')}
                            </span>
                        </Block>
                        <Block className=" flex flex-col justify-center pl-8 leading-[0.7]">
                            <span className="text-[36px] lg:text-[2.3vw] pb-[20px]">
                                {company.cumulativeAggregate
                                    ?.coacheesConnectedToCoach ??
                                    t('extras.n/a')}
                            </span>
                            <span className="text-[3.8vw] lg:text-[1.2vw] text-[#56576A]">
                                {t('dashboard.coacheesConnectedToCoach')}
                            </span>
                        </Block>
                        <Block className="flex flex-col justify-center pl-8 leading-[0.7]">
                            <span className="text-[36px] lg:text-[2.3vw] pb-[20px]">
                                {company.cumulativeAggregate
                                    ?.completedFirstSession ?? t('extras.n/a')}
                            </span>
                            <span className="text-[3.8vw] lg:text-[1.2vw] text-[#56576A]">
                                {t('dashboard.completedFirstSession')}
                            </span>
                        </Block>
                    </div>
                    {company.monthlyAggregates.length === 1 && (
                        <Block className="aspect-square flex flex-col lg:col-span-2 lg:aspect-[4] w-full py-4 px-6">
                            <span>{t('dashboard.areaOfCoaching.title')}</span>
                            <div className="flex-1">
                                <DonutChart
                                    dataKey="areaOfCoaching"
                                    staticsKey="competencies"
                                    color="#3095AA"
                                />
                            </div>
                        </Block>
                    )}
                    {company.monthlyAggregates.length > 1 && (
                        <>
                            <Block className="aspect-square lg:aspect-[2] lg:col-span-2 flex flex-col w-full py-4 pl-6 pr-2">
                                <div className="flex justify-between">
                                    <span>
                                        {t('dashboard.areaOfCoaching.title')}
                                        <p className="text-[14px]">
                                            {t(
                                                'dashboard.areaOfCoaching.subTitle',
                                            )}
                                        </p>
                                    </span>
                                    {showBarChartRatherThanLine && (
                                        <div>
                                            <div className="flex justify-end">
                                                {areaOfCoaching.map((area) => (
                                                    <LegendItem
                                                        className="mr-2"
                                                        color={area.color}
                                                        text={t(
                                                            `dashboard.areaOfCoaching.${area.name}`,
                                                        )}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <ScollableBlock>
                                    {!showBarChartRatherThanLine && (
                                        <MonthlyLineChart
                                            dataKey="areaOfCoaching"
                                            staticsKey="competencies"
                                            color="#3095AA"
                                        />
                                    )}
                                    {showBarChartRatherThanLine && (
                                        <MonthlyStackedBar
                                            dataKey="areaOfCoaching"
                                            staticsKey="competencies"
                                            colors={[
                                                '',
                                                '#FF7558',
                                                '#FFAC9B',
                                                '#FFE3DE',
                                                '#3095AA',
                                                '#83BFCC',
                                            ]}
                                        />
                                    )}
                                </ScollableBlock>
                            </Block>

                            <Block className="aspect-square lg:aspect-[2] lg:col-span-2 flex flex-col w-full py-4 pl-6 pr-2">
                                <div className="flex justify-between">
                                    <span>
                                        {t('dashboard.seniorityLevel.title')}
                                        <p className="text-[14px]">
                                            {t(
                                                'dashboard.seniorityLevel.subTitle',
                                            )}
                                        </p>
                                    </span>
                                    {showBarChartRatherThanLine && (
                                        <div>
                                            <div className="flex justify-end">
                                                {seniorityLevel.map((level) => (
                                                    <LegendItem
                                                        className="mr-2"
                                                        color={level.color}
                                                        text={t(
                                                            `dashboard.seniorityLevel.${level.name}`,
                                                        )}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <ScollableBlock>
                                    {!showBarChartRatherThanLine && (
                                        <MonthlyLineChart
                                            dataKey="seniorityLevel"
                                            staticsKey="positions"
                                            color="#FF7558"
                                        />
                                    )}
                                    {showBarChartRatherThanLine && (
                                        <MonthlyStackedBar
                                            dataKey="seniorityLevel"
                                            staticsKey="positions"
                                            colors={[
                                                '',
                                                '#FF7558',
                                                '#FFAC9B',
                                                '#FFE3DE',
                                                '#3095AA',
                                                '#83BFCC',
                                            ]}
                                        />
                                    )}
                                </ScollableBlock>
                            </Block>

                            <Block className="aspect-square lg:aspect-[2] lg:col-span-2 flex flex-col w-full py-4 pl-6 pr-2">
                                <div className="flex justify-between">
                                    <span>
                                        {t('dashboard.function.title')}
                                        <p className="text-[14px]">
                                            {t('dashboard.function.subTitle')}
                                        </p>
                                    </span>
                                </div>

                                <ScollableBlock>
                                    {!showBarChartRatherThanLine && (
                                        <MonthlyLineChart
                                            dataKey="function"
                                            staticsKey="functions"
                                            color="#3095AA"
                                        />
                                    )}
                                    {showBarChartRatherThanLine && (
                                        <MonthlyStackedBar
                                            dataKey="function"
                                            staticsKey="functions"
                                            colors={[
                                                '',
                                                '#FF7558',
                                                '#FFAC9B',
                                                '#FFE3DE',
                                                '#FFE3D0',
                                                '#3095AA',
                                                '#83BFCC',
                                            ]}
                                        />
                                    )}
                                </ScollableBlock>
                            </Block>
                        </>
                    )}
                    {company.monthlyAggregates.length === 1 && (
                        <Block className="aspect-square flex flex-col w-full py-4 px-6">
                            <span>{t('dashboard.seniorityLevel.title')}</span>
                            <div className="flex-1">
                                <DonutChart
                                    dataKey="seniorityLevel"
                                    staticsKey="positions"
                                    color="#FF7E63"
                                />
                            </div>
                        </Block>
                    )}
                    {company.monthlyAggregates.length === 1 && (
                        <Block className="aspect-square flex flex-col w-full py-4 px-6">
                            <span>{t('dashboard.function.title')}</span>
                            <div className="flex-1">
                                <DonutChart
                                    dataKey="function"
                                    staticsKey="functions"
                                    color="#3095AA"
                                />
                            </div>
                        </Block>
                    )}
                    {company.cumulativeAggregate?.sessionsByCoachee.length ? (
                        <Block className="aspect-square lg:aspect-[2] lg:col-span-2 flex flex-col w-full py-4 px-6">
                            <div className="flex justify-between">
                                <span>
                                    {t(
                                        'dashboard.totalAudioOrVideoSessions.title',
                                    )}
                                    <p className="text-[14px]">
                                        {t(
                                            'dashboard.totalAudioOrVideoSessions.subTitle',
                                        )}
                                    </p>
                                </span>

                                <div>
                                    <div className="flex justify-end">
                                        <LegendItem
                                            className="mr-2"
                                            color="#FFE3DE"
                                            text={t(
                                                'dashboard.totalAudioOrVideoSessions.lateCancel',
                                            )}
                                        />
                                        <LegendItem
                                            color="#FF7558"
                                            text={t(
                                                'dashboard.totalAudioOrVideoSessions.completed',
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex-1 pt-8">
                                <ScollableBlock
                                    scrollableStyle={{
                                        width:
                                            'calc(100% + ' +
                                            sessionsByCoacheeLength +
                                            'px)',
                                    }}
                                >
                                    <TotalSessionsChart
                                        data={
                                            company.cumulativeAggregate
                                                .sessionsByCoachee
                                        }
                                    />
                                </ScollableBlock>
                            </div>
                        </Block>
                    ) : null}
                    {company.cumulativeAggregate && (
                        <Block className="aspect-square flex flex-col w-full py-4 px-6">
                            <span>{t('dashboard.sessionDuration.title')}</span>
                            <span className="text-[14px]">
                                {t('dashboard.sessionDuration.subTitle')}
                            </span>
                            <div className="flex-1 pt-8">
                                <SessionDurationChart
                                    data={[
                                        {
                                            duration: 25,
                                            value:
                                                company.cumulativeAggregate
                                                    .sessionDuration[25] ?? 0,
                                        },
                                        {
                                            duration: 50,
                                            value:
                                                company.cumulativeAggregate
                                                    .sessionDuration[50] ?? 0,
                                        },
                                    ]}
                                />
                            </div>
                        </Block>
                    )}
                    {company.cumulativeAggregate &&
                        company.cumulativeAggregate?.monthlyBookedSessionCounts
                            .length !== 0 && (
                            <Block className="aspect-square flex flex-col w-full py-4 px-6">
                                <span>
                                    {t('dashboard.monthlyBookedSessions.title')}
                                </span>
                                <span className="text-[14px]">
                                    {t(
                                        'dashboard.monthlyBookedSessions.description',
                                    )}
                                </span>
                                <div className="flex-1 pt-8">
                                    <MonthlyBookedSessionsChart
                                        data={
                                            company.cumulativeAggregate
                                                .monthlyBookedSessionCounts
                                        }
                                    />
                                </div>
                            </Block>
                        )}
                    {company.monthlyAggregates.length !== 0 &&
                        company.monthlyAggregates[0].monthlyActiveUsers !==
                            null && (
                            <Block className="aspect-square lg:aspect-[2] lg:col-span-2 flex flex-col w-full py-4 px-6">
                                <span>
                                    {t('dashboard.monthlyActiveUsers.title')}
                                </span>
                                <span className="text-[14px]">
                                    {t(
                                        'dashboard.monthlyActiveUsers.description',
                                    )}
                                </span>
                                <div className="flex-1 pt-8">
                                    <MonthlyActiveUsers
                                        data={company.getCompanymonthlyActiveUsers()}
                                    />
                                </div>
                            </Block>
                        )}
                    {company.quarterlyAggregates.length !== 0 && (
                        <Block className="aspect-square lg:aspect-[2] lg:col-span-2 flex flex-col w-full py-4 px-6">
                            <span>
                                {t('dashboard.employeeEngagementTracker.title')}
                            </span>
                            <span className="text-[14px]">
                                {t(
                                    'dashboard.employeeEngagementTracker.description',
                                )}
                            </span>
                            <div className="flex-1 pt-8">
                                <EngagementTrackerChart
                                    data={company.quarterlyAggregates}
                                />
                            </div>
                            <InsufficentChartMessage
                                quarter={company.hiddenQuarterlyAggregates}
                            />
                        </Block>
                    )}
                    {company.monthlyAggregates.length !== 0 &&
                        company.chartOptions?.attritionRisk &&
                        company.getCompanyMonthlyAttritionRisk().length > 0 && (
                            <Block
                                className={classNames(
                                    !company.cumulativeAggregate?.coachRating
                                        ? 'lg:aspect-[2] lg:col-span-2'
                                        : '',
                                    'aspect-square flex flex-col w-full py-4 px-6',
                                )}
                            >
                                <div className="flex justify-between">
                                    <div className="flex flex-col">
                                        <span>
                                            {t('dashboard.attritionRisk.title')}
                                        </span>
                                        <span className="text-[14px]">
                                            {t(
                                                'dashboard.attritionRisk.description',
                                            )}
                                        </span>
                                    </div>
                                    <div>
                                        <div className="flex justify-end">
                                            <LegendItem
                                                className="mr-2"
                                                color="#FFAC9B"
                                                text={t(
                                                    'dashboard.attritionRisk.legend.maybe',
                                                )}
                                            />
                                            <LegendItem
                                                color="#3095AA"
                                                text={t(
                                                    'dashboard.attritionRisk.legend.no',
                                                )}
                                            />
                                        </div>
                                        <div className="flex justify-end">
                                            <LegendItem
                                                className="mr-2"
                                                color="#FF7558"
                                                text={t(
                                                    'dashboard.attritionRisk.legend.yes',
                                                )}
                                            />
                                            <LegendItem
                                                color="#83BFCC"
                                                text={t(
                                                    'dashboard.attritionRisk.legend.unknown',
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1 pt-8">
                                    <ScollableBlock
                                        scrollableStyle={{
                                            width:
                                                'calc(100% + ' +
                                                attritionRiskLength +
                                                'px)',
                                        }}
                                    >
                                        <AttritionRiskChart
                                            data={company.getCompanyMonthlyAttritionRisk()}
                                        />
                                    </ScollableBlock>
                                </div>
                            </Block>
                        )}
                    {company.cumulativeAggregate?.coachRating && (
                        <Block
                            className={classNames(
                                !company.chartOptions?.attritionRisk
                                    ? 'lg:aspect-[2] lg:col-span-2'
                                    : '',
                                'aspect-square flex flex-col w-full py-4 px-6',
                            )}
                        >
                            <span>{t('dashboard.coachRating.title')}</span>
                            <span className="text-[14px]">
                                {t('dashboard.coachRating.description')}
                            </span>
                            <div className="flex-1 pt-8">
                                <CoachRatingChart
                                    data={
                                        company.cumulativeAggregate!.coachRating
                                    }
                                />
                            </div>
                        </Block>
                    )}
                    {company.coachingHours &&
                        company.coachingHours.total !== 0 && (
                            <Block className="aspect-square lg:aspect-[2] lg:col-span-2 flex flex-col w-full py-4 px-6">
                                <span>
                                    {t('dashboard.totalCoachingHours.title')}
                                </span>
                                <span className="text-[14px] text-[#56576A]">
                                    {t(
                                        'dashboard.totalCoachingHours.description',
                                    )}
                                </span>
                                <div className="flex-1 pt-4 lg:pt-12">
                                    <TotalCoachingHours
                                        data={company.coachingHours}
                                    />
                                </div>
                            </Block>
                        )}
                </main>
            </div>
        </DashboardPageWrapper>
    )
})

export default DashboardPage
