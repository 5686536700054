import { Tab } from '@headlessui/react'
import { observer } from 'mobx-react'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../../components/buttons'
import { LogoDisplay } from '../../../components/misc/logo-display'
import {
    classNames,
    formatDate,
    formatMonthYear,
    history,
} from '../../../util/misc'
import { useStores } from '../../../util/stores'
import { DashboardPageWrapper } from '../../dashboard/components/dashboard-page-wrapper'
import { CoacheeDataTable } from '../components/coachee-data-table'
import { DepartmentsTab } from '../components/departments-tab'
import { UpgradePlanModal } from '../models/update-plan.modal'
import { CompanyDetailTab } from '../components/company-detail-tab'
import { RouteLink } from '../../../util/constants'
import { CSVLink } from 'react-csv'
import { Coachee } from '../../../models/response'
import { Row } from '@tanstack/react-table'
import { AddCoacheeModel } from '../models'

interface TabDetails {
    name: string
    url: string
    index: number
}

interface Props {
    isHorizontalScrollVisible?: boolean
}

const AccountPageTabs: React.FC<Props> = observer(
    ({ isHorizontalScrollVisible }) => {
        const [csvData, setCsvData] = useState<Row<Coachee>[]>([])
        const [csvHeaderData, setCsvHeaderData] = useState([])

        const { t } = useTranslation()
        const { company, user } = useStores()
        const [selectedIndex, setSelectedIndex] = useState(0)
        const listOfTabs: TabDetails[] = [
            { name: t('account.menuTabs.userAccess'), url: '', index: 0 },
            // { name: t('account.menuTabs.billing') },
            {
                name: t('account.menuTabs.departments'),
                url: '/departments',
                index: 1,
            },
            {
                name: t('account.menuTabs.companyDetails'),
                url: '/company-details',
                index: 2,
            },
        ]

        const changeTab = useCallback((index: number) => {
            setSelectedIndex(index)
            if (listOfTabs[index].url !== undefined) {
                history.push(RouteLink.ACCOUNT + listOfTabs[index].url)
            }
        }, [])

        useEffect(() => {
            company.resetFilters()
            const currentTab = listOfTabs.filter(
                (tab) =>
                    RouteLink.ACCOUNT + tab.url === window.location.pathname,
            )

            if (currentTab && currentTab.length === 1) {
                setSelectedIndex(currentTab?.pop()?.index ?? 0)
            }
        }, [])

        const trackClick = useCallback(
            (trackingId: string, trackingAction: string) => {
                user.trackEvent('button_clicked', {
                    name: trackingId,
                    action: trackingAction,
                }).subscribe()
            },
            [],
        )

        return (
            <Tab.Group selectedIndex={selectedIndex} onChange={changeTab}>
                <Tab.List
                    className={classNames(
                        'bg-[#FFFFFF]',
                        'min-w-[200px]',
                        'block border-none outline-hidden ring-white',
                    )}
                >
                    {listOfTabs.map((tab, index) => {
                        return (
                            <Tab key={index} as={Fragment}>
                                {({ selected }) => (
                                    <button
                                        className={classNames(
                                            'min-w-[150px]',
                                            'w-[25%] h-[55px] pt-3 border border-[#f6f6f6]',
                                            'border-solid outline-none ',
                                            selected
                                                ? 'text-[#3095aa] bg-white'
                                                : 'bg-white text-black',
                                        )}
                                    >
                                        {tab.name}
                                        <div
                                            className={classNames(
                                                'mt-4 h-[3px]',
                                                selected
                                                    ? 'bg-[#3095aa] relative z-10'
                                                    : 'bg-[#f6f6f6]',
                                            )}
                                        ></div>
                                    </button>
                                )}
                            </Tab>
                        )
                    })}
                    <div className="inline-block min-w-[150px] pr-[30px] text-center w-[25%] h-[55px] pt-3 border border-[#f6f6f6] border-solid outline-none bg-white text-black">
                        <img
                            src="/img/icons/download.svg"
                            alt="Download icon"
                            className="inline-block w-3 ml-2 mr-[10px]"
                        />
                        <CSVLink
                            filename={`sama_coachee_data_${formatDate(
                                new Date(),
                                'dd-mm-yyyy',
                            )}.csv`}
                            data={csvData}
                            headers={csvHeaderData}
                            enclosingCharacter={`"`}
                        >
                            <span
                                onClick={() => {
                                    trackClick('downloadCSV', 'download')
                                }}
                                className="mt-[5px] h-[42px] w-[200px] min-w-[120px]  mr-[20px] font-normal"
                            >
                                <span className="align-middle">
                                    {t('buttons.downloadCsv')}
                                </span>
                            </span>
                        </CSVLink>
                    </div>
                    <div className="border-2 relative top-[-2px] border-[#f6f6f6]"></div>
                </Tab.List>
                <Tab.Panels className="min-w-[600px] w-[100%]">
                    <Tab.Panel>
                        <CoacheeDataTable
                            setCsvExportData={setCsvData}
                            setCsvHeaders={setCsvHeaderData}
                            isHorizontalScrollVisible={
                                isHorizontalScrollVisible
                            }
                        />
                    </Tab.Panel>
                    <Tab.Panel>
                        <DepartmentsTab />
                    </Tab.Panel>
                    <Tab.Panel>
                        <CompanyDetailTab />
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        )
    },
)

export const AccountPage: React.FC = observer(() => {
    const { t } = useTranslation()
    const { company, user } = useStores()
    const [isUpgradePlanModalOpen, setIsUpgradePlanModalOpen] = useState(false)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isHorizontalScrollVisible, setIsHorizontalScrollVisible] =
        useState(false)

    const upgradePlan = useCallback(() => {
        setIsUpgradePlanModalOpen(true)
    }, [])

    return (
        <DashboardPageWrapper
            title={t('titles.account')}
            backgroundColour="bg-[#FFFFFF]"
            setIsHorizontalScrollVisible={setIsHorizontalScrollVisible}
        >
            <div
                className={classNames(
                    'bg-[#FFFFFF]',
                    'px-4 py-4 sm:px-[0rem]',
                    'sm:py-11 grid gap-[1.875rem] items-center ',
                )}
            >
                <div className="min-w-[300px] rounded-t-xl">
                    <header className="pb-4 px-4 bg-[#FFFFFF] flex items-center">
                        <div className="flex flex-row items-center">
                            <LogoDisplay />
                            <span className="pl-3 flex-col items-left text-[1.85rem]">
                                {company.activeCompany?.label}
                                <span className="block flex-col items-left text-[.85rem]">
                                    {company.activeCompany
                                        ?.dateOfActivation && (
                                        <Trans
                                            t={t}
                                            i18nKey="account.activeCompanySince"
                                            tOptions={{
                                                formattedDate: formatMonthYear(
                                                    company.activeCompany
                                                        ?.dateOfActivation,
                                                ),
                                            }}
                                        />
                                    )}
                                </span>
                            </span>
                        </div>
                        <div className="ml-auto hidden md:flex ">
                            {/* <PrimaryButton
                                trackingId="upgradePlan"
                                trackingAction="open"
                                className="w-40"
                                onClick={upgradePlan}
                            >
                                <span>{t('buttons.upgradePlan')}</span>
                            </PrimaryButton> */}
                        </div>
                        <UpgradePlanModal
                            isOpen={isUpgradePlanModalOpen}
                            setIsOpen={setIsUpgradePlanModalOpen}
                            adminDetail={{
                                firstName: user.user?.firstName!,
                                email: user.user?.email!,
                            }}
                        />
                        <div className="ml-auto hidden md:flex bg-[#f1f8f9] m-auto px-[25px] py-[20px]">
                            <span className="m-auto">
                                <Trans
                                    t={t}
                                    i18nKey="account.menuHeader.currentPlanSubText"
                                    tOptions={{
                                        seats: company.numOfActiveLicences.toString(),
                                    }}
                                />
                            </span>
                            <PrimaryButton
                                onClick={() => setIsModalOpen(true)}
                                trackingId="addCoachee"
                                trackingAction="open"
                                className="h-[42px] w-[100px] min-w-[120px] m-auto ml-[20px] font-normal"
                            >
                                <span>{t('buttons.addUsers')}</span>
                            </PrimaryButton>
                            <AddCoacheeModel
                                isOpen={isModalOpen}
                                setIsOpen={setIsModalOpen}
                            />
                        </div>
                        <div className="ml-auto md:flex top-[120px]"></div>
                    </header>

                    <AccountPageTabs
                        isHorizontalScrollVisible={isHorizontalScrollVisible}
                    />
                </div>
            </div>
        </DashboardPageWrapper>
    )
})

export default AccountPage
