export enum Validation {
    SHORT_STRING_MIN_LENGTH = 1,
    SHORT_STRING_MAX_LENGTH = 42,

    SHORT_MIDDLE_STRING_MIN_LENGTH = 1,
    SHORT_MIDDLE_STRING_MAX_LENGTH = 85,

    MIDDLE_STRING_MIN_LENGTH = 1,
    MIDDLE_STRING_MAX_LENGTH = 142,

    MIDDLE_BIG_STRING_MIN_LENGTH = 1,
    MIDDLE_BIG_STRING_MAX_LENGTH = 170,

    TEXT_MIN_LENGTH = 1,
    TEXT_MAX_LENGTH = 44442,

    CONTRACT_MAX_MINUTES_PER_COACHEE_MIN_VALUE = 25,
}
