import React, { useState } from 'react'
import './styles.css'

interface TooltipProps {
    text: string
    orientation: string
    children: React.ReactNode
}

export function Tooltip({ text, orientation, children }: TooltipProps) {
    const [active, setActive] = useState(false)

    function showTooltip() {
        setActive(true)
    }

    function hideTooltip() {
        setActive(false)
    }

    return (
        <div
            className="tooltip-wrapper"
            onMouseEnter={showTooltip}
            onMouseLeave={hideTooltip}
        >
            {children}

            {active && (
                <div className={`tooltip-orientation ${orientation || 'top'}`}>
                    {text}
                </div>
            )}
        </div>
    )
}
