import React from 'react'
import { PieLabelRenderProps } from 'recharts'

export const LabelLine: React.FC<PieLabelRenderProps> = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
    name,
}) => {
    cx = cx as number
    cy = cy as number
    outerRadius = outerRadius as number
    innerRadius = innerRadius as number

    const RADIAN = Math.PI / 180
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)
    const sin = Math.sin(-RADIAN * midAngle)
    const cos = Math.cos(-RADIAN * midAngle)
    const sx = cx + (outerRadius + 10) * cos
    const sy = cy + (outerRadius + 10) * sin
    const mx = cx + (outerRadius + 20) * cos
    const my = cy + (outerRadius + 20) * sin
    const ex = mx + (cos >= 0 ? 1 : -1) * 20
    const ey = my
    const textAnchor = cos >= 0 ? 'start' : 'end'

    return (
        <g>
            <text
                x={x}
                y={y}
                fill="white"
                textAnchor="middle"
                fontSize={12}
                dominantBaseline="central"
            >
                {`${value?.toFixed(0)}%`}
            </text>
            <path
                d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                stroke={'#898A9A'}
                fill="none"
            />
            <text
                x={ex + (cos >= 0 ? 1 : -1) * -10}
                y={ey - 8}
                textAnchor={textAnchor}
                fill="var(--accent-2)"
                fontSize={12}
                xmlSpace="preserve"
            >
                {name.split(' ').join('\n')}
            </text>
        </g>
    )
}
