import React from 'react'
import { classNames } from '../../util/misc'

interface Props {
    color: string
    text: string
    className?: string
}

export const LegendItem: React.FC<Props> = ({ color, text, className }) => {
    return (
        <div className={classNames('inline-flex items-center', className)}>
            <span
                className={classNames(
                    'mr-1 h-2.5 w-2.5 rounded-[3px] block shrink-0',
                )}
                style={{
                    background: color,
                }}
            />
            <span className="text-[14px]">{text}</span>
        </div>
    )
}
