import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'
import { NotificationType } from '../../util/constants'
import { classNames } from '../../util/misc'

interface Props {
    type: NotificationType
    message: string
    className?: string
}

const Wrapper = styled(motion.div)`
    &.error {
        color: #ef5658;
        background: #ffefef;
    }
`

export const FormNotification: React.FC<Props> = ({
    type,
    message,
    className,
}) => {
    const iconSrc =
        type === NotificationType.INFO
            ? '/img/icons/check-circle-open.svg'
            : '/img/icons/exclamation-circle.svg'

    return (
        <Wrapper
            className={classNames(
                'rounded-full',
                'flex items-center',
                'text-xs',
                'p-2 space-x-2',
                'bg-[#d1e5e94d] text-accent-2',
                className,
                type === NotificationType.ERROR && 'error',
            )}
        >
            <img src={iconSrc} alt="Icon" className="h-4 w-4" />
            <span>{message}</span>
        </Wrapper>
    )
}
