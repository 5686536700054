import React, { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { TableList } from '../../../components/layout/table-list'
import { Subscription } from 'rxjs'
import { useStores } from '../../../util/stores'
import { Coachee } from '../../../models/response'
import { cancelSubscriptions, classNames, formatDate } from '../../../util/misc'
import { useTranslation } from 'react-i18next'
import { EditCoacheeModal } from '../models'
import { DeactivateCoacheeModal } from '../models/deactivate-coachee.modal'
import { createColumnHelper, Row } from '@tanstack/react-table'
import { getStaticsMap } from '../../../stores'
import { NotificationType } from '../../../util/constants'

interface Props {
    setCsvExportData: any
    setCsvHeaders: any
    isHorizontalScrollVisible?: boolean
}

export const CoacheeDataTable: React.FC<Props> = observer(
    ({ setCsvExportData, setCsvHeaders, isHorizontalScrollVisible }) => {
        const { company, statics, notifications } = useStores()
        const subscriptions: Subscription[] = []
        const { t } = useTranslation()
        const [isDeactivateOpen, setIsDeactivateOpen] = useState(false)
        const [isEditModalOpen, setIsEditModalOpen] = useState(false)
        const [coacheeDeactivateDetails, setCoacheeDeactivateDetails] =
            useState({
                _id: '',
                fullName: '',
            })
        const [editCoacheeFields, setEditCoacheeFields] = useState(
            {} as Coachee,
        )

        const getCompanyCoachees = useCallback(() => {
            const subscription = company.getCoachees().subscribe()
            subscriptions.push(subscription)
        }, [company.coachees])

        useEffect(() => {
            if (company.activeCompanyId) {
                getCompanyCoachees()
            }

            return () => {
                cancelSubscriptions(subscriptions)
            }
        }, [company.activeCompanyId])

        const columnHelper = createColumnHelper<Coachee>()

        const getCoacheeTableColumns = useCallback(() => {
            const fields = [
                // Accessor Column
                columnHelper.accessor('firstName', {
                    cell: (info) => {
                        return (
                            <>
                                <span
                                    className="font-normal cursor-pointer"
                                    onClick={() => {
                                        navigator.clipboard.writeText(
                                            info.row.original.firstName +
                                                ' ' +
                                                info.row.original.lastName,
                                        )
                                        notifications.createNotification(
                                            NotificationType.INFO,
                                            t('messages.contentCopied'),
                                            2 * 1000 /* 2 seconds */,
                                        )
                                    }}
                                >
                                    <img
                                        src="/img/icons/copy.svg"
                                        alt="Copy content"
                                        className="inline w-4 ml-2 mr-2"
                                    />
                                </span>
                                <a
                                    className="font-normal cursor-pointer"
                                    onClick={() =>
                                        viewCoacheeInfo(info.row.original)
                                    }
                                >
                                    {info.getValue()}
                                </a>
                            </>
                        )
                    },
                    header: t(
                        'account.coacheeTable.headers.firstName',
                    ) as string,
                    filterFn: 'multiOption',
                    meta: {
                        trackableName: 'firstName',
                    },
                }),
                columnHelper.accessor('lastName', {
                    cell: (info) => info.getValue(),
                    header: t(
                        'account.coacheeTable.headers.lastName',
                    ) as string,
                    filterFn: 'multiOption',
                    meta: {
                        trackableName: 'lastName',
                    },
                }),
                columnHelper.accessor('status', {
                    cell: (info) => {
                        return (
                            <span
                                className={classNames(
                                    info.getValue() === 'active'
                                        ? 'bg-[#fff] border-[#3095aa]'
                                        : '',
                                    'py-1 px-2 border-[1px] rounded-[6px]',
                                    'text-xs',
                                )}
                            >
                                {
                                    t(
                                        `dashboard.coacheeActivity.status.${info.getValue()}`,
                                    ).toUpperCase() as string
                                }
                            </span>
                        )
                    },
                    header: t('account.coacheeTable.headers.status') as string,
                    filterFn: 'multiOption',
                    meta: {
                        trackableName: 'status',
                        translationKey: 'dashboard.coacheeActivity.status',
                    },
                }),
                columnHelper.accessor('latestSessionDate', {
                    cell: (info) => {
                        return (
                            <span>
                                {info.getValue()
                                    ? formatDate(info.getValue())
                                    : t('extras.n/a')}
                            </span>
                        )
                    },
                    header: t(
                        'account.coacheeTable.headers.lastestSessionDate',
                    ) as string,
                    filterFn: 'dateOption',
                    meta: {
                        trackableName: 'lastestSessionDate',
                    },
                }),
                columnHelper.accessor('createdAt', {
                    cell: (info) => {
                        return (
                            <span>
                                {info.getValue()
                                    ? formatDate(info.getValue())
                                    : t('extras.n/a')}
                            </span>
                        )
                    },
                    header: t(
                        'account.coacheeTable.headers.activatedDate',
                    ) as string,
                    filterFn: 'dateOption',
                    meta: {
                        trackableName: 'activatedDate',
                    },
                    size: 180,
                }),
                columnHelper.accessor('numberOfSessions', {
                    cell: (info) => {
                        return (
                            <span className="flex justify-center">
                                {info.getValue()}
                            </span>
                        )
                    },
                    header: t(
                        'account.coacheeTable.headers.numberOfSessions',
                    ) as string,
                    enableColumnFilter: false,
                    meta: {
                        trackableName: 'numberOfSessions',
                    },
                }),
                columnHelper.accessor('numberOfLateCancels', {
                    cell: (info) => {
                        return (
                            <span className="flex justify-center">
                                {info.getValue()}
                            </span>
                        )
                    },
                    header: t(
                        'account.coacheeTable.headers.numberOfLateCancels',
                    ) as string,
                    enableColumnFilter: false,
                    meta: {
                        trackableName: 'numberOfLateCancels',
                    },
                }),
                columnHelper.accessor('address.country', {
                    cell: (info) => {
                        const map = getStaticsMap(statics.countries)
                        return info.getValue()
                            ? map[info.getValue() as string]
                            : t('extras.n/a')
                    },
                    header: t('account.coacheeTable.headers.country') as string,
                    filterFn: 'multiOption',
                    meta: {
                        trackableName: 'countries',
                        staticsKey: 'countries',
                    },
                }),
            ]

            return getOptionalCoacheeTableColumns(fields)
        }, [company.coachees])

        const hasMinsLimitField = useCallback(() => {
            return (
                company.coachees.filter((coachee) => coachee.minsLimit > 0)
                    .length > 0
            )
        }, [company.coachees])

        const getCsvExportHeaders = useCallback(() => {
            // const hasMinsLimit = hasMinsLimitField()
            setCsvHeaders([
                {
                    label: t(
                        'account.coacheeTable.headers.firstName',
                    ) as string,
                    key: 'firstName',
                },
                {
                    label: t('account.coacheeTable.headers.lastName') as string,
                    key: 'lastName',
                },
                {
                    label: t('account.coacheeTable.headers.email') as string,
                    key: 'email',
                },
                {
                    label: t('account.coacheeTable.headers.status') as string,
                    key: 'status',
                },
                {
                    label: t('account.coacheeTable.headers.country') as string,
                    key: 'country',
                },
                {
                    label: t('account.coacheeTable.headers.location') as string,
                    key: 'city',
                },
                {
                    label: t(
                        'account.coacheeTable.headers.activatedDate',
                    ) as string,
                    key: 'activatedDate',
                },
                {
                    label: t(
                        'account.coacheeTable.headers.lastestSessionDate',
                    ) as string,
                    key: 'lastestSessionDate',
                },
                {
                    label: t(
                        'account.coacheeTable.headers.numberOfSessions',
                    ) as string,
                    key: 'numberOfSessions',
                },
                {
                    label: t(
                        'account.coacheeTable.headers.numberOfLateCancels',
                    ) as string,
                    key: 'numberOfLateCancels',
                },
                {
                    label: t(
                        'account.coacheeTable.headers.department',
                    ) as string,
                    key: 'department',
                },
            ])
        }, [company.coachees])

        const getCsvExportData = useCallback(
            (rows: Row<Coachee>[]) => {
                getCsvExportHeaders()
                const hasMinsLimit = hasMinsLimitField()
                const map = getStaticsMap(statics.countries)
                setCsvExportData(
                    rows.map((row) => {
                        const data: any = {
                            firstName: row.original.firstName,
                            lastName: row.original.lastName,
                            email: row.original.email,
                            status: row.original.status,
                            country: row.original.address?.country
                                ? map[row.original.address?.country as string]
                                : '',
                            city: row.original.address?.city,
                            activatedDate: formatDate(row.original.createdAt),
                            lastestSessionDate: row.original.latestSessionDate
                                ? formatDate(row.original.latestSessionDate)
                                : '',
                            numberOfSessions: row.original.numberOfSessions,
                            numberOfLateCancels:
                                row.original.numberOfLateCancels,
                            department:
                                row.original.department?.name ?? undefined,
                        }
                        if (hasMinsLimit) {
                            data.minsLimit = row.original.minsLimit
                            data.minsRemaining = row.original.minsRemaining
                        }
                        return data
                    }),
                )
            },
            [company.coachees],
        )

        const getOptionalCoacheeTableColumns = useCallback(
            (fields: Array<any>) => {
                if (company.departments && company.departments.length > 0) {
                    fields.push(
                        columnHelper.accessor('department.name', {
                            cell: (info) => info.getValue(),
                            header: t(
                                'account.coacheeTable.headers.department',
                            ) as string,
                            meta: {
                                trackableName: 'department',
                            },
                        }),
                    )
                }

                if (hasMinsLimitField()) {
                    fields.push(
                        columnHelper.accessor('minsLimit', {
                            cell: (info) => {
                                return (
                                    <span className="flex justify-center">
                                        {info.getValue()}
                                    </span>
                                )
                            },
                            header: t(
                                'account.coacheeTable.headers.minsLimit',
                            ) as string,
                            enableColumnFilter: false,
                            meta: {
                                trackableName: 'minsLimit',
                            },
                        }),
                    )
                    fields.push(
                        columnHelper.accessor('minsRemaining', {
                            cell: (info) => {
                                return (
                                    <span className="flex justify-center">
                                        {info.getValue()}
                                    </span>
                                )
                            },
                            header: t(
                                'account.coacheeTable.headers.minsRemaining',
                            ) as string,
                            enableColumnFilter: false,
                            meta: {
                                trackableName: 'minsRemaining',
                            },
                        }),
                    )
                }
                return fields
            },
            [company.coachees],
        )

        const viewCoacheeInfo = useCallback(
            (coachee: Coachee) => {
                setEditCoacheeFields(coachee)
                setIsEditModalOpen(true)
            },
            [close],
        )

        const confirmDeactivateCoachee = useCallback(
            (coacheeId: string, fullName: string) => {
                setCoacheeDeactivateDetails({
                    _id: coacheeId,
                    fullName,
                })
                setIsDeactivateOpen(true)
            },
            [close],
        )

        return (
            <>
                {company.coachees.length > 0 && (
                    <>
                        <TableList
                            data={company.coachees}
                            columns={getCoacheeTableColumns()}
                            binAction={confirmDeactivateCoachee}
                            csvExportData={getCsvExportData}
                            isHorizontalScrollVisible={
                                isHorizontalScrollVisible
                            }
                        />
                        <DeactivateCoacheeModal
                            isOpen={isDeactivateOpen}
                            setIsOpen={setIsDeactivateOpen}
                            coacheeDetails={coacheeDeactivateDetails}
                        />
                        <EditCoacheeModal
                            isOpen={isEditModalOpen}
                            setIsOpen={setIsEditModalOpen}
                            coacheeFields={editCoacheeFields}
                        />
                    </>
                )}
            </>
        )
    },
)
