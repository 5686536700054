import { IsISO8601, validateSync } from 'class-validator'
import { addHours } from 'date-fns'
import { assign, find, first } from 'lodash'
import { action, computed, makeAutoObservable, runInAction } from 'mobx'
import { map as rxmap, Observable, of, switchMap, tap } from 'rxjs'
import {
    CompanyAssociation,
    CompanyUser,
    FileDownload,
    User,
} from '../models/response'
import {
    AccountLevel,
    accountLevelAccess,
    HttpMethod,
    HttpStatusCode,
    NotificationType,
} from '../util/constants'
import {
    dehydrateToStorage,
    hydrateFromStorage,
    removeFromStorage,
    Resettable,
} from '../util/misc'
import { toBase64 } from '../util/misc/to-base-64'
import { request } from '../util/request'
import { stores } from '../util/stores'
import { detect } from 'detect-browser'
import { UpdateUserModel } from '../models/request/company-user/update-user.model'
import i18next, { t } from 'i18next'
const browser = detect()

class UserPictureInformation {
    public data!: string | null

    @IsISO8601()
    public expires!: string
}

const USER_KEY = 'SAMA:USER'
const USER_ASSOCIATIONS_KEY = 'SAMA:USER-ASSOCIATIONS'
const USER_ACTIVE_ASSOCIATION_KEY = 'SAMA:ACTIVE_ASSOCIATION'
const USER_PICTURE_INFORMATION_PREFIX_KEY = 'SAMA:USER-PICTURE-INFORMATION/'
const HOURS_TO_CACHE_LOGO = 10

export class UserStore implements Resettable {
    public ready: boolean = true
    public user!: User | null
    public userAssociations: CompanyAssociation[] = []
    public activeAssociation: CompanyAssociation | null = null
    public lastActiveAssociationId: string | null = null
    public picture: string | null = null
    public insightFiles: FileDownload[] | null = null

    @computed
    public get accountLevel(): AccountLevel | null {
        return this.activeAssociation?.level ?? null
    }

    @computed
    public get companyUserId(): string | null {
        return this.activeAssociation?.companyUser._id ?? null
    }

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
        this.setUp()
    }

    @action
    public setUp(): void {
        this.user = hydrateFromStorage(USER_KEY)
        this.userAssociations = hydrateFromStorage(USER_ASSOCIATIONS_KEY) ?? []
        this.lastActiveAssociationId = hydrateFromStorage(
            USER_ACTIVE_ASSOCIATION_KEY,
        )

        this.activeAssociation = this.userAssociations
            ? (find(this.userAssociations, {
                  _id: this.lastActiveAssociationId,
              }) as CompanyAssociation | undefined) ??
              first(this.userAssociations) ??
              null
            : null
    }

    @action
    public setUser(user: User): void {
        this.user = user
        dehydrateToStorage(USER_KEY, user)
    }

    @action
    public async changeSiteLanguage(language: string): Promise<void> {
        stores.statics.reset()
        await stores.statics.loadStatics(language)
        i18next.changeLanguage(language)
        stores.notifications.createNotification(
            NotificationType.INFO,
            t('messages.languageChanged'),
            3 * 1000 /* 5 seconds */,
        )
    }

    @action
    public canAccess(...levels: AccountLevel[]): boolean {
        if (!this.accountLevel) {
            return false
        }

        return levels.every((level) =>
            accountLevelAccess[this.accountLevel!].includes(level),
        )
    }

    @action
    public setCompanyAssociations(
        companyAssociations: CompanyAssociation[],
    ): void {
        this.userAssociations = companyAssociations
        this.hydrateActiveAssociation()
        dehydrateToStorage(USER_ASSOCIATIONS_KEY, companyAssociations)
    }

    @action
    public hydrateActiveAssociation() {
        if (!this.userAssociations || this.userAssociations.length === 0) {
            this.activeAssociation = null
        } else {
            const activeAssociation =
                (find(this.userAssociations, {
                    _id: this.lastActiveAssociationId,
                }) as CompanyAssociation | undefined) ??
                first(this.userAssociations) ??
                null

            if (activeAssociation) {
                this.setActiveAssociation(activeAssociation)
            }
        }
    }

    @action
    public setActiveAssociation(companyAssociation: CompanyAssociation) {
        this.activeAssociation = companyAssociation
        stores.company.resetFilters()
        dehydrateToStorage(USER_ACTIVE_ASSOCIATION_KEY, companyAssociation._id)
    }

    @action
    public setActiveAssociationByCompanyId(id: string) {
        stores.company.exportDisabled = false
        const association = find(
            this.userAssociations,
            (_association) => _association.company._id === id,
        ) as CompanyAssociation | undefined

        if (association) {
            this.setActiveAssociation(association)
        }
    }

    @action
    public getPicture() {
        if (this.companyUserId) {
            return this.getUserPicture(this.companyUserId).pipe(
                tap((image) => {
                    runInAction(() => {
                        if (image) {
                            this.picture = image
                        }
                    })
                }),
            )
        } else {
            return of()
        }
    }

    @action
    public setPicture(image: string) {
        if (this.user) {
            this.picture = image
            this.storeUserPicture(this.user._id, image)
        }
    }

    @action
    public getUserPicture(userId: string): Observable<string | null> {
        const userPictureInformation =
            hydrateFromStorage<UserPictureInformation>(
                USER_PICTURE_INFORMATION_PREFIX_KEY + userId,
            )

        const model = assign(
            new UserPictureInformation(),
            userPictureInformation,
        )

        if (userPictureInformation && validateSync(model).length === 0) {
            const now = new Date()
            const expiresAt = new Date(model.expires)

            if (now <= expiresAt) {
                return of(userPictureInformation.data)
            }
        }

        return request<never, Blob>(
            `/company-user/${userId}/${stores.company.activeCompanyId}/picture`,
            HttpMethod.GET,
            {
                query: { size: 'thumbnail' },
                responseType: 'blob',
            },
        ).pipe(
            rxmap((response) => response.data),
            switchMap((blob) => toBase64(blob!)),
            tap((image) => {
                if (image) {
                    let usedImage: string | null = image
                    if (image === 'data:application/json;base64,e30=') {
                        usedImage = null
                    }
                    runInAction(() => this.storeUserPicture(userId, usedImage))
                }
            }),
        )
    }

    @action
    public storeUserPicture(userId: string, image: string | null) {
        dehydrateToStorage(USER_PICTURE_INFORMATION_PREFIX_KEY + userId, {
            data: image,
            expires: addHours(new Date(), HOURS_TO_CACHE_LOGO),
        })
    }

    @action
    public getUser() {
        this.ready = false

        return request<never, CompanyUser>('/me/company-user', HttpMethod.GET, {
            silentErrors: true,
        }).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.setUser(response.data.user)
                        this.setCompanyAssociations(
                            response.data.companyAssociations,
                        )
                    } else if (
                        [HttpStatusCode.FORBIDDEN].includes(response.status)
                    ) {
                        stores.auth.signOut()
                    }
                    this.ready = true
                })
            }),
        )
    }

    @action
    public updateCompanyUser(userId: string, user: UpdateUserModel) {
        this.ready = false
        return request(`/company-user/${userId}`, HttpMethod.PATCH, {
            body: { user: user.getRequestBody() },
            successMessage: t('messages.updatedCoachee') as string,
        }).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok && this.user && user.appLanguage) {
                        this.user.appLanguage = user.appLanguage
                            ?.value as string
                        this.setUser(this.user)
                        this.ready = true
                    }
                })
            }),
        )
    }

    @action
    public getConfidentialInsightsFiles(id: string) {
        return request<never, FileDownload[]>(
            `/coachee/${id}/insight-files?companyId=${stores.company.activeCompanyId}`,
            HttpMethod.GET,
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.insightFiles = response.data
                    }
                })
            }),
        )
    }

    @action
    public deleteConfidentialInsightsFile(coacheeId: string, fileId: string) {
        return request<never, never>(
            `/coachee/${coacheeId}/${fileId}/insight-files?companyId=${stores.company.activeCompanyId}`,
            HttpMethod.DELETE,
        ).pipe(
            tap((response) => {
                runInAction(() => {})
            }),
        )
    }

    _capitalize = (string: string | undefined): string => {
        return string ? string.charAt(0).toUpperCase() + string.slice(1) : ''
    }

    @action
    public trackEvent(eventName: string, eventData: any) {
        const accessToken = stores.auth.authResponse?.accessToken
        if (accessToken) {
            eventData.event = eventName
            eventData.platform = 'Web'
            eventData.$browser = this._capitalize(browser?.name.toString())
            eventData.$browser_version = browser?.version
            eventData.$current_url = window.location.href
            eventData.$os = this._capitalize(browser?.os?.toString())
            eventData.$screen_height = window.innerHeight
            eventData.$screen_width = window.innerWidth
            eventData.distinct_id = this.companyUserId
            eventData.company = this.activeAssociation?.company.label
            eventData.userLevel = this.activeAssociation?.level

            return request('/sama/mix', HttpMethod.POST, {
                body: eventData,
                silentErrors: true,
            }).pipe(
                tap((response) => {
                    runInAction(() => {})
                }),
            )
        } else {
            return of({})
        }
    }

    @action
    public reset(): void {
        this.user = null
        this.userAssociations = []
        removeFromStorage(
            USER_KEY,
            USER_ASSOCIATIONS_KEY,
            USER_ACTIVE_ASSOCIATION_KEY,
        )
    }
}
