import { Popover } from '@headlessui/react'
import { observer } from 'mobx-react'
import React, {
    useEffect,
    useState,
    useCallback,
    SyntheticEvent,
    useRef,
} from 'react'
import { useTranslation } from 'react-i18next'
import { FiltersButton } from '../../../../components/buttons'
import { useStores } from '../../../../util/stores'
import { DateFiltersSection } from './date-filters-section'
import { FiltersSection } from './filters-section'
import { classNames } from '../../../../util/misc'

export const SelectedFilters: React.FC = observer(() => {
    const { t } = useTranslation()
    const { company } = useStores()
    const [selectedFilterCategories, setSelectedFilterCategories] = useState(
        [] as Array<string>,
    )

    const categoryToTranslationKey = {
        areasOfCoaching: 'dashboard.areaOfCoaching.title',
        seniorityLevels: 'dashboard.seniorityLevel.title',
        functions: 'dashboard.function.title',
        userStatus: 'dashboard.userStatus.title',
        departments: 'dashboard.filters.department',
        coacheeGenders: 'dashboard.gender',
        date: 'dashboard.filters.dateRange',
    }

    const clearFilter = useCallback(
        (filterKey: string, e: SyntheticEvent) => {
            e.stopPropagation()
            e.preventDefault()
            company.clearFilterSection(filterKey)
        },
        [company],
    )

    useEffect(() => {
        const selectedCategories = []
        for (const key in company.selectedFilters) {
            const data =
                company.selectedFilters?.[
                    key as keyof typeof company.selectedFilters
                ] ?? []

            if (Array.isArray(data) && data.length > 0) {
                selectedCategories.push(key)
            }
        }
        if (company.selectedFilters.date.start) {
            selectedCategories.push('date')
        }

        setSelectedFilterCategories(selectedCategories)
    }, [company.selectedFilters])

    return (
        <>
            {selectedFilterCategories.map((category, index) => {
                return (
                    <span
                        key={index}
                        className={classNames(
                            'rounded-[100px] bg-[#F6F7F9]',
                            'p-[10px] flex items-stretch',
                        )}
                    >
                        <i className={classNames('mx-[5px]  pt-[3px]')}>
                            <img width="15" src="/img/icons/tick.svg" />
                        </i>
                        {t(
                            categoryToTranslationKey[
                                category as keyof typeof categoryToTranslationKey
                            ],
                        )}
                        <i
                            className={classNames('mx-[5px] pt-[3px]')}
                            onClick={(e) => clearFilter(category, e)}
                        >
                            <img width="15" src="/img/icons/green-cross.svg" />
                        </i>
                    </span>
                )
            })}
        </>
    )
})

export const Filters: React.FC = observer(() => {
    const { t } = useTranslation()
    const { company } = useStores()
    const ref = useRef<any>(null)
    const [isOpen, setPanelIsOpen] = useState(false)

    const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setPanelIsOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    }, [])

    return (
        <Popover className="relative" ref={ref}>
            <Popover.Button
                as={FiltersButton}
                onClick={() => setPanelIsOpen(!isOpen)}
                className={classNames(
                    company.filtersCount === 0 && 'btn-white',
                    company.filtersCount > 0 &&
                        'text-black bg-white active:bg-white',
                    'inline-flex items-center space-x-1 px-6',
                )}
            >
                {company.filtersCount === 0 && (
                    <img
                        src="/img/icons/filter.svg"
                        className="h-3"
                        alt="Filter"
                    />
                )}
                {company.filtersCount > 0 && (
                    <img
                        src="/img/icons/filter-applied.svg"
                        className="h-3"
                        alt="Filter"
                    />
                )}
                <span>{t('buttons.filters')}</span>
                {company.filtersCount > 0 && (
                    <span className="text-[9px]">({company.filtersCount})</span>
                )}

                <SelectedFilters />
            </Popover.Button>
            {isOpen && (
                <Popover.Panel
                    static
                    className="absolute overflow-hidden z-10 w-[200px] bg-white rounded-[.25rem] mt-4"
                    style={{
                        filter: `drop-shadow(0px 17px 80px rgba(16, 18, 58, 0.03)) drop-shadow(0px 11.0185px 46.8519px rgba(16, 18, 58, 0.0227778)) drop-shadow(0px 6.54815px 25.4815px rgba(16, 18, 58, 0.0182222)) drop-shadow(0px 3.4px 13px rgba(16, 18, 58, 0.015))
                    drop-shadow(0px 1.38519px 6.51852px rgba(16, 18, 58, 0.0117778)) drop-shadow(0px 0.314815px 3.14815px rgba(16, 18, 58, 0.00722222))`,
                    }}
                >
                    <div className="grid grid-cols-1 divide-y divide-white">
                        <div className="px-2.5 py-2 space-x-1">
                            <span className="text-[15px]">
                                {t('dashboard.filters.filters')}
                            </span>
                            <span className="text-[11px]">
                                ({company.filtersCount})
                            </span>
                        </div>
                        <FiltersSection
                            filterKey="areasOfCoaching"
                            titleKey="dashboard.areaOfCoaching.title"
                            staticsKey="competencies"
                            type="array"
                        />
                        <FiltersSection
                            filterKey="seniorityLevels"
                            titleKey="dashboard.seniorityLevel.title"
                            staticsKey="positions"
                            type="array"
                        />
                        <FiltersSection
                            filterKey="functions"
                            titleKey="dashboard.function.title"
                            staticsKey="functions"
                            type="array"
                        />
                        {company.availableFilters?.departments &&
                            company.availableFilters?.departments.length >
                                0 && (
                                <FiltersSection
                                    filterKey="departments"
                                    titleKey="dashboard.filters.department"
                                    type="array"
                                />
                            )}
                        <FiltersSection
                            filterKey="userStatus"
                            titleKey="dashboard.userStatus.title"
                            staticsKey="userStatus"
                            type="array"
                        />
                        <FiltersSection
                            filterKey="coacheeGenders"
                            titleKey="dashboard.gender"
                            staticsKey="coacheeGender"
                            type="array"
                        />
                        <DateFiltersSection />
                    </div>
                </Popover.Panel>
            )}
        </Popover>
    )
})
