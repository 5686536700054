import { Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Subscription } from 'rxjs'
import { PrimaryButton } from '../../../components/buttons'
import { SelectInput, TextareaInput } from '../../../components/input'
import { CenterModal, ModalProps } from '../../../components/modals'
import { RemoveAccessModel } from '../../../models/request'
import { CompanyUser } from '../../../models/response'
import { cancelSubscriptions, Option } from '../../../util/misc'
import { useStores } from '../../../util/stores'
import { validateModel } from '../../../util/validation'

interface Props extends ModalProps {
    companyUser: CompanyUser
    option: Option
}

export const ChangeLevelsModal: React.FC<Props> = observer(
    ({ isOpen, setIsOpen, companyUser, option }) => {
        const { t } = useTranslation()
        const { company } = useStores()
        const subscriptions: Subscription[] = []

        const displayValue = option.display

        const close = useCallback(() => {
            setIsOpen(false)
        }, [])

        const changeAccess = useCallback(() => {
            const subscription = company
                .updateCompanyUserAccess(
                    option.value as string,
                    companyUser._id,
                )
                .subscribe({
                    next(response) {
                        if (response.ok) {
                            close()
                        }
                    },
                })
            subscriptions.push(subscription)
        }, [close, companyUser, option])

        useEffect(() => {
            return () => {
                cancelSubscriptions(subscriptions)
            }
        }, [])

        return (
            <CenterModal isOpen={isOpen} setIsOpen={setIsOpen}>
                <header className="flex flex-col space-y-3 mb-6 mx-9">
                    <span className="text-2xl">
                        <Trans
                            t={t}
                            i18nKey="modals.changeLevel.heading"
                            tOptions={{ level: displayValue }}
                        >
                            <b />
                        </Trans>
                    </span>
                    <span className="text-xs text-[#56576A]">
                        {t('modals.changeLevel.subText')}
                    </span>
                </header>

                <footer className="px-9 py-5 flex space-x-8">
                    <PrimaryButton
                        trackingId="changeAdminAccessLevel"
                        trackingAction="cancel"
                        type="button"
                        className="flex-1 btn-open"
                        onClick={close}
                    >
                        <span>{t('buttons.cancel')}</span>
                    </PrimaryButton>
                    <PrimaryButton
                        trackingId="changeAdminAccessLevel"
                        trackingAction="save"
                        onClick={changeAccess}
                        type="button"
                        className="flex-1 btn-[#3095aa]"
                    >
                        <span>{t('buttons.update')}</span>
                    </PrimaryButton>
                </footer>
            </CenterModal>
        )
    },
)
