import { t } from 'i18next'
import { memoize } from 'lodash'
import { Option } from '../misc'

export enum AccountLevel {
    VIEWER = 'viewer',
    MANAGER = 'manager',
    ADMIN = 'admin',
    SUPER_ADMIN = 'super-admin',
    SAMA_ADMIN = 'sama-admin',
}

export const accountLevels = [
    AccountLevel.VIEWER,
    // AccountLevel.MANAGER,
    AccountLevel.ADMIN,
    AccountLevel.SUPER_ADMIN,
    AccountLevel.SAMA_ADMIN,
]

export const accountLevelAccess = {
    [AccountLevel.VIEWER]: [AccountLevel.VIEWER],
    [AccountLevel.MANAGER]: [AccountLevel.VIEWER, AccountLevel.MANAGER],
    [AccountLevel.ADMIN]: [
        AccountLevel.VIEWER,
        AccountLevel.MANAGER,
        AccountLevel.ADMIN,
    ],
    [AccountLevel.SUPER_ADMIN]: [
        AccountLevel.VIEWER,
        AccountLevel.MANAGER,
        AccountLevel.ADMIN,
        AccountLevel.SUPER_ADMIN,
    ],
    [AccountLevel.SAMA_ADMIN]: [
        AccountLevel.VIEWER,
        AccountLevel.MANAGER,
        AccountLevel.ADMIN,
        AccountLevel.SUPER_ADMIN,
        AccountLevel.SAMA_ADMIN,
    ],
}

export const accountLevelOptions = memoize(
    (level?: AccountLevel | null): Option[] =>
        accountLevels.map((accountLevel) => ({
            value: accountLevel,
            display: t(`levels.${accountLevel}`),
            disabled: level
                ? !accountLevelAccess[level].includes(accountLevel)
                : undefined,
        })),
)
