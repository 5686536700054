import { useField } from 'formik'
import { HTMLMotionProps, motion } from 'framer-motion'
import React from 'react'
import { classNames } from '../../util/misc'
import { FormValidationMessage } from '../layout'

interface Props extends HTMLMotionProps<'textarea'> {
    name: string
    label?: string
    labelBig?: boolean
    canResize?: boolean
}

export const TextareaInput: React.FC<Props> = ({
    name,
    label,
    labelBig,
    className,
    canResize = false,
    ...props
}) => {
    const [field, meta] = useField(name)

    return (
        <div className="flex flex-col">
            {label && (
                <label
                    htmlFor={name}
                    className={classNames(
                        'block mb-2',
                        labelBig ? 'text-[15px] ' : 'text-[11px] ',
                    )}
                >
                    {label}
                </label>
            )}
            <motion.textarea
                className={classNames(
                    'bg-[#f6f7f9] h-[4.5rem]',
                    canResize ? '' : 'resize-none',
                    'rounded-[0.25rem]',
                    'px-4 py-2',
                    'text-[13px]',
                    className,
                )}
                {...props}
                {...field}
            />
            {meta.touched && meta.error && (
                <div className="mt-[10px]">
                    <FormValidationMessage message={meta.error[0]} />
                </div>
            )}
        </div>
    )
}
