import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import {
    ValueType,
    NameType,
    Formatter,
    // @ts-ignore
} from 'recharts/lib/component/DefaultTooltipContent'
import { RoundedBar } from '../../../components/charts'

interface Data {
    duration: number
    value: number
}

interface Props {
    data: Data[]
}

const colors = ['#3095AA', '#FFE3DE']

export const SessionDurationChart: React.FC<Props> = ({ data }) => {
    const { t } = useTranslation()

    const tickFormatter = useMemo(
        () => (value: string) =>
            t(`dashboard.sessionDuration.minutes`, { number: value }),
        [t],
    )

    const formatter: Formatter<NameType, NameType> = useMemo(
        () => (value: ValueType, name: NameType) =>
            [value as NameType, t(`extras.value`) as NameType],
        [t],
    )

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                data={data.sort((a, b) => b.duration - a.duration)}
                layout="vertical"
                margin={{ left: -15, right: 20 }}
            >
                <CartesianGrid stroke="#F0F0F0" />
                <XAxis
                    type="number"
                    tickCount={10}
                    strokeWidth={1}
                    fontSize={12}
                    tick={{ fill: '#10123A' }}
                    tickLine={false}
                    axisLine={{ stroke: '#F0F0F0' }}
                    interval={0}
                    allowDecimals={false}
                />
                <YAxis
                    type="category"
                    dataKey="duration"
                    padding={{ top: 50, bottom: 50 }}
                    strokeWidth={1}
                    fontSize={12}
                    tick={{ fill: '#10123A', width: 100 }}
                    width={80}
                    tickFormatter={tickFormatter}
                    tickLine={false}
                    axisLine={{ stroke: '#F0F0F0' }}
                />
                <Tooltip
                    wrapperStyle={{ outline: 'none' }}
                    cursor={{ fill: '#EDEDED' }}
                    labelFormatter={tickFormatter}
                    formatter={formatter}
                    itemStyle={{
                        fontSize: '12px',
                        marginTop: -8,
                        color: 'white',
                    }}
                    labelStyle={{
                        color: 'white',
                        marginBottom: 8,
                    }}
                    contentStyle={{
                        background: '#080922',
                        padding: 8,
                        borderRadius: 6,
                        border: 'none',
                    }}
                />
                <Bar
                    dataKey="value"
                    stackId="a"
                    shape={<RoundedBar layout="vertical" />}
                    barSize={46}
                    minPointSize={1}
                >
                    {data.map((_, i) => (
                        <Cell key={i} fill={colors[i]} />
                    ))}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    )
}
