import i18next from 'i18next'
import { format, utcToZonedTime } from 'date-fns-tz'
import frLocale from 'date-fns/locale/fr'
import enLocale from 'date-fns/locale/en-US'

const getLocale = (lang: string): Locale => {
    if (lang === 'fr') {
        return frLocale
    } else {
        return enLocale
    }
}

export const getTimestamp = (date?: Date): number => {
    const milliseconds = date ? date.getTime() : Date.now()
    return Math.floor(milliseconds / 1000)
}

export const formatTime = (timezone: string) => {
    const utc = new Date().toUTCString()
    const usersTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const date = utcToZonedTime(new Date(utc), timezone)
    let timeFormat = 'HH:mm'
    if (usersTimeZone.includes('America')) {
        timeFormat = 'p'
    }
    return format(date, timeFormat, {
        locale: getLocale(i18next.language),
    })
}

export const formatDate = (
    date: Date | string | number,
    dateFormat: string = 'MMMM d, yyyy',
) => {
    return format(new Date(date), dateFormat, {
        locale: getLocale(i18next.language),
    })
}

export const formatMonthYear = (date: Date | string | number) => {
    return formatDate(date, 'MMMM, yyyy')
}

export const formatShortMonthYear = (date: Date | string | number) => {
    return formatDate(date, 'MMM, yyyy')
}

export const formatMonthDayYear = (date: Date | string | number) => {
    return formatDate(date, 'MMM, d, yyyy')
}

export const formatMonthYearByUTC = (date: Date | string | number) => {
    return format(new Date(date), 'MMMM, yyyy', {
        timeZone: 'UTC',
        locale: getLocale(i18next.language),
    })
}

export const getUTCMonthNumber = (date: Date | string | number) => {
    return parseInt(
        format(new Date(date), 'M', {
            timeZone: 'UTC',
        }),
    )
}

export const formatQuarter = (date: Date | string | number) => {
    return 'Q' + formatDate(date, 'Q, yyyy')
}
