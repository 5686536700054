import { once } from 'lodash'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { MagicLoginModel } from '../../../models/request'
import { NotificationType, RouteLink } from '../../../util/constants'
import { stores, useStores } from '../../../util/stores'
import { AuthPageWrapper } from '../components'

export const MagicSignupPage: React.FC = observer(() => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [search] = useSearchParams()
    const token = search.get('token')
    const company = search.get('company')
    const { auth, notifications } = useStores()
    const [loading, setLoading] = useState(true)

    const logIn = useCallback(
        once(() => {
            if (!token || !company) {
                return navigate(RouteLink.INDEX, { replace: true })
            }

            auth.logIn(new MagicLoginModel(token, company)).subscribe({
                next(response) {
                    if (response.ok) {
                        if (!response?.data?.isPhoneNeeded) {
                            navigate(RouteLink.TWO_FA, {
                                replace: true,
                                state: {
                                    canSignUp: true,
                                },
                            })
                        } else {
                            navigate(RouteLink.SIGN_UP, {
                                replace: true,
                                state: {
                                    canSignUp: true,
                                },
                            })
                        }

                        return
                    }

                    notifications.createNotification(
                        NotificationType.ERROR,
                        t('messages.invalidToken'),
                        5 * 1000 /* 5 seconds */,
                    )
                    stores.reset()
                    navigate(RouteLink.LOG_IN, { replace: true })
                },
                complete() {
                    setLoading(false)
                },
            })
        }),
        [auth, token, company, t],
    )

    useEffect(() => {
        if (token && company) {
            logIn()
        }
    }, [])

    return token ? (
        <AuthPageWrapper>
            {loading && <span>{t('messages.loggingYouIn')}</span>}
        </AuthPageWrapper>
    ) : null
})

export default MagicSignupPage
