import { ResourceLanguage } from 'i18next'
import { Translation } from '../translation'

export const fr: Translation & ResourceLanguage = {
    pageTitles: {
        logIn: 'Connectez-vous à votre compte',
        setPhoneNumber: 'Numéro de téléphone mobile',
        magicLogin: 'Connexion magique',
        signUp: 'Inscrivez-vous',
        magicSignup: 'Inscription magique',
        twoFa: 'Authentification à deux facteurs',
        dashboard: 'Tableau de bord',
        account: 'Compte',
        faqs: 'Foire aux Questions',
        redirecting: 'Redirection...',
        pageNotFound: 'Page non trouvée',
        downloadPdf: 'Tableau de bord Sama',
    },
    titles: {
        logIn: 'Connexion',
        dashboard: 'Tableau de bord',
        companyInvitesYou:
            '{{company}} vous invite à vous connecter au tableau de bord Sama',
        setPhoneNumber: 'Votre numéro de téléphone',
        account: 'Compte',
        yourProfile: 'Afficher votre profile',
        faqs: 'FAQs',
    },
    fields: {
        email: {
            label: 'Adresse courriel',
            placeholder: 'Adresse courriel',
        },
        firstName: {
            label: 'Prénom',
            placeholder: 'Prénom',
        },
        lastName: {
            label: 'Nom de famille',
            placeholder: 'Nom de famille',
        },
        code: {
            label: 'Code de vérification',
            placeholder: 'Code de vérification',
        },
        phone: {
            label: 'Numéro de téléphone',
            placeholder: 'Numéro de téléphone',
            reason: "En entrant votre numéro de téléphone ici, vous acceptez de recevoir des messages SMS contenant des codes à usage unique à des fins d'authentification.",
        },
        reason: {
            label: 'Veuillez spécifier une raison',
            placeholder: 'Choisir une raison',
        },
        reasonText: {
            label: 'Raison',
            placeholder: 'Veuillez spécifier une raison ici',
        },
        level: {
            label: 'Niveau',
            placeholder: 'Choisir le niveau',
        },
        appLanguage: {
            label: 'Langue',
            placeholder: 'Choisir la langue',
        },
        departmentsAccess: {
            label: 'Accès départements',
            placeholder: 'Choisir le(s) département(s)',
        },
        country: {
            label: 'Pays',
            placeholder: 'Choisir le pays',
        },
        city: {
            label: 'Ville',
            placeholder: 'Ville',
        },
        timeZone: {
            label: 'Fuseau horaire',
            placeholder: 'Choisir le fuseau horaire',
        },
        editCiContent: {
            label: '',
            placeholder: 'Ajouter les informations confidentielles',
        },
        minsLimit: {
            label: "Limite d'heures",
            placeholder:
                "La limite est actuellement fixée au niveau de l'entreprise",
        },
        department: {
            label: 'Nom',
            placeholder: 'Nom du département',
        },
    },
    buttons: {
        sendVerificationCode: 'Envoyer courriel de vérification',
        verifyCode: 'Code de vérification',
        verifyPhoneNumber: 'Vérifier numéro',
        signIn: 'Connexion',
        signOut: 'Se déconnecter',
        resend2fa: 'Renvoyer le code',
        manageAccess: "Gérer l'accès",
        inviteUser: "Inviter l'utilisateur",
        inviteCoachee: 'Ajouter utilisateur',
        update: 'Mise à jour',
        cancel: 'Annuler',
        remove: 'Retirer',
        confirm: 'Confirmer',
        filters: 'Filtres',
        save: 'Enregistrer',
        edit: 'Modifier',
        exportToPdf: 'Exporter au format PDF',
        addUsers: 'Ajouter utilisateur(s)',
        editUserDetails: 'Modifier détails utilisateur(s)',
        deleteUser: "Supprimer l'utilisateur",
        requestChange: 'Requête de modification',
        upgradePlan: 'Surclasser votre contrat',
        sendNudge: 'Envoyer une notification',
        createDepartment: 'Créer un nouveau département',
        updateDepartment: 'Modifier département',
        saveConfidentialInfo: "Enregister l'information confidentielle",
        getStarted: 'Créer',
        downloadCsv: '',
    },
    messages: {
        emailSent:
            'Email envoyé. Vous ne le trouvez pas ? Veuillez vérifier votre dossier spam.',
        loggingYouIn: 'Nous vous connectons',
        invalidToken: "Le jeton n'est pas valide",
        pageNotFound: 'Page non trouvée',
        loading: 'Chargement',
        resend2fa: `Vous n'avez pas reçu de SMS ? Envoyer à nouveau (dans {{seconds}}s).`,
        accessRemoved: "L'accès a été supprimé pour le compte",
        accessUpdated: "Le niveau d'accès a été mis à jour pour le compte",
        departmentAccessUpdated:
            'Les départements ont été mis à jour pour le compte',
        userInviteSent: "Invitation envoyée à l'utilisateur",
        insufficientDataToShow: 'Moins de 5 utilisateurs dans cette catégorie',
        updatedCoachee: 'Informations mises à jour',
        updatedCompanyInsight:
            "Informations confidentielles d'entreprise mises à jour",
        deletedCoachee: 'Utilisateur désactivé',
        reportBeingGenerated:
            'Le rapport est en cours de génération et sera envoyé à votre courriel dans les prochaines secondes',
        supportEmailSubject: 'Support Tableau de bord',
        feedbackEmailSubject: 'Feedback Tableau de bord',
        inviteReSent: "Courriel d'invitation envoyé",
        userConfidentialInfo:
            "Les informations de l'utilisateur seront partagées avec le coach.",
        companyConfidentialInfo:
            "Les informations sur l'entreprise seront partagées avec le coach.",
        emailSupport:
            'Merci pour votre demande de surclassement. Un représentant de Sama vous contactera sous peu.',
        editRequestSent: 'La demande a été envoyée à Sama pour traitement',
        createDepartmentSent: 'Département crée',
        updateDepartmentSent: 'Département mis à jour',
        insufficentData: `Données insuffisantes en {{quarter}}`,
        contentCopied: '',
        account: {
            activeUsers: "Nombre d'utilisateurs actifs dans ce département",
        },
        languageChanged: 'La langue a été mise à jour',
        resetDates: 'Changer dates',
    },
    sidebar: {
        dashboardAccess: 'Accès tableau de bord',
        contactSupport: 'Contacter support',
        sendFeedback: 'Envoyez-nous votre feedback',
        you: 'vous',
    },
    modals: {
        uploadLogo: {
            heading: 'Télécharger logo',
            subText: "La taille de l'image doit être de 500 x 500 pixels.",
            browse: 'parcourir',
            dragAndDrop: "Insérer l'image, ou <0>{{browse}}</0>",
            supportedFileTypes: 'Prend en charge .jpg, .png ou .svg',
        },
        manageAccess: {
            heading: "Gérer l'accès",
            subText:
                'Afficher et gérer les utilisateurs qui ont accès au tableau de bord.',
            name: 'Nom',
            role: 'Role',
            createdAt: 'Joint le {{formattedDate}}',
            inviteSent: 'Invitation envoyée {{formattedDate}}',
            resendInvite: "Renvoyer l'invitation",
        },
        addCoachee: {
            heading: 'Ajouter utilisateur',
            subText:
                'Ajouter des utilisateurs à la plateforme Sama en incluant leur détails ci-dessous.',
            seatsLeft: 'licences restantes',
            noCoacheesRemaining:
                'Limite du nombre de licences atteinte. Pour ajouter des licences, prière de nous écrire à support@sama.io',
        },
        editCiContent: {
            heading:
                'Modifier les informations confidentielles de <0>{{name}}</0>',
            subText: 'Ce contenu sera partager avec leur coach',
        },
        editCoachee: {
            heading: '',
            dragAndDrop: 'Insérer le document, ou <0>{{browse}}</0>',
            supportedFileTypes: 'Prend en charge .docs et .pdf',
            areasOfCoaching: 'Domaine de coaching',
            seniority: 'Niveau de séniority',
            userConfidentialInfo: 'Informations confidentielles utilisateur',
            companyConfidentialInfo: "Information sur l'entreprise",
            uploadFile:
                "Télécharger des documents d'informations confidentielles sur l'utilisateur",
            coachingLanguages: 'Langue de coaching désirée',
            country: 'Pays',
            location: 'Ville',
            timeZone: 'Fuseau horaire',
            delete: 'Supprimer',
            fileDeleteTitle: 'Êtes-vous certain?',
            fileDeleteSubtext:
                'Cela ne peut pas être annulé une fois que vous avez confirmé',
            department: 'Département',
        },
        removeAccess: {
            heading: "Supprimer l'accès de <0>{{name}}</0>",
            subText:
                "Vous êtes sur le point de supprimer l'accès de cet utilisateur.",
        },
        upgradePlan: {
            heading: 'Surclasser votre contrat',
            subText:
                'Nous allons notifier votre représentant Sama qui vous contactera sous peu.',
        },
        inviteUser: {
            heading: 'Inviter utilisateur',
            subText:
                'Ajouter un utilisateur au tableau de bord en ajoutant ses détails ci-dessous.',
        },
        roles: {
            heading: 'Roles',
            subText: 'Voir les roles et accès au tableau de bord.',
            access: 'Accès',
            departmentInfo: 'Informations sur le département',
            specificKeyDates: 'Dates importantes',
            dataTable: 'Afficher tableau utilisateur',
            dashboard: 'Afficher graphiques du tableau de bord',
            exportPdf: 'Exporter les graphiques en document PDF',
            addRemoveUsers: 'Ajout/retirer utilisateur(s) Sama',
            inviteUsers: 'Inviter utilisateur(s) tableau de bord',
            viewDashboardUsers: 'Afficher utilisateur(s) tableau de bord',
        },
        deactivateCoachee: {
            heading: 'Supprimer utilisateur <0>{{name}}</0>',
            subText:
                "Toute séance future sera annulée et l'utilisateur sera déconnecté de l'application. L'utilisateur n'aura plus accès à l'application",
        },
        changeLevel: {
            heading: "Confirmer le changement de type d'administrateur",
            subText: '',
        },
        myProfile: {
            joinedContent: 'A joint {{formattedDate}}',
            localTime: '{{formattedTime}} heure locale',
            dashboardLanguage: { title: 'Langue du tableau de bord' },
        },
        requestChangesCoachee: {
            heading: "Requête de la modification des détails de l'utilisateur",
            subText:
                'Les modifications doivent être approuvées par votre représentant Sama.',
        },
        createDepartment: {
            heading: 'Créer départment',
        },
        updateDepartment: {
            heading: 'Modifier département',
        },
    },
    validation: {
        valuesIdentical: 'Aucune modification apportée',
        selectAnOption: 'Choisir une option',
        mustBeSet: 'Ajouter',
        invalidFormat: 'Format non valide',
        invalidPhone: 'Format requis: +15142359999',
        tooShort: 'Doit contenir plus de $constraint1 charactère(s)',
        tooLong: 'Doit contenir moins de $constraint1 charactère(s)',
        twoFaCode: {
            length: 'Doit avoir $constraint1 chiffre(s)',
            format: 'Ne doit inclure que des chiffres',
        },
        mustBeDivisible: 'Doit être un multiple de 25 minutes',
    },
    levels: {
        viewer: 'Basic',
        manager: 'Gestionnaire',
        admin: 'Admin',
        'super-admin': 'Super Admin',
        'sama-admin': 'Admin SAMA',
    },
    dashboard: {
        areaOfCoaching: {
            title: 'Domainde de coaching',
            subTitle: 'Données en temps réel.',
            lifeBalance: 'Équilibre de vie',
            engagement: 'Motivation',
            leadership: 'Leadership',
            performance: 'Performance',
            communication: 'Communication',
        },
        seniorityLevel: {
            title: 'Niveau de séniorité',
            subTitle: 'Données en temps réel.',
            executive: 'Direction / Exécutif',
            admin: 'Support et Opérations',
            associates: 'Analyste',
            senior: 'Professionnel senior',
            manager: 'Officier',
        },
        function: {
            title: 'Fonction',
            subTitle: 'Données en temps réel.',
        },
        userStatus: {
            title: 'Statut utilisateur',
            subTitle: '',
        },
        gender: 'Genre',
        appDownloads: 'Applications téléchargées',
        licencesActive: 'Licences allouées',
        coacheesConnectedToCoach: 'Utilisateurs avec un coach',
        completedFirstSession: 'Première séance complétée',
        bookedSessions: 'Séances réservées',
        totalGoalsCreated: "Nombre d'objectifs créés",
        averageGoalsPerUser: "Moyenne d'objectifs",
        totalTasksCreated: "Nombre d'exercices créés",
        tasksCompleted: "Nombre d'exercices complétés",
        sessionDuration: {
            title: 'Durée de séance',
            subTitle: 'Données en temps réel.',
            minutes: '{{number}} minutes',
        },
        totalAudioOrVideoSessions: {
            title: 'Total séances audio ou vidéo',
            subTitle: 'Données en temps réel.',
            lateCancel: 'Annulation(s) tardive',
            completed: 'Complété',
            user: 'Utilisateur {{number}}',
        },
        monthlyBookedSessions: {
            title: 'Séance réservées au mois',
            description: 'Inclut les séances réservées mais non encourues.',
            count: 'Séances',
        },
        monthlyActiveUsers: {
            title: 'Utilisateurs actifs mensuels (UAM)',
            description:
                "Utilisateurs actifs mensuels = nombre d'utilisateurs uniques qui ont utilisé la plateforme au cours des 30 derniers jours",
        },
        employeeEngagementTracker: {
            title: "Évaluation de l'engagement employé",
            description: 'Mis à jour chaque trimestre',
            adaptability: 'Adaptabilité',
            alignment: 'Alignment',
            motivation: 'Motivation',
            resilience: 'Résilience',
            selfEfficacy: 'Confiance en soi',
        },
        attritionRisk: {
            title: "Risque d'attrition",
            description: "Pourcentage d'utilisateurs",
            legend: {
                maybe: 'Possible',
                no: 'Ne songe pas à quitter',
                yes: 'Oui',
                unknown: 'Inconnu',
            },
        },
        coachRating: {
            title: 'Évaluations du coach',
            description: 'Mise à jour mensuelle.',
            starSingular: '{{number}} étoile',
            starPlural: '{{number}} étoiles',
        },
        totalCoachingHours: {
            title: 'Heures de coaching encourues',
            description: 'Inclut les communications par chat, audio et vidéo',
            hourSingular: '{{number}} heure',
            hourPlural: '{{number}} heures',
            totalAllocatedTimeUsed: 'Pourcentage utilisé',
        },
        filters: {
            clear: 'Effacer la sélection',
            filters: 'Filtres',
            dateRange: 'Plage de dates',
            department: 'Département',
        },
        logo: {
            uploadLogo: 'Télécharger le logo',
        },
        coacheeActivity: {
            title: 'Activité utilisateur',
            columns: {
                user: 'Utilisateur',
                status: 'Statut',
                areaOfCoaching: 'Domaine de coaching',
                nextSession: 'Prochaine séance',
                numberOfSessions: '# séances',
                numberOfLateCancels: '# annulations tardives',
            },
            user: 'Utilisateur {{number}}',
            status: {
                active: 'Actif',
                anonymized: 'Anonymisé',
                deactivated: 'Désactivé',
                disabled: 'Désactivé',
                locked: 'Accès retiré',
                paused: '',
            },
        },
    },
    account: {
        menuTabs: {
            userAccess: 'Accès utilisateur',
            billing: 'Facturation',
            departments: 'Départements',
            companyDetails: "Informations sur l'entreprise",
        },
        menuHeader: {
            currentPlan: 'Plan en cours',
            companyDetail: 'Détails',
            currentPlanSubText: 'Votre plan a {{seats}} licences non-allouées.',
        },
        companyDetail: {
            companyConfidentiaInfo:
                "Informations confidentielles sur l'entreprise",
        },
        departments: {
            activeUsers: 'Utilisateurs actifs:',
            createdAt: 'Date de création:',
            emptyDepartments: {
                title: 'Créer un dépatement',
                subtext:
                    'Créer le premier département en appuyant sur le bouton ci-dessous.',
            },
        },
        coacheeTable: {
            headers: {
                email: 'Courriel',
                firstName: 'Prénom',
                lastName: 'Nom de famille',
                status: 'Statut',
                loggedIn: 'Connecté',
                activatedDate: "Date d'activation",
                nextSessionDate: 'Date de la prochaine séance',
                country: 'Pays',
                minsLimit: 'Minutes maximum',
                minsRemaining: 'Minutes restantes',
                department: 'Département',
                location: '',
                onboarded: '',
                numberOfSessions: '',
                numberOfLateCancels: '',
                lastestSessionDate: 'n',
            },
            status: {
                active: 'Actif',
                anonymized: 'Anonymisé',
                disabled: 'Désactivé',
            },
        },
        activeCompanySince: 'Utilisateur depuis {{formattedDate}}',
    },
    extras: {
        other: 'Autre',
        value: 'Valeur',
        'n/a': 'N/A',
    },
    tabelList: {
        globalSearchPlaceHolder: 'Recherche par nom ou courriel',
        columnSearchPlaceHolder: 'Search ',
        pagination: {
            show: 'Par page',
        },
    },
    options: {
        selectAll: 'Sélectionner tout',
    },
}
