import { IsNotEmpty, IsOptional, MaxLength } from 'class-validator'
import { t } from 'i18next'
import { Validation } from '../../../util/constants'
import { BaseModel } from '../base.model'

export class EditConfidentialInsightsModel implements BaseModel {
    constructor(_id: string, confidentialInsight: string) {
        ;(this._id = _id), (this.confidentialInsight = confidentialInsight)
    }

    @IsNotEmpty({ message: t('validation.selectAnOption') as string })
    public _id: string = ''

    @IsOptional()
    @MaxLength(Validation.TEXT_MAX_LENGTH, {
        message: t('validation.tooLong') as string,
    })
    public confidentialInsight: string = ''

    public getRequestBody() {
        return {
            _id: this._id,
            confidentialInsight: this.confidentialInsight,
        }
    }
}
