import { Disclosure, Switch } from '@headlessui/react'
import { observer } from 'mobx-react'
import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FilterOptions } from '../../../../models/response'
import { getStaticsMap } from '../../../../stores'
import { classNames } from '../../../../util/misc'
import { useStores } from '../../../../util/stores'

const StyledSpan = styled.span`
    .tooltip-arrow {
        position: absolute;
        top: 98%;
        left: 15%;
        margin-left: -12px;
        width: 24px;
        height: 12px;
        overflow: hidden;
    }

    .tooltip-arrow:after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        background-color: #080922;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
    }
`

interface Props {
    filterKey: keyof FilterOptions
    titleKey: string
    staticsKey?: string
    type: 'array' | 'object'
    children?: React.ReactNode
}

export const Checkbox: React.FC<{
    checked: boolean
    label: string
    active: boolean
    onChange: () => void
    lineClassName?: string | null
}> = ({ checked, label, active, onChange, lineClassName }) => {
    const [isInactiveToolTip, showInactiveToolTip] = useState(false)
    const { t } = useTranslation()

    return (
        <Switch
            onMouseOver={() => {
                showInactiveToolTip(true)
            }}
            onMouseLeave={() => {
                showInactiveToolTip(false)
            }}
            checked={checked}
            onChange={onChange}
            className={classNames(
                'flex items-center space-x-2',
                lineClassName ? lineClassName : '',
            )}
        >
            <div
                className={classNames(
                    'h-3 w-3 border inline-flex justify-center items-center rounded-[2px]',
                    checked
                        ? 'bg-[#3095AA] border-[#3095AA]'
                        : 'bg-[#F6F6F7] border-[#10123A4D]/10',
                )}
            >
                {checked && (
                    <img
                        src="/img/icons/checkbox-check.svg"
                        alt="Check"
                        className="h-1"
                    />
                )}
            </div>
            {!active && isInactiveToolTip && (
                <StyledSpan
                    className={classNames(
                        'absolute',
                        'z-[200]',
                        'rounded-[5px]',
                        'mt-[-75px]',
                        'w-[130px]',
                        'h-[50px]',
                        'pt-[3px]',
                        'pl-[5px]',
                        'pr-[5px]',
                        'text-[#FFFFFF]',
                        'text-[13px]',
                        'bg-[#080922]',
                    )}
                >
                    {t('messages.insufficientDataToShow')}
                    <i className="tooltip-arrow"></i>
                </StyledSpan>
            )}

            <span
                className={classNames(
                    'text-[13px]',
                    !checked && 'text-[#56576A]',
                    !active && 'line-through',
                )}
            >
                {label}
            </span>
        </Switch>
    )
}

export const FiltersSection: React.FC<Props> = observer(
    ({ filterKey, titleKey, staticsKey, type, children }) => {
        const { t } = useTranslation()
        const { company, statics, user } = useStores()

        const staticsMap = useMemo(() => {
            if (staticsKey) return getStaticsMap((statics as any)[staticsKey])

            return null
        }, [])

        const isTouched = useMemo(
            () => company.touchedFilterSections.includes(filterKey),
            [company.selectedFilters],
        )

        const clearFilters = useCallback(
            (e: SyntheticEvent) => {
                e.stopPropagation()
                e.preventDefault()
                company.clearFilterSection(filterKey)
                user.trackEvent('dashboard_filters', {
                    action: 'all-cleared',
                }).subscribe()
            },
            [company],
        )

        const possibleValues = useMemo(
            () => company.availableFilters?.[filterKey] ?? [],
            [company.availableFilters],
        )

        const selected = useMemo(
            () => company.selectedFilters?.[filterKey] ?? [],
            [company.selectedFilters],
        )

        const trackFilterSelected = useCallback(
            (section: string, option: string, action: string) => {
                user.trackEvent('dashboard_filters', {
                    section: section,
                    option: option,
                    action: action,
                }).subscribe()
            },
            [],
        )

        return (
            <Disclosure defaultOpen={isTouched}>
                <Disclosure.Button
                    className={classNames(
                        'flex items-center justify-between bg-[#F6F7F9] p-2.5 cursor-pointer',
                    )}
                    as="div"
                >
                    {({ open }) => (
                        <>
                            <div className="flex items-center space-x-2">
                                {isTouched && (
                                    <img
                                        src="/img/icons/check-circle.svg"
                                        alt="Check mark"
                                        className="h-3.5"
                                    />
                                )}
                                <span className="text-[13px]">
                                    {t(titleKey)}
                                </span>
                            </div>
                            <div className="inline-flex space-x-1 items-center">
                                {isTouched && (
                                    <button
                                        className="inline-flex items-center"
                                        onClick={clearFilters}
                                    >
                                        <span className="text-[13px] text-accent">
                                            {t('dashboard.filters.clear')}
                                        </span>
                                    </button>
                                )}
                                <img
                                    src="/img/icons/chevron-down.svg"
                                    className={classNames(
                                        'w-[6px]',
                                        open && 'rotate-180',
                                    )}
                                    alt="chevron"
                                />
                            </div>
                        </>
                    )}
                </Disclosure.Button>
                <Disclosure.Panel className="p-2.5 max-h-[170px] overflow-y-auto">
                    {children}
                    {!children &&
                        type === 'array' &&
                        Array.isArray(possibleValues) && (
                            <div className="grid grid-cols-1 gap-2.5">
                                {possibleValues.map((value, i) => {
                                    const checked = (
                                        selected as string[]
                                    ).includes(value.name)

                                    const onChange = () => {
                                        if (value.enabled) {
                                            if (checked) {
                                                trackFilterSelected(
                                                    filterKey,
                                                    staticsMap?.[value.name] ??
                                                        '',
                                                    'remove',
                                                )
                                                company.removeFilter(
                                                    filterKey,
                                                    value.name,
                                                )
                                            } else {
                                                trackFilterSelected(
                                                    filterKey,
                                                    staticsMap?.[value.name] ??
                                                        '',
                                                    'add',
                                                )
                                                company.pushFilter(
                                                    filterKey,
                                                    value.name,
                                                )
                                            }
                                        }
                                    }

                                    return (
                                        <Checkbox
                                            key={i}
                                            checked={checked}
                                            onChange={onChange}
                                            active={value.enabled}
                                            label={
                                                staticsMap?.[value.name] ??
                                                value.name
                                            }
                                        />
                                    )
                                })}
                            </div>
                        )}
                </Disclosure.Panel>
            </Disclosure>
        )
    },
)
