import { ColumnFiltersState, SortingState } from '@tanstack/table-core'
import { IsISO8601, IsNotEmpty, validateSync } from 'class-validator'
import { addHours } from 'date-fns'
import { t } from 'i18next'
import {
    assign,
    get,
    isMatch,
    map,
    pick,
    set,
    setWith,
    size,
    uniq,
    values,
} from 'lodash'
import { action, computed, makeAutoObservable, runInAction } from 'mobx'
import { map as rxmap, Observable, of, switchMap, tap } from 'rxjs'
import {
    DeactivateCoacheeModel,
    EditCoacheeModel,
    InviteCoacheeModel,
    InviteUserModel,
    RemoveAccessModel,
    RequestCoacheeUpdateModel,
} from '../models/request'
import { EditConfidentialInsightsModel } from '../models/request/coachee/edit-confidential-insights.model'
import { DepartmentModel } from '../models/request/department/department.model'
import {
    Coachee,
    CoachingHours,
    Company,
    CompanyAssociationFull,
    CumulativeAggregate,
    Department,
    FileDownload,
    FilterOptions,
    MonthlyAggregate,
    MonthlyAggregateResponse,
    QuarterlyAggregate,
} from '../models/response'
import { AccountLevel, HttpMethod, NotificationType } from '../util/constants'
import {
    dehydrateToStorage,
    getUTCMonthNumber,
    hydrateFromStorage,
    Option,
    removeFromStorage,
    Resettable,
} from '../util/misc'
import { toBase64 } from '../util/misc/to-base-64'
import { request } from '../util/request'
import { stores } from '../util/stores'

const DEFAULT_FILTERS: FilterOptions = {
    areasOfCoaching: [],
    seniorityLevels: [],
    functions: [],
    userStatus: [],
    coacheeGenders: [],
    departments: [],
    date: {},
}

class CacheInformation {
    @IsNotEmpty()
    public data!: string

    @IsISO8601()
    public expires!: string
}

class SortCacheInformation {
    @IsNotEmpty()
    public data!: SortingState

    @IsISO8601()
    public expires!: string
}

class FilterCacheInformation {
    @IsNotEmpty()
    public data!: ColumnFiltersState

    @IsISO8601()
    public expires!: string
}

const COMPANY_PICTURE_INFORMATION_PREFIX_KEY =
    'SAMA:COMPANY-PICTURE-INFORMATION/'
const COMPANY_USER_PICTURE_INFORMATION_PREFIX_KEY =
    'SAMA:COMPANY-USER-PICTURE-INFORMATION/'
const HOURS_TO_CACHE_LOGO = 10
const HOURS_TO_CACHE_COMPANY_USER_IMG = 4
const COACHEE_PICTURE_INFORMATION_PREFIX_KEY =
    'SAMA:COACHEE-PICTURE-INFORMATION/'

const COMPANY_TABLE_SORT_PREFIX_KEY = 'SAMA:COMPANY-TABLE-SORTS/'
const COMPANY_TABLE_SORT_HOURS = 4
const COMPANY_TABLE_FILTER_PREFIX_KEY = 'SAMA:COMPANY-TABLE-FILTERS/'
const COMPANY_TABLE_FILTER_HOURS = 4

export class CompanyStore implements Resettable {
    public companyAssociations: CompanyAssociationFull[] = []
    public monthlyAggregates: MonthlyAggregate[] = []
    public quarterlyAggregates: QuarterlyAggregate[] = []
    public hiddenQuarterlyAggregates: string[] = []
    public cumulativeAggregate: CumulativeAggregate | null = null
    public coachingHours: CoachingHours | null = null
    public selectedFilters: FilterOptions = DEFAULT_FILTERS
    public availableFilters: FilterOptions | null = null
    public activeCompanyPicture: string | null = null
    public coachees: Coachee[] = []
    public departments: Department[] = []
    public departmentOptions: Option[] = []
    public exportDisabled: boolean = false
    public company_user_images_updated: boolean = false
    public companyInsightFiles: FileDownload[] | null = null

    @computed
    public get touchedFilterSections() {
        const keys = Object.keys(
            this.selectedFilters,
        ) as (keyof FilterOptions)[]

        return keys.filter(
            (key) => !isMatch(DEFAULT_FILTERS[key], this.selectedFilters[key]),
        )
    }

    @computed
    public get filtersCount() {
        let count = values(this.selectedFilters).reduce((acc, value, key) => {
            return acc + size(value)
        }, 0)

        if (this.selectedFilters.date.start) {
            count = count - 1
        }
        return count
    }

    @computed
    public get companies(): Company[] {
        return map(stores.user.userAssociations, 'company')
    }

    @computed
    public get activeCompany(): Company | null {
        return stores.user.activeAssociation?.company ?? null
    }

    @computed
    public get numOfActiveCoachees(): number {
        return (
            this.coachees.filter((coachee) => coachee.active === true).length ??
            0
        )
    }

    @computed
    public get activeCoachees(): Coachee[] {
        return this.coachees.filter((coachee) => coachee.active === true)
    }

    @computed
    public get anyUserIsViewer(): boolean {
        return (
            this.companyAssociations.filter(
                (ca) => ca.level === AccountLevel.VIEWER,
            ).length > 0
        )
    }

    @computed
    public get numOfActiveLicences(): Number {
        const licences = this.activeCompany?.currentContract?.licences ?? 0
        return licences - this.numOfActiveCoachees
    }

    @computed
    public get chartOptions(): Required<Company['chartOptions']> | null {
        return this.activeCompany?.chartOptions ?? null
    }

    @computed
    public get coachingLanguages(): Array<string> | null {
        return this.activeCompany?.coachingLanguages ?? null
    }

    @computed
    public get getActiveLanguages(): Array<Option> | null {
        let supportedLanguages: Option[] | null = null
        if (this.coachingLanguages && this.coachingLanguages?.length >= 2) {
            supportedLanguages = stores.statics.supportedLanguages.map(
                (language): Option => ({
                    value: language.key,
                    display: language.name,
                }),
            )
            supportedLanguages.filter((value) => {
                return this.coachingLanguages?.includes(value.value as string)
            })
        }
        return supportedLanguages
    }

    @computed
    public get activeCompanyId(): string | null {
        return this.activeCompany?._id ?? null
    }

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
    }

    @action
    public sortCompanyAssociations(): void {
        if (this.companyAssociations.length > 0) {
            this.companyAssociations.sort((a, b) => {
                return a.companyUser.user.firstName.localeCompare(
                    b.companyUser.user.firstName,
                )
            })
            const loggedInUser = (element: CompanyAssociationFull) =>
                element.companyUser._id === stores.user.companyUserId
            const index = this.companyAssociations.findIndex(loggedInUser)
            const loggedIn = this.companyAssociations.splice(index, 1)
            this.companyAssociations.unshift(loggedIn[0])
        }
    }

    @action
    public getCompanyUsersProfileImages(): void {
        this.companyAssociations.forEach((assotiation) => {
            if (assotiation.companyUser.profilePicture) {
                this.getUserPicture(assotiation.companyUser._id)
                    .pipe(
                        tap((image) => {
                            runInAction(() => {
                                assotiation.companyUser.profilePicture = image
                                this.setCompanyUserImagesUpdated(true)
                            })
                        }),
                    )
                    .subscribe()
            }
        })
    }

    @action
    public setCompanyAssociations(
        companyAssociations: CompanyAssociationFull[],
    ) {
        this.companyAssociations = companyAssociations
        this.getCompanyUsersProfileImages()
        this.sortCompanyAssociations()
    }

    @action
    public setCompanyUserImagesUpdated(updated: boolean) {
        this.company_user_images_updated = updated
    }

    @action
    public setCompanyCoachees(coachees: Coachee[]) {
        this.coachees = coachees.sort((a: Coachee, b: Coachee) => {
            return a.firstName > b.firstName ||
                (a.firstName == b.firstName && a.lastName > b.lastName)
                ? 1
                : -1
        })
    }

    @action
    public setCompanyDepartments(departments: Department[]) {
        this.departments = departments
    }

    @action
    public getCompanyDepartments() {
        return this.departments.map(
            (department): Option => ({
                value: department._id,
                display: department.name,
            }),
        )
    }

    @action
    public pushFilter(key: string, value: string) {
        this.exportDisabled = false
        ;(this.selectedFilters as any)[key] = uniq(
            (this.selectedFilters as any)[key].concat(value),
        )

        this.selectedFilters = {
            ...this.selectedFilters,
        }
    }

    @action
    public removeFilter(key: string, value: any) {
        this.exportDisabled = false
        ;(this.selectedFilters as any)[key]?.remove?.(value)

        this.selectedFilters = {
            ...this.selectedFilters,
        }
    }

    @action
    public setFilter(key: string[], value: any) {
        this.exportDisabled = false
        setWith(this.selectedFilters, key, value, Object)

        this.selectedFilters = {
            ...this.selectedFilters,
        }
    }

    @action
    public clearFilterSection(key: string) {
        this.exportDisabled = false

        if (Array.isArray(get(this.selectedFilters, [key]))) {
            set(this.selectedFilters, [key], [])
        } else {
            set(this.selectedFilters, [key], {})
        }

        this.selectedFilters = {
            ...this.selectedFilters,
        }
    }

    @action
    public resetFilters() {
        this.exportDisabled = false
        this.selectedFilters = {
            ...DEFAULT_FILTERS,
        }
    }

    @action
    public getCompanyPicture(companyId: string): Observable<string | null> {
        const companyPictureInformation = hydrateFromStorage<CacheInformation>(
            COMPANY_PICTURE_INFORMATION_PREFIX_KEY + companyId,
        )

        const model = assign(new CacheInformation(), companyPictureInformation)

        if (companyPictureInformation && validateSync(model).length === 0) {
            const now = new Date()
            const expiresAt = new Date(model.expires)

            if (now <= expiresAt) {
                return of(companyPictureInformation.data)
            }
        }

        return request<never, Blob>(
            `/company/${companyId}/picture`,
            HttpMethod.GET,
            { responseType: 'blob' },
        ).pipe(
            rxmap((response) => response.data),
            switchMap((blob) => toBase64(blob!)),
            tap((image) => {
                if (image) {
                    runInAction(() =>
                        this.storeCompanyPicture(companyId, image),
                    )
                }
            }),
        )
    }

    @action
    public getActiveCompanyPicture() {
        if (!this.activeCompanyId) {
            this.activeCompanyPicture = null
            return of()
        }

        return this.getCompanyPicture(this.activeCompanyId).pipe(
            tap((image) => {
                runInAction(() => {
                    if (image) {
                        this.activeCompanyPicture = image
                    }
                })
            }),
        )
    }

    @action
    public setActiveCompanyPicture(image: string) {
        this.activeCompanyPicture = image
        this.storeCompanyPicture(this.activeCompanyId!, image)
    }

    @action
    public storeCompanyPicture(companyId: string, image: string) {
        dehydrateToStorage(COMPANY_PICTURE_INFORMATION_PREFIX_KEY + companyId, {
            data: image,
            expires: addHours(new Date(), HOURS_TO_CACHE_LOGO),
        })
    }

    @action
    public getCoacheePicture(coacheeId: string): Observable<string | null> {
        const coacheePictureInformation = hydrateFromStorage<CacheInformation>(
            COACHEE_PICTURE_INFORMATION_PREFIX_KEY + coacheeId,
        )

        const model = assign(new CacheInformation(), coacheePictureInformation)

        if (coacheePictureInformation && validateSync(model).length === 0) {
            const now = new Date()
            const expiresAt = new Date(model.expires)

            if (now <= expiresAt) {
                return of(coacheePictureInformation.data)
            }
        }

        return request<never, Blob>(
            `/coachee/${coacheeId}/picture`,
            HttpMethod.GET,
            {
                query: { size: 'thumbnail' },
                responseType: 'blob',
            },
        ).pipe(
            rxmap((response) => response.data),
            switchMap((blob) => toBase64(blob!)),
            tap((image) => {
                if (image) {
                    runInAction(() =>
                        this.storeCoacheePicture(coacheeId, image),
                    )
                }
            }),
        )
    }

    @action
    public storeCoacheePicture(coacheeId: string, image: string) {
        dehydrateToStorage(COACHEE_PICTURE_INFORMATION_PREFIX_KEY + coacheeId, {
            data: image,
            expires: addHours(new Date(), HOURS_TO_CACHE_LOGO),
        })
    }

    @action
    public listCompanyUsers() {
        return request<never, CompanyAssociationFull[]>(
            `/company-user/associations/${this.activeCompanyId}`,
            HttpMethod.GET,
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.setCompanyAssociations(
                            response.data.filter(
                                (association) => !!association.companyUser.user,
                            ),
                        )
                    }
                })
            }),
        )
    }

    @action
    public getUserPicture(userId: string): Observable<string | null> {
        const userPictureInformation = hydrateFromStorage<CacheInformation>(
            COMPANY_USER_PICTURE_INFORMATION_PREFIX_KEY + userId,
        )

        const model = assign(new CacheInformation(), userPictureInformation)

        if (userPictureInformation && validateSync(model).length === 0) {
            const now = new Date()
            const expiresAt = new Date(model.expires)

            if (now <= expiresAt) {
                return of(userPictureInformation.data)
            }
        }
        return request<never, Blob>(
            `/company-user/${userId}/${stores.company.activeCompanyId}/picture`,
            HttpMethod.GET,
            {
                query: { size: 'thumbnail' },
                responseType: 'blob',
            },
        ).pipe(
            rxmap((response) => response.data),
            switchMap((blob) => toBase64(blob!)),
            tap((image) => {
                if (image) {
                    runInAction(() => this.storeUserPicture(userId, image))
                }
            }),
        )
    }

    @action
    public storeUserPicture(userId: string, image: string) {
        dehydrateToStorage(
            COMPANY_USER_PICTURE_INFORMATION_PREFIX_KEY + userId,
            {
                data: image,
                expires: addHours(new Date(), HOURS_TO_CACHE_COMPANY_USER_IMG),
            },
        )
    }

    @action
    public storeTableSort(tableName: string, values: SortingState) {
        dehydrateToStorage(
            COMPANY_TABLE_SORT_PREFIX_KEY +
                tableName +
                '/' +
                this.activeCompanyId,
            {
                data: values,
                expires: addHours(new Date(), COMPANY_TABLE_SORT_HOURS),
            },
        )
    }

    @action
    public getTableSort(tableName: string) {
        const sortInformation = hydrateFromStorage<SortCacheInformation>(
            COMPANY_TABLE_SORT_PREFIX_KEY +
                tableName +
                '/' +
                this.activeCompanyId,
        )

        if (sortInformation) {
            const now = new Date()
            const expiresAt = new Date(sortInformation.expires)

            if (now <= expiresAt) {
                return sortInformation.data
            } else {
                removeFromStorage(
                    COMPANY_TABLE_SORT_PREFIX_KEY +
                        tableName +
                        '/' +
                        this.activeCompanyId,
                )
            }
        }
    }

    @action
    public storeTableFilters(tableName: string, values: ColumnFiltersState) {
        dehydrateToStorage(
            COMPANY_TABLE_FILTER_PREFIX_KEY +
                tableName +
                '/' +
                this.activeCompanyId,
            {
                data: values,
                expires: addHours(new Date(), COMPANY_TABLE_FILTER_HOURS),
            },
        )
    }

    @action
    public getTableFilters(tableName: string) {
        const filterInformation = hydrateFromStorage<FilterCacheInformation>(
            COMPANY_TABLE_FILTER_PREFIX_KEY +
                tableName +
                '/' +
                this.activeCompanyId,
        )
        if (filterInformation) {
            const now = new Date()
            const expiresAt = new Date(filterInformation.expires)

            if (now <= expiresAt) {
                return filterInformation.data
            } else {
                removeFromStorage(
                    COMPANY_TABLE_SORT_PREFIX_KEY +
                        tableName +
                        '/' +
                        this.activeCompanyId,
                )
            }
        }
    }

    @action
    public getCoachees() {
        if (!this.activeCompanyId) {
            this.activeCompanyPicture = null
            return of()
        }

        return this.listCompanyCoachees().pipe(
            tap((data) => {
                runInAction(() => {})
            }),
        )
    }

    @action
    public listCompanyCoachees() {
        return request<never, Coachee[]>(
            `/company/${this.activeCompanyId}/coachees`,
            HttpMethod.GET,
            {
                query: this.getFilterQuery(),
            },
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.setCompanyCoachees(response.data)
                    }
                })
            }),
        )
    }

    public getFilterQuery() {
        const query: Record<string, any> = pick(
            this.selectedFilters,
            'areasOfCoaching',
            'seniorityLevels',
            'functions',
            'departments',
            'userStatus',
            'coacheeGenders',
        )

        set(query, ['startDate'], this.selectedFilters.date.start)
        set(query, ['endDate'], this.selectedFilters.date.end)

        return query
    }
    // public monthlyAttritionRisk: MonthlyAggregate['attritionRisk'][] = []
    @action
    public getCompanyMonthlyAttritionRisk() {
        return this.monthlyAggregates
            .map((aggregate) => ({
                month: aggregate.month,
                valid: aggregate.attritionRisk ? true : false,
                yes: aggregate.attritionRisk?.yes ?? 0,
                no: aggregate.attritionRisk?.no ?? 0,
                maybe: aggregate.attritionRisk?.maybe ?? 0,
                unknown: aggregate.attritionRisk?.unknown ?? 0,
            }))
            .filter(
                (aggregate) =>
                    aggregate.valid &&
                    (new Date(aggregate.month).getFullYear() !==
                        new Date().getFullYear() ||
                        getUTCMonthNumber(aggregate.month)) !==
                        new Date().getMonth(),
            )
    }

    @action
    public getCompanymonthlyActiveUsers() {
        return this.monthlyAggregates
            .map((aggregate) => ({
                date: aggregate.month,
                value: aggregate.monthlyActiveUsers,
            }))
            .filter(
                (aggregate) =>
                    new Date(aggregate.date).getFullYear() !==
                        new Date().getFullYear() ||
                    getUTCMonthNumber(aggregate.date) !== new Date().getMonth(),
            )
    }

    @action
    public getCompanyMonthlyAggregates() {
        return request<never, MonthlyAggregateResponse>(
            `/company/${this.activeCompanyId}/aggregate`,
            HttpMethod.GET,
            {
                query: this.getFilterQuery(),
            },
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.monthlyAggregates = response.data.monthly
                        this.quarterlyAggregates = response.data.quarterly ?? []
                        this.cumulativeAggregate = response.data.cumulative
                        this.availableFilters = response.data.filters.available
                        this.hidCompanyQuarterlyAggregate()
                    }
                })
            }),
        )
    }

    @action
    public hidCompanyQuarterlyAggregate() {
        const hidden: string[] = []
        const aggregate = this.chartOptions?.assesmentHiddenQuarters

        if (aggregate && aggregate.length !== 0) {
            aggregate.map((q) => {
                hidden.push(`Q${q.quarter}, ${q.year}`)
            })
        }

        this.hiddenQuarterlyAggregates = hidden
    }

    @action
    public exportCompanyAggregates() {
        this.exportDisabled = true
        const messageDuration = 7 * 1000 // 5 seconds

        stores.notifications.createNotification(
            NotificationType.INFO,
            t('messages.reportBeingGenerated'),
            messageDuration,
        )

        return request<never, MonthlyAggregateResponse>(
            `/company/${this.activeCompanyId}/aggregate/export`,
            HttpMethod.GET,
            {
                query: this.getFilterQuery(),
            },
        )
    }

    @action
    public getCompanyPdf(name: string) {
        return request<any, FileDownload>(
            `/company/${this.activeCompanyId}/pdf/export`,
            HttpMethod.GET,
            {
                query: { fileName: name },
            },
        )
    }

    @action
    public getCompanyCoachingHours() {
        return request<never, CoachingHours>(
            `/company/${this.activeCompanyId}/aggregate/coaching-hours`,
            HttpMethod.GET,
            {},
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.coachingHours = response.data
                    }
                })
            }),
        )
    }

    @action
    public removeCompanyUserAccess(
        model: RemoveAccessModel,
        companyUserId: string,
    ) {
        return request(
            `/company-user/${companyUserId}/associations/${this.activeCompanyId}`,
            HttpMethod.DELETE,
            {
                body: model.getRequestBody(),
                successMessage: t('messages.accessRemoved') as string,
            },
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        this.listCompanyUsers().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public updateCompanyUserAccess(level: string, companyUserId: string) {
        return request(
            `/company-user/${companyUserId}/associations/${this.activeCompanyId}`,
            HttpMethod.PATCH,
            {
                body: { level: level },
                successMessage: t('messages.accessUpdated') as string,
            },
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        this.listCompanyUsers().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public updateCompanyUserDepartments(
        departments: Array<string>,
        companyUserId: string,
    ) {
        return request(
            `/company-user/${companyUserId}/associations/${this.activeCompanyId}`,
            HttpMethod.PATCH,
            {
                body: { departments: departments },
                successMessage: t('messages.departmentAccessUpdated') as string,
            },
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        this.listCompanyUsers().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public inviteUser(model: InviteUserModel) {
        return request(`/company-user`, HttpMethod.POST, {
            body: model.getRequestBody(),
            successMessage: t('messages.userInviteSent') as string,
        }).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        this.listCompanyUsers().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public addCoachee(model: InviteCoacheeModel) {
        return request(
            `/company/${this.activeCompanyId}/coachee`,
            HttpMethod.POST,
            {
                body: model.getRequestBody(),
                successMessage: t('messages.userInviteSent') as string,
                silentErrors: true,
            },
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        this.listCompanyCoachees().subscribe()
                    } else if (response.status === 422) {
                        stores.notifications.createNotification(
                            NotificationType.ERROR,
                            'You have reached the limit of users that you can reactivate please contact support@sama.io for more details.',
                            15 * 1000,
                        )
                    }
                })
            }),
        )
    }

    @action
    public updateCoachee(
        model: EditCoacheeModel | EditConfidentialInsightsModel,
    ) {
        return request(
            `/company/${this.activeCompanyId}/coachee/${model._id}`,
            HttpMethod.PUT,
            {
                body: model.getRequestBody(),
                // @ TODO UPDATE MESSAGE
                successMessage: t('messages.updatedCoachee') as string,
            },
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        this.listCompanyCoachees().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public updateCompanyInsight(model: EditConfidentialInsightsModel) {
        return request(
            `/company-user/${this.activeCompanyId}/company`,
            HttpMethod.PUT,
            {
                body: model.getRequestBody(),
                successMessage: t('messages.updatedCompanyInsight') as string,
            },
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        stores.user.getUser().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public requestUpdateCoachee(model: RequestCoacheeUpdateModel) {
        return request(
            `/company/${this.activeCompanyId}/coachee/${model.coacheeId}`,
            HttpMethod.PUT,
            {
                body: model.getRequestBody(),
                // @ TODO UPDATE MESSAGE
                successMessage: t('messages.updatedCoachee') as string,
            },
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        this.listCompanyCoachees().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public sendEmailToSupport(content: string, subject: string, email: string) {
        return request('/email', HttpMethod.POST, {
            body: {
                content: content,
                subject: subject,
                companyId: this.activeCompanyId,
                email: email,
            },
            successMessage: t('messages.emailSupport') as string,
        })
    }

    @action
    public deactivateCoachee(model: DeactivateCoacheeModel) {
        return request(
            `/company/${this.activeCompanyId}/coachee/${model.coacheeId}`,
            HttpMethod.DELETE,
            {
                body: model.getRequestBody(),
                successMessage: t('messages.deletedCoachee') as string,
            },
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        this.listCompanyCoachees().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public resendCompanyAdminInvite(companyUserId: string) {
        return request(
            `/company-user/${this.activeCompanyId}/${companyUserId}/resend-invite`,
            HttpMethod.POST,
            {
                successMessage: t('messages.inviteReSent') as string,
            },
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        this.listCompanyCoachees().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public getCompanyConfidentialInsightsFiles() {
        return request<never, FileDownload[]>(
            `/company/${this.activeCompanyId}/insight-files`,
            HttpMethod.GET,
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.companyInsightFiles = response.data
                    }
                })
            }),
        )
    }

    @action
    public deleteCompanyConfidentialInsightsFile(fileId: string) {
        return request<never, never>(
            `/company/${this.activeCompanyId}/${fileId}/insight-files`,
            HttpMethod.DELETE,
        ).pipe(
            tap((response) => {
                runInAction(() => {})
            }),
        )
    }

    @action
    public addDepartment(model: DepartmentModel) {
        return request(`/department/${this.activeCompanyId}`, HttpMethod.POST, {
            body: model.getRequestBody(),
            successMessage: t('messages.createDepartmentSent') as string,
            silentErrors: true,
        })
    }

    @action
    public updateDepartment(model: DepartmentModel) {
        return request(`/department/${model._id}`, HttpMethod.PUT, {
            body: {
                ...model.getRequestBody(),
                companyId: this.activeCompanyId,
            },
            successMessage: t('messages.updateDepartmentSent') as string,
            silentErrors: true,
        })
    }

    @action
    public getDepartments() {
        return request<never, Department[]>(
            `/department/${this.activeCompanyId}`,
            HttpMethod.GET,
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.setCompanyDepartments(response.data)
                    }
                })
            }),
        )
    }

    @action
    public sendEndRequest(model: RequestCoacheeUpdateModel) {
        return request(`/edit-request/${model.coacheeId}`, HttpMethod.POST, {
            body: {
                ...model.getRequestBody(),
                companyId: this.activeCompanyId,
            },
            successMessage: t('messages.editRequestSent') as string,
        }).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        this.listCompanyCoachees().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public reset(): void {
        this.companyAssociations = []
        this.monthlyAggregates = []
        this.quarterlyAggregates = []
        this.cumulativeAggregate = null
        this.coachingHours = null
        this.selectedFilters = DEFAULT_FILTERS
        this.coachees = []
        this.exportDisabled = false
    }
}
