import { Disclosure } from '@headlessui/react'
import { LayoutGroup } from 'framer-motion'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { PrimaryButton } from '../../../components/buttons'
import { ProfilePictureCircle } from '../../../components/misc'
import { CompanyAssociationFull } from '../../../models/response/company-association'
import { AccountLevel, RouteLink } from '../../../util/constants'
import { classNames } from '../../../util/misc'
import { useStores } from '../../../util/stores'
import { ManageAccessModal } from '../modals'
import { SideBarLink } from './side-bar-link'

const Wrapper = styled.div`
    .hamburger-menu-button {
        width: 40px;
        height: 40px;
        padding: 6px;
        display: block;
        position: relative;
        margin-top: 10px;
        z-index: 100;
        background: #3095aa;
        border: 5px solid white;
        box-sizing: content-box;
        border-radius: 50%;
        text-indent: 100%;
        color: transparent;
        white-space: nowrap;
        cursor: pointer;
        overflow: hidden;
        outline: 0;
    }

    .hamburger-menu-button-o {
        background: #10123a;
    }

    .hamburger-menu-button-open {
        top: 50%;
        margin-top: -1px;
        left: 50%;
        margin-left: -12px;
    }
    .hamburger-menu-button-open,
    .hamburger-menu-button-open::before,
    .hamburger-menu-button-open::after {
        position: absolute;
        left: 20px;
        width: 14px;
        height: 2px;
        background: #fff;
        border-radius: 4px;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }
    .hamburger-menu-button-open::before,
    .hamburger-menu-button-open::after {
        left: 0;
        content: '';
    }
    .hamburger-menu-button-open::before {
        top: 6px;
    }
    .hamburger-menu-button-open::after {
        bottom: 6px;
    }
    .hamburger-menu-button-close {
        background: transparent;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    .hamburger-menu-button-close::before {
        -webkit-transform: translateY(-6px) rotate(45deg);
        transform: translateY(-6px) rotate(45deg);
    }
    .hamburger-menu-button-close::after {
        -webkit-transform: translateY(6px) rotate(-45deg);
        transform: translateY(6px) rotate(-45deg);
    }
`

export const SideBar: React.FC = observer(() => {
    const { t } = useTranslation()
    const { auth, user, company } = useStores()
    const [isExpanded, setIsExpanded] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [companyAssociations, setCompanyAssociations] = useState(
        [] as CompanyAssociationFull[],
    )
    const activeAssociation = user.activeAssociation?._id

    const openModal = useCallback(() => {
        setIsModalOpen(true)
    }, [setIsModalOpen])

    const updateCompanyAssociations = useCallback(() => {
        return setCompanyAssociations(company.companyAssociations)
    }, [company.companyAssociations])

    const toggleExpansion = useCallback(() => {
        setIsExpanded((expanded) => !expanded)
    }, [setIsExpanded])

    useEffect(() => {
        if (window.innerWidth < 576) {
            setIsExpanded(false)
        }

        if (activeAssociation) {
            const subscription = company
                .listCompanyUsers()
                .subscribe(updateCompanyAssociations)
            return () => {
                subscription.unsubscribe()
            }
        }
    }, [company, activeAssociation, company.company_user_images_updated])

    const trackClick = useCallback(
        (trackingId: string, trackingAction: string) => {
            user.trackEvent('button_clicked', {
                name: trackingId,
                action: trackingAction,
            }).subscribe()
        },
        [],
    )

    return (
        <aside
            className={classNames(
                'z-20 transition-all bg-white text-[14px] flex-shrink-0',
                'fixed right-0 sm:right-100 sm:relative',
                'w-[100%]',
                isExpanded ? 'h-full sm:w-[18.5rem]' : 'sm:w-[4rem]',
            )}
            style={{
                boxShadow: 'inset -0.33px 0px 0px #DDDEE3',
            }}
        >
            <div
                className={classNames(
                    'w-full px-4 h-16 flex items-center',
                    isExpanded
                        ? 'justify-between'
                        : 'justify-between sm:justify-center',
                )}
                style={{
                    background: 'rgba(247, 247, 247, 0.01)',
                    boxShadow: '0px 0.33px 0px #DDDEE3',
                }}
            >
                <Link
                    to={RouteLink.DASHBOARD}
                    className={classNames(!isExpanded && 'sm:hidden left-0')}
                >
                    <img
                        src="/img/logo/logo-color.svg"
                        alt="Logo"
                        className="h-6"
                    />
                </Link>
                <button className={classNames('h-2')} onClick={toggleExpansion}>
                    <img
                        src={`/img/icons/${
                            isExpanded ? 'minimize' : 'maximize'
                        }-menu.svg`}
                        className={classNames('h-2 hidden sm:block')}
                    />
                </button>
                <Wrapper className={classNames('block sm:hidden ')}>
                    <button
                        className={classNames(
                            'hamburger-menu-button',
                            !isExpanded && 'hamburger-menu-button-o',
                        )}
                        onClick={toggleExpansion}
                    >
                        <span
                            className={classNames(
                                'hamburger-menu-button-open',
                                isExpanded && 'hamburger-menu-button-close',
                            )}
                        >
                            Menu
                        </span>
                    </button>
                </Wrapper>
            </div>
            <div
                className={classNames(
                    !isExpanded && 'hidden sm:block',
                    'w-full py-3 flex flex-col justify-between',
                )}
                style={{
                    background: 'rgba(247, 247, 247, 0.01)',
                    boxShadow: isExpanded ? '0px 0.33px 0px #DDDEE3' : '',
                }}
            >
                <LayoutGroup>
                    <SideBarLink
                        to={RouteLink.DASHBOARD}
                        title={t('titles.dashboard')}
                        iconPath="/img/icons/home-open.svg"
                        isExpanded={isExpanded}
                    />
                    {user.canAccess(AccountLevel.ADMIN) && (
                        <SideBarLink
                            to={RouteLink.ACCOUNT}
                            title={t('titles.account')}
                            iconPath="/img/icons/cog-open.svg"
                            isExpanded={isExpanded}
                        />
                    )}
                    {/* <SideBarLink
                        to={RouteLink.FAQS}
                        title={t('titles.faqs')}
                        iconPath="/img/icons/question-mark-circle-open.svg"
                        isExpanded={isExpanded}
                    /> */}
                </LayoutGroup>
            </div>

            <div
                className={classNames(
                    'w-full sm:w-[18.5rem] px-4 py-6 flex flex-col justify-between',
                    !isExpanded && 'hidden',
                )}
                style={{
                    background: 'rgba(247, 247, 247, 0.01)',
                    boxShadow: '0px 0.33px 0px #DDDEE3',
                }}
            >
                <Disclosure defaultOpen>
                    {({ open }) => (
                        <>
                            <Disclosure.Button className="flex justify-between items-center">
                                <div>
                                    <span>{t('sidebar.dashboardAccess')}</span>
                                    <span className="ml-1">
                                        ({companyAssociations.length})
                                    </span>
                                </div>
                                <img
                                    src="/img/icons/chevron-down.svg"
                                    alt="Arrow"
                                    className={classNames(
                                        'w-2',
                                        !open && '-rotate-90',
                                    )}
                                />
                            </Disclosure.Button>
                            {open && (
                                <>
                                    <div
                                        className={classNames(
                                            'space-y-4 my-6',
                                            'overflow-y-auto',
                                            'scrollbar-thin scrollbar-thumb-[#c4c4c4] scrollbar-thumb-rounded',
                                            'md:h-[350px] sm:h-[250px] lg:h-[400px] h-[220px]',
                                        )}
                                    >
                                        {companyAssociations.length !== 0 &&
                                            companyAssociations.map(
                                                (
                                                    {
                                                        companyUser: {
                                                            _id: companyUserId,
                                                            user: _user,
                                                            profilePicture,
                                                        },
                                                        level,
                                                    },
                                                    i,
                                                ) => {
                                                    const firstName =
                                                        _user.firstName
                                                    const lastName =
                                                        _user.lastName
                                                    const initials =
                                                        firstName[0].toUpperCase() +
                                                        lastName[0].toUpperCase()

                                                    const isCurrentUser =
                                                        companyUserId ===
                                                        user.companyUserId

                                                    return (
                                                        <div
                                                            key={i}
                                                            className="flex items-center space-x-3"
                                                        >
                                                            <ProfilePictureCircle
                                                                initials={
                                                                    initials
                                                                }
                                                                picture={
                                                                    profilePicture
                                                                }
                                                            />
                                                            <div className="flex flex-col space-y-[-2px]">
                                                                <span className="text-[16px] space-x-2">
                                                                    <span>
                                                                        {
                                                                            firstName
                                                                        }{' '}
                                                                        {
                                                                            lastName
                                                                        }
                                                                    </span>
                                                                    {isCurrentUser && (
                                                                        <span className="p-1 border-[1px] rounded-full border-dotted border-accent-2 text-[9px] uppercase">
                                                                            {t(
                                                                                'sidebar.you',
                                                                            )}
                                                                        </span>
                                                                    )}
                                                                </span>
                                                                <span className="text-[14px] text-[#56576A]">
                                                                    {t(
                                                                        `levels.${level}`,
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )
                                                },
                                            )}
                                    </div>
                                    <PrimaryButton
                                        trackingId="manageAccess"
                                        trackingAction="open"
                                        className="btn-sm btn-alt"
                                        onClick={openModal}
                                    >
                                        <span>{t('buttons.manageAccess')}</span>
                                    </PrimaryButton>
                                </>
                            )}
                        </>
                    )}
                </Disclosure>
            </div>
            <div
                className={classNames(
                    'w-full px-0 sm:px-4 py-0 sm:py-3 flex flex-col justify-between',
                    !isExpanded && 'hidden',
                )}
                style={{
                    background: 'rgba(247, 247, 247, 0.01)',
                    boxShadow: '0px 0.33px 0px #DDDEE3',
                }}
            >
                <a
                    onClick={() => trackClick('contactSupport', 'click')}
                    className="border-b-[1px] sm:border-none relative px-4 sm:px-0 h-10 flex items-center text-accent-2 font-normal"
                    href={`mailto:${
                        process.env.REACT_APP_EMAIL_SUPPORT
                    }?subject=${t('messages.supportEmailSubject')}`}
                >
                    <span>{t('sidebar.contactSupport')}</span>
                </a>
                <a
                    onClick={() => trackClick('sendFeedback', 'click')}
                    className="border-b-[1px] sm:border-none relative px-4 sm:px-0 h-10 flex items-center text-accent-2 font-normal"
                    href={`mailto:${
                        process.env.REACT_APP_EMAIL_SUPPORT
                    }?subject=${t('messages.feedbackEmailSubject')}`}
                >
                    <span>{t('sidebar.sendFeedback')}</span>
                </a>
                <Link
                    className="sm:hidden border-b-[1px] sm:border-none relative px-4 h-10 flex items-center text-accent-2 font-normal"
                    to={RouteLink.LOG_OUT}
                    onClick={auth.signOut}
                >
                    <span>{t('buttons.signOut')}</span>
                </Link>
                <ManageAccessModal
                    isOpen={!!user.activeAssociation && isModalOpen}
                    setIsOpen={setIsModalOpen}
                />
            </div>
        </aside>
    )
})
