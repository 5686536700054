export enum RouteLink {
    ANY = '*',
    INDEX = '/',
    SET_PHONE = '/set-phone',
    SIGN_UP = '/signup',
    SIGN_UP_VERIFY = '/signup/verify',
    MAGIC_SIGNUP = '/signup/magic',
    LOG_IN = '/login',
    LOG_OUT = '#',
    MAGIC_LOGIN = '/login/magic',
    TWO_FA = '/login/2fa',
    DASHBOARD = '/dashboard',
    ACCOUNT = '/account',
    ACCOUNT_WILDCARD = '/account/*',
    MY_ACCOUNT = '/my-account',
    DOWNLOAD_PDF_REPORT = '/download/pdf-report',
    FAQS = '/faqs',
    SUPPORT = '#',
}
