import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { ProfilePictureCircle } from '../../../components/misc'
import { useStores } from '../../../util/stores'
import { MyProfileModal } from '../../../components/modals/my-profile-modal'
import { MenuHoverList } from '../../../components/layout/menu-hover-list'
import { useTranslation } from 'react-i18next'
import { classNames } from '../../../util/misc'

interface Props {
    title: string
}

export const DashboardPageHeader: React.FC<Props> = observer(({ title }) => {
    const { user, auth } = useStores()
    const { t } = useTranslation()
    const [isMenuOpen, setMenuOpen] = useState(false)
    const [isMyProfileOpen, setMyProfileOpen] = useState(false)
    const wrapperRef = useRef(null)

    const openMyProfileOpen = useCallback(
        (open: boolean) => {
            user.trackEvent('button_clicked', {
                name: 'myProfile',
                action: open ? 'open' : 'close',
            }).subscribe()
            setMyProfileOpen(open)
        },
        [isMyProfileOpen],
    )

    const initials = useMemo(() => {
        if (!user.user) {
            return 'N/A'
        }

        const firstInitial = user.user.firstName[0]?.toUpperCase?.() ?? ''
        const lastInitial = user.user.lastName[0]?.toUpperCase?.() ?? ''

        return firstInitial + lastInitial
    }, [user.user])

    useEffect(() => {
        if (!user.user) {
            const subscription = user.getUser().subscribe()

            return () => {
                subscription.unsubscribe()
            }
        }
    }, [])

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                wrapperRef.current &&
                // @ts-ignore
                !wrapperRef.current.contains(event.target)
            ) {
                setTimeout(() => setMenuOpen(false), 160)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [wrapperRef])

    return (
        <header
            className={classNames(
                'bg-[#f7f7f7]',
                'sm:flex transition-all w-full',
                'px-6 h-16 flex-shrink-0 flex items-center justify-between',
            )}
        >
            <span>{title}</span>
            {user.user && (
                <div className="flex space-x-4 items-center">
                    <ProfilePictureCircle
                        picture={user.picture}
                        initials={initials}
                        onClick={() => setMenuOpen(true)}
                        wrapperClassNames="cursor-pointer"
                        wrapperRef={wrapperRef}
                    />

                    <MenuHoverList
                        isOpen={isMenuOpen}
                        boxClassName={'pt-[43px] pl-[25px]'}
                        list={[
                            {
                                title: t('titles.yourProfile'),
                                onClick: () => openMyProfileOpen(true),
                            },
                            {
                                title: t('buttons.signOut'),
                                onClick: () => auth.signOut(),
                            },
                        ]}
                    />
                    <MyProfileModal
                        isOpen={isMyProfileOpen}
                        setIsOpen={openMyProfileOpen}
                    />
                </div>
            )}
        </header>
    )
})
