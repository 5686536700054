import { Allow, IsNotEmpty, IsPhoneNumber } from 'class-validator'
import { t } from 'i18next'
import { autorun } from 'mobx'
import { stores } from '../../../util/stores'
import { BaseModel } from '../base.model'

export class AddPhoneModel extends BaseModel {
    @IsPhoneNumber(undefined, {
        message: t('validation.invalidPhone') as string,
    })
    public phone: string = ''

    @IsNotEmpty({ message: t('validation.mustBeSet') as string })
    public phoneCountry: string = ''

    @Allow()
    public accessToken: string = ''

    constructor() {
        super()

        autorun(() => {
            const accessToken = stores.auth.authResponse?.accessToken

            if (accessToken) {
                this.accessToken = accessToken
            }
        })
    }
}
