import { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Subscription } from 'rxjs'
import { PrimaryButton } from '../../../components/buttons'
import { Tooltip } from '../../../components/tooltip'
import { Department } from '../../../models/response'
import { cancelSubscriptions, classNames, formatDate } from '../../../util/misc'
import { useStores } from '../../../util/stores'
import { CreateOrUpdateDepartmentModal } from '../models'
import { EditOption } from './edit-option'
import { AccountLevel } from '../../../util/constants'

interface FieldProps {
    value: any
    title: string
    tooltip?: string
}

export const DepartmentsField: React.FC<FieldProps> = observer(
    ({ value, title, tooltip }) => {
        return (
            <div className="flex h-[50px]">
                <span className="w-2/4">{title}</span>
                <span className="w-2/4 text-right">
                    <p className="inline-block align-text-bottom">{value}</p>
                    <span className={classNames('pl-2')}>
                        {tooltip && (
                            <Tooltip text={tooltip} orientation="left">
                                <img
                                    src="/img/icons/i-circle-open.svg"
                                    alt="information tooltip"
                                    className="w-5"
                                />
                            </Tooltip>
                        )}
                    </span>
                </span>
            </div>
        )
    },
)

export const DepartmentsTab: React.FC = observer(() => {
    const { company, user } = useStores()
    const { t } = useTranslation()
    const subscriptions: Subscription[] = []
    const [isDepartmentModalOpen, setIsDepartmentModalOpen] = useState(false)
    const [departmentsList, setDepartmentsList] = useState([] as Department[])
    const [currentDepartment, setDepartment] = useState(
        undefined as undefined | Department,
    )
    const isAdmin = user.canAccess(AccountLevel.ADMIN)

    const openDepartmentModel = useCallback(
        (department?: Department) => {
            setDepartment(department)
            setIsDepartmentModalOpen(true)
        },
        [isDepartmentModalOpen, currentDepartment],
    )

    const getCompanyDepartments = useCallback(() => {
        if (isAdmin && company.activeCompanyId) {
            const subscription = company.getDepartments().subscribe({
                next(response) {
                    if (response.ok && response.data) {
                        if (!currentDepartment) {
                            setDepartment(response?.data[0])
                        }

                        setDepartmentsList(response?.data)
                    }
                },
            })

            subscriptions.push(subscription)
        }
    }, [company.activeCompanyId, isAdmin])

    useEffect(() => {
        if (company.activeCompanyId) {
            getCompanyDepartments()
        }

        return () => {
            cancelSubscriptions(subscriptions)
        }
    }, [company.activeCompanyId])

    return (
        <div
            className={classNames(
                'grid grid-flow-col min-h-[600px]',
                departmentsList && departmentsList.length > 0
                    ? 'grid-rows-3'
                    : '',
            )}
        >
            {!departmentsList ||
                (departmentsList.length === 0 && (
                    <div className={classNames('w-full m-auto w-[250px]')}>
                        <h2 className="text-3xl">
                            {
                                t(
                                    'account.departments.emptyDepartments.title',
                                ) as string
                            }
                        </h2>
                        <p className="pt-4">
                            {
                                t(
                                    'account.departments.emptyDepartments.subtext',
                                ) as string
                            }
                        </p>
                        <PrimaryButton
                            trackingId="createDepartment"
                            trackingAction="open"
                            className="h-[42px] w-[150px] min-w-[120px] mt-[20px]"
                            onClick={() => openDepartmentModel()}
                        >
                            <span>{t('buttons.getStarted')}</span>
                        </PrimaryButton>
                    </div>
                ))}

            {departmentsList && departmentsList.length > 0 && (
                <>
                    <div
                        className={classNames(
                            'w-[280px] h-full flex flex-wrap row-span-3',
                        )}
                    >
                        <div
                            className={classNames('w-full pt-[20px] pl-[32px]')}
                        >
                            <h1 className="text-2xl">
                                {t('account.menuTabs.departments') as string}
                            </h1>
                            <div className="border-r-2 border-gray-100 ">
                                <div className="max-h-[700px] overflow-y-auto">
                                    {departmentsList &&
                                        departmentsList.map(
                                            (department, key) => (
                                                <div
                                                    key={key}
                                                    className={classNames(
                                                        'flex min-h-[40px] break-all pt-5 pr-2',
                                                        department._id ===
                                                            currentDepartment?._id
                                                            ? 'text-[#3095aa]'
                                                            : '',
                                                    )}
                                                >
                                                    <span
                                                        onClick={() =>
                                                            setDepartment(
                                                                department,
                                                            )
                                                        }
                                                        className="w-[73%] wrap cursor-pointer"
                                                    >
                                                        {department.name}
                                                    </span>
                                                    <span
                                                        className={classNames(
                                                            'place-items-center cursor-pointer',
                                                            department._id ===
                                                                currentDepartment?._id
                                                                ? ' flex'
                                                                : ' hidden',
                                                        )}
                                                        key={key}
                                                    >
                                                        <EditOption
                                                            isEditing={false}
                                                            onClick={() =>
                                                                openDepartmentModel(
                                                                    department,
                                                                )
                                                            }
                                                        />
                                                    </span>
                                                </div>
                                            ),
                                        )}
                                </div>
                                <div className="m-atuo pb-10 pt-3">
                                    <PrimaryButton
                                        trackingId="createDepartment"
                                        trackingAction="open"
                                        className="h-[42px] w-[200px] min-w-[120px] mt-[20px] btn-alt"
                                        onClick={() => openDepartmentModel()}
                                    >
                                        <span>
                                            {t('buttons.createDepartment')}
                                        </span>
                                    </PrimaryButton>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className={classNames(
                            'w-full h-full pt-[60px] pr-20 col-span-8',
                        )}
                    >
                        {currentDepartment && (
                            <>
                                <DepartmentsField
                                    title={
                                        t(
                                            'account.departments.createdAt',
                                        ) as string
                                    }
                                    value={formatDate(
                                        currentDepartment._createdAt,
                                    )}
                                />
                                <DepartmentsField
                                    title={
                                        t(
                                            'account.departments.activeUsers',
                                        ) as string
                                    }
                                    value={
                                        currentDepartment.numberOfActiveUsers
                                    }
                                    tooltip={
                                        t(
                                            'messages.account.activeUsers',
                                        ) as string
                                    }
                                />
                            </>
                        )}
                    </div>
                </>
            )}
            <CreateOrUpdateDepartmentModal
                department={currentDepartment}
                isOpen={isDepartmentModalOpen}
                setIsOpen={setIsDepartmentModalOpen}
                onClose={getCompanyDepartments}
            />
        </div>
    )
})
