import { Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton } from '../../../components/buttons'
import { TextInput } from '../../../components/input'
import { AddPhoneModel } from '../../../models/request'
import { RouteLink } from '../../../util/constants'
import { useStores } from '../../../util/stores'
import { validateModel } from '../../../util/validation'
import { AuthPageWrapper } from '../components'
import { SetPhoneForm } from '../sign-up/forms'

export const SetPhonePage: React.FC = observer(() => {
    const { t } = useTranslation()
    const { auth } = useStores()
    const navigate = useNavigate()
    const companyName = auth.authResponse?.companyName

    const onSubmit = useCallback(
        (values: AddPhoneModel, helpers: FormikHelpers<AddPhoneModel>) => {
            helpers.setSubmitting(true)

            auth.setPhone(values).subscribe({
                next(response) {
                    if (response.ok) {
                        navigate(RouteLink.TWO_FA, { replace: true })
                    }
                },
                complete() {
                    helpers.setSubmitting(false)
                },
            })
        },
        [auth],
    )

    return (
        <AuthPageWrapper>
            <SetPhoneForm
                title={t('titles.setPhoneNumber') as string}
                onSubmit={onSubmit}
            />
        </AuthPageWrapper>
    )
})

export default SetPhonePage
