import React, { PropsWithChildren } from 'react'
import { classNames } from '../../../util/misc'

interface Props extends PropsWithChildren {
    className?: string
    horizontalScroll?: Boolean
}

export const Block: React.FC<Props> = ({ children, className }) => {
    return (
        <div
            className={classNames(
                'relative bg-white rounded-lg overflow-hidden overflow-x-auto',
                className,
            )}
        >
            {children}
        </div>
    )
}
