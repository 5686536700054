import {
    FieldHelperProps,
    FieldInputProps,
    FieldMetaProps,
    useField,
} from 'formik'
import React, { useCallback, useState } from 'react'
import { classNames, Option } from '../../util/misc'
import { FormValidationMessage } from '../layout'
import { ListBox, SELECT_ALL_VALUE } from './list-box'

interface Props {
    name: string
    label?: string | React.ReactNode
    className?: string
    placeholder?: string
    options: Option[]
    selected?: Option[]
    disabled?: boolean
    canUseField?: boolean
    onChange?: any
    width?: string
    height?: string
}

export const CheckElement: React.FC = () => {
    return (
        <img
            src="/img/icons/checkbox-active.svg"
            alt="Check in circle"
            className="w-4 ml-2 mr-2"
        />
    )
}

export const NotCheckedElement: React.FC = () => {
    return (
        <img
            src="/img/icons/checkbox-inactive.svg"
            alt="Check in circle"
            className="w-4 ml-2 mr-2"
        />
    )
}

export const MultiSelectInput: React.FC<Props> = ({
    name,
    label,
    className,
    placeholder,
    options,
    selected = [],
    disabled,
    canUseField,
    onChange,
    width,
    height,
}) => {
    let field: FieldInputProps<any> | null = null
    let meta: FieldMetaProps<any> | null = null
    let helpers: FieldHelperProps<any> | null = null

    if (canUseField) {
        ;[field, meta, helpers] = useField(name)
    }

    const [selectedOptions, setSelectedOptions] = useState([] as Option[])

    const getSelectedOptions = useCallback(() => {
        if (selected && selected.length > 0) {
            placeholder = selected
                .filter((select) => select.value !== SELECT_ALL_VALUE)
                .slice(0, 2)
                .map((select) => select.display)
                .join(', ')

            if (selected.length > 2) {
                placeholder += ',....'
            }
        }

        return options.filter((option) => {
            return (
                selected.filter((select) => {
                    return select.value === option.value
                }).length > 0
            )
        })
    }, [selected])

    const onSelect = useCallback(
        (newleySelected: Option[]) => {
            const allSelected =
                newleySelected.filter(
                    (option) => option.value === SELECT_ALL_VALUE,
                ).length === 1

            const selectedLength =
                selectedOptions.length > 0
                    ? selectedOptions.length
                    : getSelectedOptions().length
            const wasAllSelected = selectedLength >= options.length - 1

            let cleanedOptions: Option[] = []

            if (allSelected) {
                cleanedOptions = options
            } else if (wasAllSelected && !allSelected) {
                // nothing to do here
            } else {
                cleanedOptions = newleySelected.filter(
                    (option) => option.value !== SELECT_ALL_VALUE,
                )
            }
            selected = cleanedOptions
            setSelectedOptions(cleanedOptions)
            helpers?.setValue(cleanedOptions)

            if (onChange) {
                onChange(cleanedOptions)
            }
        },
        [selectedOptions],
    )

    return (
        <div className={classNames('flex flex-col', className)}>
            {label && (
                <label htmlFor={name} className="block text-[11px] mb-2">
                    {label}
                </label>
            )}
            <ListBox
                multiple={true}
                selectAllOption={true}
                onSelect={onSelect}
                values={getSelectedOptions()}
                options={options}
                placeholder={placeholder}
                buttonWidth={width}
                buttonHeight={height}
                optionsClassName="!w-full bg-[#f6f7f9] !divide-[#10123A]/[3%]"
                optionClassName="text-accent-2"
                optionClassNameActive="!text-accent"
                optionClassNameSelected="!text-accent"
                disabled={disabled}
                checkElement={<CheckElement />}
                notCheckedElement={<NotCheckedElement />}
            />
            {meta?.touched && meta?.error && (
                <div className="mt-[10px]">
                    <FormValidationMessage message={meta.error[0]} />
                </div>
            )}
        </div>
    )
}
