import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormNotification } from '../../../components/layout'
import { NotificationType } from '../../../util/constants'
import { useStores } from '../../../util/stores'

export const Resend2fa: React.FC = observer(() => {
    const { t } = useTranslation()
    const { auth } = useStores()
    const [secondsLeft, setSecondsLeft] = useState(
        auth.getSecondsToResendCode(),
    )

    const canResendCode = useMemo(() => {
        return auth.authResponse?.codeRequestedAt && secondsLeft <= 0
    }, [secondsLeft, auth])

    const secondsLeftText = useMemo(() => {
        return t('messages.resend2fa', { seconds: secondsLeft })
    }, [secondsLeft])

    useEffect(() => {
        const interval = setInterval(() => {
            setSecondsLeft(auth.getSecondsToResendCode())
        }, 1000 /* Update every second */)

        return () => {
            clearInterval(interval)
        }
    }, [])

    const resendCode = useCallback(() => {
        auth.resend2fa().subscribe()
    }, [auth])

    return (
        <div>
            {canResendCode ? (
                <button
                    onClick={resendCode}
                    className="inline-block text-accent font-semibold"
                    type="button"
                >
                    <span>{t('buttons.resend2fa')}</span>
                </button>
            ) : (
                <FormNotification
                    type={NotificationType.INFO}
                    message={secondsLeftText}
                />
            )}
        </div>
    )
})
