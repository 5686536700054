import './i18n'

import React from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { App } from './app'
import './index.css'
import reportWebVitals from './report-web-vitals'

Sentry.init({
    dsn: 'https://f0e49891f59149d58fa73830169ed2c5@o784619.ingest.sentry.io/4504169853485056',
    environment: process.env.NODE_ENV,
    tracesSampleRate: 1.0,
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
