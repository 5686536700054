import { IsNotEmpty, MaxLength, MinLength, ValidateIf } from 'class-validator'
import { t } from 'i18next'
import { Validation } from '../../../util/constants'
import { Option } from '../../../util/misc'
import { BaseModel } from '../base.model'

export class RemoveAccessModel implements BaseModel {
    @IsNotEmpty({ message: t('validation.selectAnOption') as string })
    public reason: Option | null = null

    @ValidateIf((o: RemoveAccessModel) => o.reason?.value === 'other')
    @MinLength(Validation.MIDDLE_BIG_STRING_MIN_LENGTH, {
        message: t('validation.tooShort') as string,
    })
    @MaxLength(Validation.MIDDLE_BIG_STRING_MAX_LENGTH, {
        message: t('validation.tooShort') as string,
    })
    public reasonText: string = ''

    public getRequestBody() {
        return {
            deactivationReason: this.reason?.value,
            deactivationReasonText:
                this.reason?.value === 'other' ? this.reasonText : undefined,
        }
    }
}
