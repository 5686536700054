import { FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TwoFaModel } from '../../../models/request'
import { useStores } from '../../../util/stores'
import { AuthPageWrapper } from '../components'
import { VerifyPhoneForm } from '../sign-up/forms'

export const TwoFaPage: React.FC = observer(() => {
    const { t } = useTranslation()
    const { auth } = useStores()

    const onSubmit = useCallback(
        (values: TwoFaModel, helpers: FormikHelpers<TwoFaModel>) => {
            helpers.setSubmitting(true)

            auth.validate2fa(values).subscribe({
                complete() {
                    helpers.setSubmitting(false)
                },
            })
        },
        [auth],
    )

    return (
        <AuthPageWrapper>
            <VerifyPhoneForm
                title={t('titles.logIn') as string}
                onSubmit={onSubmit}
            />
        </AuthPageWrapper>
    )
})

export default TwoFaPage
