import { Formik, FormikHelpers } from 'formik'
import React, { useCallback, useEffect } from 'react'
import { observer } from 'mobx-react'
import { CenterModal } from '../../../components/modals'
import { Trans, useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../../components/buttons'
import { validateModel } from '../../../util/validation'
import { Subscription } from 'rxjs'
import { useStores } from '../../../util/stores'
import { cancelSubscriptions } from '../../../util/misc'
import { TextareaInput } from '../../../components/input'
import { EditConfidentialInsightsModel } from '../../../models/request/coachee/edit-confidential-insights.model'
import { Coachee } from '../../../models/response'

interface Props {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    coachee?: Coachee
}

export const EditCiModel: React.FC<Props> = observer(
    ({ isOpen, setIsOpen, coachee }) => {
        const { t } = useTranslation()
        const { company, statics, user } = useStores()
        const subscriptions: Subscription[] = []

        const close = useCallback(() => {
            user.trackEvent('button_clicked', {
                name: 'editConfidentialInsight',
                action: 'close',
            }).subscribe()
            setIsOpen(false)
        }, [])

        const editCiContent = useCallback(
            (
                values: EditConfidentialInsightsModel,
                helpers: FormikHelpers<EditConfidentialInsightsModel>,
            ) => {
                helpers.setSubmitting(true)
                let subscription = null
                if (coachee) {
                    subscription = company.updateCoachee(values).subscribe({
                        next(response) {
                            if (response.ok) {
                                coachee.confidentialInfo =
                                    values.confidentialInsight
                                helpers.setSubmitting(false)
                            }
                        },
                    })
                    subscriptions.push(subscription)
                } else {
                    subscription = company
                        .updateCompanyInsight(values)
                        .subscribe({
                            next() {
                                helpers.setSubmitting(false)
                                close()
                            },
                        })
                }
            },
            [close],
        )

        const getEditModel = useCallback(() => {
            const id = coachee ? coachee._id : company?.activeCompany?._id ?? ''
            const insight = coachee
                ? coachee.confidentialInfo
                : company?.activeCompany?.confidentialInsight ?? ''

            return new EditConfidentialInsightsModel(id, insight)
        }, [close])

        useEffect(() => {
            return () => {
                cancelSubscriptions(subscriptions)
            }
        }, [close])

        return (
            <CenterModal
                isOpen={isOpen}
                setIsOpen={close}
                className="max-w-[32rem]"
            >
                <header className="flex flex-col space-y-3 mb-6 mx-9">
                    <span className="text-2xl">
                        <Trans
                            t={t}
                            i18nKey="modals.editCiContent.heading"
                            tOptions={{
                                name: coachee
                                    ? coachee.firstName + ' ' + coachee.lastName
                                    : company.activeCompany?.label,
                            }}
                        >
                            <b />
                        </Trans>
                    </span>
                    <span className="text-xs text-[#56576A]">
                        {t('modals.editCiContent.subText')}
                    </span>
                </header>
                <main>
                    <Formik
                        validate={validateModel}
                        initialValues={getEditModel()}
                        onSubmit={editCiContent}
                    >
                        {({ handleSubmit, values, isSubmitting, isValid }) => (
                            <form
                                onSubmit={handleSubmit}
                                className="divide-y divide-[black]/10"
                            >
                                <main className="space-y-4 mx-9 mb-6">
                                    <TextareaInput
                                        name="confidentialInsight"
                                        value={coachee?.confidentialInfo}
                                        canResize={true}
                                        className="h-[10rem]"
                                        placeholder={
                                            t(
                                                'fields.editCiContent.placeholder',
                                            ) as string
                                        }
                                    />
                                </main>
                                <footer className="px-9 py-5 flex space-x-8">
                                    <PrimaryButton
                                        trackingId="editConfidentialInsight"
                                        trackingAction="cancel"
                                        type="button"
                                        className="flex-1 btn-open"
                                        onClick={close}
                                        disabled={isSubmitting}
                                    >
                                        <span>{t('buttons.cancel')}</span>
                                    </PrimaryButton>
                                    <PrimaryButton
                                        trackingId="editConfidentialInsight"
                                        trackingAction="update"
                                        type="submit"
                                        className="flex-1 btn-red"
                                        disabled={isSubmitting || !isValid}
                                    >
                                        <span>{t('buttons.update')}</span>
                                    </PrimaryButton>
                                </footer>
                            </form>
                        )}
                    </Formik>
                </main>
            </CenterModal>
        )
    },
)
