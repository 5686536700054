import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../tailwind.config'
const fullConfig = resolveConfig(tailwindConfig)

const getScreens = (): any => {
    if (fullConfig?.theme?.screens) {
        return fullConfig.theme.screens
    }
    return null
}

export const getBreakpointValue = (value: string): number => {
    const screens = getScreens()
    if (screens) {
        return screens[value].slice(0, screens[value].indexOf('px'))
    }
    return 0
}

export const getCurrentBreakpoint = (): string => {
    let currentBreakpoint: string = 'sm'
    let biggestBreakpointValue = 0
    for (const breakpoint of Object.keys(getScreens())) {
        const breakpointValue = getBreakpointValue(breakpoint)
        if (
            breakpointValue > biggestBreakpointValue &&
            window.innerWidth >= breakpointValue
        ) {
            biggestBreakpointValue = breakpointValue
            currentBreakpoint = breakpoint
        }
    }
    return currentBreakpoint
}
