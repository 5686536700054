import { action, makeAutoObservable, runInAction } from 'mobx'
import { tap } from 'rxjs'
import {
    AddPhoneModel,
    MagicLoginModel,
    RequestMagicLinkModel,
    TwoFaModel,
} from '../models/request'
import { AuthResponse, RefreshTokenResponse } from '../models/response'
import { HttpMethod } from '../util/constants'
import {
    dehydrateToStorage,
    getTimestamp,
    hydrateFromStorage,
    removeFromStorage,
    Resettable,
} from '../util/misc'
import { request } from '../util/request'
import { stores } from '../util/stores'
import { addMinutes } from 'date-fns'
import { detect } from 'detect-browser'
const browser = detect()

const AUTH_KEY = 'SAMA:AUTH'
const MINS_TO_CACHE_INCOMPLETE_AUTH = 10

const PHONE_KEY = 'SAMA:PHONE'

export const RESEND_CODE_TIME_INTERVAL = 20 // In seconds

export class AuthStore implements Resettable {
    public authResponse!: AuthResponse | null
    public ready: boolean = true
    public phone!: string | null

    public get authenticated(): boolean {
        return !!this.authResponse?.accessToken
    }

    public get fullyAuthenticated(): boolean {
        return this.authenticated && !this.authResponse?.awaiting2fa
    }

    public getSecondsToResendCode(): number {
        if (!this.authResponse?.codeRequestedAt) {
            return 0
        }

        const currentTime = getTimestamp()
        const timeAtLastRequest = this.authResponse.codeRequestedAt
        const nextPossibleRequestTime =
            timeAtLastRequest + RESEND_CODE_TIME_INTERVAL

        return nextPossibleRequestTime - currentTime
    }

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
        this.setUp()
    }

    @action
    public setUp(): void {
        this.authResponse = hydrateFromStorage(AUTH_KEY)
        if (this.authResponse?.expires) {
            const now = new Date()
            const expiresAt = new Date(this.authResponse.expires)

            if (now >= expiresAt) {
                this.reset()
            }
        }

        this.phone = hydrateFromStorage(PHONE_KEY)
    }

    @action
    public setAuthResponse(authResponse: AuthResponse): void {
        if (authResponse.awaiting2fa) {
            authResponse.expires = addMinutes(
                new Date(),
                MINS_TO_CACHE_INCOMPLETE_AUTH,
            )
        } else if (authResponse.expires) {
            authResponse.expires = undefined
        }

        this.authResponse = authResponse
        dehydrateToStorage(AUTH_KEY, authResponse)
    }

    @action
    public setTokens(tokens: RefreshTokenResponse): void {
        if (this.authResponse) {
            this.setAuthResponse({
                ...this.authResponse,
                accessToken: tokens.accessToken,
            })
        }
    }

    @action
    public requestMagicLink(model: RequestMagicLinkModel) {
        return request('/auth/magic-link', HttpMethod.POST, {
            body: {
                email: model.email,
            },
        })
    }

    _capitalize = (string: string | undefined): string => {
        return string ? string.charAt(0).toUpperCase() + string.slice(1) : ''
    }

    @action
    public launch() {
        const eventData: any = {}
        eventData.platform = 'Web'
        eventData.$browser = this._capitalize(browser?.name.toString())
        eventData.$browser_version = browser?.version
        eventData.$current_url = window.location.href
        eventData.$os = this._capitalize(browser?.os?.toString())
        eventData.$screen_height = window.innerHeight
        eventData.$screen_width = window.innerWidth

        const query = new URLSearchParams(eventData).toString()

        return request(`/me/launch`, HttpMethod.POST, {
            body: { eventData: eventData },
            silentErrors: true,
        })
    }

    @action
    public logIn(model: MagicLoginModel) {
        return request<MagicLoginModel, AuthResponse>(
            '/auth/login/magic',
            HttpMethod.POST,
            { body: model },
        ).pipe(
            tap((response) =>
                runInAction(() => {
                    if (response.data) {
                        this.setAuthResponse(response.data)

                        if (
                            !response.data.isPhoneNeeded &&
                            response.data.awaiting2fa === true
                        )
                            this.resend2fa().subscribe()
                    }
                }),
            ),
        )
    }

    @action
    public resend2fa() {
        return request('/auth/2fa', HttpMethod.POST, {
            body: { accessToken: this.authResponse?.accessToken },
        }).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok && this.authResponse) {
                        this.setAuthResponse({
                            ...this.authResponse,
                            codeRequestedAt: getTimestamp(),
                        })
                    }
                })
            }),
        )
    }

    @action
    public validate2fa(model: TwoFaModel) {
        return request('/auth/2fa', HttpMethod.PUT, {
            body: model,
        }).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok && this.authResponse) {
                        this.launch().subscribe()
                        stores.statics.loadStatics()
                        this.setAuthResponse({
                            ...this.authResponse,
                            awaiting2fa: false,
                        })
                    }
                })
            }),
        )
    }

    @action
    public setPhone(model: AddPhoneModel) {
        return request('/auth/phone', HttpMethod.PUT, {
            body: model,
        }).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok && this.authResponse) {
                        this.phone = model.phone
                        dehydrateToStorage(PHONE_KEY, model.phone)
                        this.setAuthResponse({
                            ...this.authResponse,
                            isPhoneNeeded: false,
                        })

                        this.resend2fa().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public reset(): void {
        this.authResponse = null
        this.phone = null
        removeFromStorage(AUTH_KEY, PHONE_KEY)
    }

    @action
    public signOut(): void {
        stores.user.trackEvent('logout', {}).subscribe()
        stores.reset()
    }
}
