import { observer } from 'mobx-react'
import React, { useCallback, useMemo } from 'react'
import ReactDOMServer from 'react-dom/server'
import { FilePond, registerPlugin } from 'react-filepond'
import { Trans, useTranslation } from 'react-i18next'
import { CenterModal, ModalProps } from '../../../components/modals'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond/dist/filepond.min.css'
import styled from 'styled-components'
import { getHeaders } from '../../../util/request'
import { toBase64 } from '../../../util/misc/to-base-64'
import { useStores } from '../../../util/stores'
import * as Sentry from '@sentry/react'

registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateType,
)

const Wrapper = styled.main`
    .filepond--root {
        font-family: inherit;
        min-height: 128px;
        border: 1px dashed #3095aa;
        border-radius: 0.5rem;
    }

    .filepond--drop-label {
        cursor: pointer;
        height: 128px;
    }

    .filepond--drop-label > label {
        cursor: pointer;
    }

    .filepond--panel-root {
        background: #f1f8f9cc;
        overflow: hidden;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
`

interface UploadModelProps {
    uploadType: string
    uploadId: string
    setPicture: any
}

export const UploadLogoModal: React.FC<ModalProps & UploadModelProps> =
    observer(({ isOpen, setIsOpen, uploadType, uploadId, setPicture }) => {
        const { t } = useTranslation()
        const { user } = useStores()

        const placeholder = useMemo(
            () => (
                <div className="flex flex-col">
                    <img
                        src="/img/icons/upload-logo.svg"
                        className="h-6"
                        alt="Upload logo"
                    />
                    <span className="!text-accent-2 !text-[16px]">
                        <Trans
                            t={t}
                            i18nKey="modals.uploadLogo.dragAndDrop"
                            tOptions={{ browse: t('modals.uploadLogo.browse') }}
                        >
                            <span className="text-[#3095AA]" />
                        </Trans>
                    </span>
                    <span className="!text-[#56576A] !text-[13px]">
                        {t('modals.uploadLogo.supportedFileTypes')}
                    </span>
                </div>
            ),
            [t],
        )

        return (
            <CenterModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                className="max-w-[468px] p-10"
            >
                <header className="flex flex-col mb-8">
                    <span className="text-[24px]">
                        {t('modals.uploadLogo.heading')}
                    </span>
                    <span className="text-[13px]">
                        {t('modals.uploadLogo.subText')}
                    </span>
                </header>
                <Wrapper>
                    <FilePond
                        name="file"
                        server={{
                            process: (
                                fieldName,
                                file,
                                _,
                                load,
                                error,
                                progress,
                                abort,
                            ) => {
                                const formData = new FormData()
                                formData.append(fieldName, file, file.name)

                                const request = new XMLHttpRequest()
                                const headers = getHeaders()

                                request.open(
                                    'POST',
                                    process.env.REACT_APP_BASE_URL +
                                        '/' +
                                        uploadType +
                                        '/' +
                                        uploadId +
                                        '/picture',
                                )

                                request.setRequestHeader(
                                    'Authorization',
                                    headers['Authorization'],
                                )

                                request.upload.onprogress = (e) => {
                                    progress(
                                        e.lengthComputable,
                                        e.loaded,
                                        e.total,
                                    )
                                }

                                request.onload = async function () {
                                    if (
                                        request.status >= 200 &&
                                        request.status < 300
                                    ) {
                                        user.trackEvent('uploadedLogo', {
                                            type: uploadType,
                                        }).subscribe()
                                        load(request.responseText)
                                        const base64 = await toBase64(file)
                                        setPicture(base64)
                                        setIsOpen(false)
                                    } else {
                                        Sentry.captureMessage(
                                            'Upload failed: status:' +
                                                request.status +
                                                ' message: ' +
                                                request.responseText,
                                        )
                                        error('')
                                    }
                                }

                                request.send(formData)

                                return {
                                    abort: () => {
                                        request.abort()
                                        abort()
                                    },
                                }
                            },
                        }}
                        instantUpload={true}
                        credits={false}
                        allowMultiple={false}
                        labelIdle={ReactDOMServer.renderToString(placeholder)}
                        acceptedFileTypes={[
                            'image/jpeg',
                            'image/png',
                            'image/svg+xml',
                        ]}
                    />
                </Wrapper>
            </CenterModal>
        )
    })
