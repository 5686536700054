import { useField } from 'formik'
import { HTMLMotionProps, motion } from 'framer-motion'
import React from 'react'
import { classNames } from '../../util/misc'
import { FormValidationMessage } from '../layout'

interface Props extends HTMLMotionProps<'input'> {
    name: string
    label?: string
    small?: boolean
    editEnabled?: boolean
}

export const TextInput: React.FC<Props> = ({
    name,
    label,
    small,
    className,
    editEnabled = true,
    ...props
}) => {
    const [field, meta] = useField(name)

    return (
        <div className={classNames('flex flex-col', className)}>
            {label && (
                <label htmlFor={name} className="block text-[11px] mb-2">
                    {label}
                </label>
            )}
            {!editEnabled ? (
                <p>{field.value}</p>
            ) : (
                <motion.input
                    className={classNames(
                        'bg-[#f6f7f9]',
                        small ? 'h-11' : 'h-12',
                        'rounded-[0.25rem]',
                        'px-4',
                        'text-[13px]',
                    )}
                    {...props}
                    {...field}
                />
            )}
            {meta.error && (
                <div className="mt-[10px]">
                    <FormValidationMessage message={meta.error[0]} />
                </div>
            )}
        </div>
    )
}
